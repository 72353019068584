/* eslint-disable no-param-reassign */
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsNetworkGraph from 'highcharts/modules/networkgraph';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

HighchartsNetworkGraph(Highcharts);

const NetworkGraphWrapper = () => {
  const { t } = useTranslation();

  const getLinkPath = (chartLine, minusArrow) => {
    const { toNode, fromNode, type } = chartLine;
    const arrowLength = 7;
    const angle = Math.atan((fromNode.plotX - toNode.plotX) / (fromNode.plotY - toNode.plotY));
    const angleCos = Math.cos(angle);
    const angleSin = Math.sin(angle);
    const pointRadiusTo = toNode.radius + (minusArrow ? arrowLength : 1);
    const pointRadiusFrom = fromNode.radius + (minusArrow ? arrowLength : 1);

    let mX = fromNode.plotX - pointRadiusFrom * angleSin;
    if (fromNode.plotY < toNode.plotY) {
      mX = fromNode.plotX + pointRadiusFrom * angleSin;
    }
    let mY = fromNode.plotY - pointRadiusFrom * angleCos;
    if (fromNode.plotY < toNode.plotY) {
      mY = fromNode.plotY + pointRadiusFrom * angleCos;
    }
    let lX = toNode.plotX - pointRadiusTo * angleSin;
    if (toNode.plotY < fromNode.plotY) {
      lX = toNode.plotX + pointRadiusTo * angleSin;
    }
    let lY = toNode.plotY - pointRadiusTo * angleCos;
    if (toNode.plotY < fromNode.plotY) {
      lY = toNode.plotY + pointRadiusTo * angleCos;
    }

    if (!type || !angle || minusArrow) {
      return [
        ['M', mX || 0, mY || 0],
        ['L', lX || 0, lY || 0],
      ];
    }

    const path = ['M', mX, mY, lX, lY];
    const arrowWidth = arrowLength / 2;
    if (fromNode.plotY < toNode.plotY) {
      path.push(
        toNode.plotX - pointRadiusTo * angleSin,
        toNode.plotY - pointRadiusTo * angleCos,
      );
      path.push(
        toNode.plotX - pointRadiusTo * angleSin - arrowLength * angleSin - arrowWidth * angleCos,
        toNode.plotY - pointRadiusTo * angleCos - arrowLength * angleCos + arrowWidth * angleSin,
      );

      path.push(
        toNode.plotX - pointRadiusTo * angleSin,
        toNode.plotY - pointRadiusTo * angleCos,
      );
      path.push(
        toNode.plotX - pointRadiusTo * angleSin - arrowLength * angleSin + arrowWidth * angleCos,
        toNode.plotY - pointRadiusTo * angleCos - arrowLength * angleCos - arrowWidth * angleSin,
      );
      path.push(
        toNode.plotX - pointRadiusTo * angleSin,
        toNode.plotY - pointRadiusTo * angleCos,
      );
    } else {
      path.push(
        toNode.plotX + pointRadiusTo * angleSin,
        toNode.plotY + pointRadiusTo * angleCos,
      );
      path.push(
        toNode.plotX + pointRadiusTo * angleSin + arrowLength * angleSin - arrowWidth * angleCos,
        toNode.plotY + pointRadiusTo * angleCos + arrowLength * angleCos + arrowWidth * angleSin,
      );
      path.push(
        toNode.plotX + pointRadiusTo * angleSin,
        toNode.plotY + pointRadiusTo * angleCos,
      );
      path.push(
        toNode.plotX + pointRadiusTo * angleSin + arrowLength * angleSin + arrowWidth * angleCos,
        toNode.plotY + pointRadiusTo * angleCos + arrowLength * angleCos - arrowWidth * angleSin,
      );
      path.push(
        toNode.plotX + pointRadiusTo * angleSin,
        toNode.plotY + pointRadiusTo * angleCos,
      );
    }

    if (chartLine.options.bidirectional) {
      if (toNode.plotY < fromNode.plotY) {
        path.push(
          fromNode.plotX - pointRadiusFrom * angleSin,
          fromNode.plotY - pointRadiusFrom * angleCos,
        );
        path.push(
          fromNode.plotX - pointRadiusFrom * angleSin - arrowLength * angleSin - arrowWidth * angleCos,
          fromNode.plotY - pointRadiusFrom * angleCos - arrowLength * angleCos + arrowWidth * angleSin,
        );

        path.push(
          fromNode.plotX - pointRadiusFrom * angleSin,
          fromNode.plotY - pointRadiusFrom * angleCos,
        );
        path.push(
          fromNode.plotX - pointRadiusFrom * angleSin - arrowLength * angleSin + arrowWidth * angleCos,
          fromNode.plotY - pointRadiusFrom * angleCos - arrowLength * angleCos - arrowWidth * angleSin,
        );
      } else {
        path.push(
          fromNode.plotX + pointRadiusFrom * angleSin,
          fromNode.plotY + pointRadiusFrom * angleCos,
        );
        path.push(
          fromNode.plotX + pointRadiusFrom * angleSin + arrowLength * angleSin - arrowWidth * angleCos,
          fromNode.plotY + pointRadiusFrom * angleCos + arrowLength * angleCos + arrowWidth * angleSin,
        );
        path.push(
          fromNode.plotX + pointRadiusFrom * angleSin,
          fromNode.plotY + pointRadiusFrom * angleCos,
        );
        path.push(
          fromNode.plotX + pointRadiusFrom * angleSin + arrowLength * angleSin + arrowWidth * angleCos,
          fromNode.plotY + pointRadiusFrom * angleCos + arrowLength * angleCos - arrowWidth * angleSin,
        );
      }
    }

    return path;
  };

  Highcharts.wrap(
    Highcharts.seriesTypes.networkgraph.prototype.pointClass.prototype,
    'renderLink',
    function renderLink() {
      const chartLine = this;

      if (!chartLine.graphic) {
        const { chart } = chartLine.series;
        const { nodes, points: links } = chart.series[0];
        let path = getLinkPath(chartLine);
        const { fromNode, toNode } = chartLine;

        chartLine.graphic = chart.renderer
          .path(path)
          .add(chartLine.series.group);

        let originalStrokeWidth = 1;

        path = getLinkPath(chartLine, true);
        chartLine.svgPadding = chart.renderer
          .path(path)
          .attr({
            stroke: 'transparent',
            'stroke-width': 7,
            class: 'panningDisabled',
          })
          .on('mousemove', (event) => {
            if (document.getElementById('networkgraph-links-tooltip').style.display === 'block') {
              const x = Math.round(event.x + 5);
              const y = Math.round(event.y + 25);

              document.getElementById('networkgraph-links-tooltip').style.left = `${x}px`;
              document.getElementById('networkgraph-links-tooltip').style.top = `${y}px`;
            }
          })
          .on('mouseover', () => {
            if (document.querySelector('.highcharts-point-hover') !== null) {
              return;
            }
            originalStrokeWidth = chartLine.graphic.attr('stroke-width');
            chartLine.graphic.attr({
              'stroke-width': originalStrokeWidth + 1,
            });

            if (chartLine.type) {
              const allRelations = chartLine.options.relatedLinks || [chartLine.options];
              const tooltipRelations = [];
              allRelations.forEach((relation) => {
                tooltipRelations.push(
                  `<div>
                    <b>${t('dashboard.tooltip_content.source')}</b> ${relation.from}<br/>
                    <b>${t('dashboard.tooltip_content.destination')}</b> ${relation.to}<br/>
                    <b>${t('dashboard.tooltip_content.bidirectional')}</b>
                    ${relation.bidirectional ? t('ui.yes') : t('ui.no')}<br/>
                    <b>${t('dashboard.tooltip_content.type')}</b> ${relation.type}<br/>
                    <b>${t('dashboard.tooltip_content.weight')}</b> ${relation.value}<br/>
                  </div>`,
                );
              });
              let joinChar = '';
              if (tooltipRelations.length > 2) {
                joinChar = '<br/>';
              }
              document.getElementById('networkgraph-links-tooltip-content').innerHTML = tooltipRelations.join(joinChar);
              document.getElementById('networkgraph-links-tooltip-content').style.columnCount = (
                tooltipRelations.length > 1 ? 2 : 'unset'
              );
              document.getElementById('networkgraph-links-tooltip').style.display = 'block';
            }

            links.forEach(({
              graphic: chartTestedLink,
              id: chartTestedLinkId,
            }) => {
              if (![chartLine.id].includes(chartTestedLinkId)) {
                chartTestedLink.attr({
                  opacity: 0.2,
                });
              }
            });

            nodes.forEach(({
              graphic: chartNode,
              dataLabel,
              id: chartNodeId,
            }) => {
              if (![fromNode.id, toNode.id].includes(chartNodeId)) {
                chartNode.attr({
                  opacity: 0.3,
                });
                if (dataLabel.attr('opacity') > 0) {
                  dataLabel.attr({
                    opacity: 0.2,
                  });
                }
              } else {
                dataLabel.attr({
                  opacity: 1,
                });
              }
            });
          })
          .on('mouseleave', () => {
            if (document.querySelector('.highcharts-point-hover') !== null) {
              return;
            }
            chartLine.graphic.attr({
              'stroke-width': originalStrokeWidth,
            });
            links.forEach(({
              graphic: chartTestedLink,
            }) => {
              chartTestedLink.attr({
                opacity: 1,
              });
            });
            nodes.forEach(({ graphic: chartNode, dataLabel }) => {
              chartNode.attr({
                opacity: 1,
              });
              if (dataLabel.attr('opacity') > 0) {
                dataLabel.attr({
                  opacity: 1,
                });
              }
            });
            document.getElementById('networkgraph-links-tooltip').style.display = 'none';
          })
          .add(chartLine.series.group);
      }
    },
  );

  Highcharts.wrap(
    Highcharts.seriesTypes.networkgraph.prototype.pointClass.prototype,
    'redrawLink',
    function redrawLink() {
      const chartLine = this;
      let path = getLinkPath(chartLine);
      let attribs;

      if (chartLine.graphic) {
        chartLine.shapeArgs = {
          d: path,
        };
        if (!chartLine.series.chart.styledMode) {
          attribs = chartLine.series.pointAttribs(chartLine);
          chartLine.graphic.attr(attribs);
          (chartLine.dataLabels || []).forEach((label) => {
            if (label) {
              label.attr({
                opacity: attribs.opacity,
              });
            }
          });

          path = getLinkPath(chartLine, true);
          chartLine.svgPadding.attr({ d: path });
        }
        chartLine.graphic.animate(chartLine.shapeArgs);
        const start = path[0];
        const end = path[1];
        if (start[0] === 'M' && end[0] === 'L') {
          chartLine.plotX = (start[1] + end[1]) / 2;
          chartLine.plotY = (start[2] + end[2]) / 2;
        }
      }
    },
  );

  Highcharts.wrap(
    Highcharts.seriesTypes.networkgraph.prototype,
    'onMouseMove',
    function onMouseMove(func, point, event) {
      if (point.fixedPosition && point.inDragMode) {
        const series = this;
        const { chart } = series;
        const normalizedEvent = chart.pointer.normalize(event);
        const diffX = point.fixedPosition.chartX - normalizedEvent.chartX;
        const diffY = point.fixedPosition.chartY - normalizedEvent.chartY;
        const { graphLayoutsLookup } = chart;
        // At least 5px to apply change (avoids simple click):
        if (Math.abs(diffX) > 5 || Math.abs(diffY) > 5) {
          const newPlotX = normalizedEvent.offsetX - 10;
          const newPlotY = normalizedEvent.offsetY - 45;
          if (chart.isInsidePlot(newPlotX, newPlotY)) {
            point.plotX = newPlotX;
            point.plotY = newPlotY;
            point.hasDragged = true;
            series.redrawHalo(point);
            graphLayoutsLookup.forEach((layout) => {
              layout.restartSimulation();
            });
          }
        }
      }
    },
  );

  return (
    <Box
      id="networkgraph-links-tooltip"
      sx={{
        border: '1px solid #28f',
        borderRadius: '3px',
        position: 'fixed',
        padding: '3px 5px',
        display: 'none',
        backgroundColor: '#fffe',
        flexWrap: 'wrap',
        zIndex: 1200,
        maxWidth: '450px',
      }}
    >
      <Box
        id="networkgraph-links-tooltip-content"
        sx={{
          columnGap: '20px',
        }}
      />
    </Box>
  );
};

export default NetworkGraphWrapper;
