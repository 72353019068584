import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { arrayMove } from '@dnd-kit/sortable';

import { toggleCheckbox, toggleCheckboxAll, clearSelection } from 'generic/core/selection/actions';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import { openDialogSendToFriend } from 'generic/core/actions/actions';
import { cartOrNewsletterPropType } from 'generic/core/qes/proptypes';
import { fetchResultsComplete } from 'generic/core/search/actions';
import { patchPanierDocuments, updatePanierDocumentsOrderByIndex, types } from 'generic/core/newsletters/actions';
import NewsletterDocuments from 'generic/components/ui/NewsletterDocuments';

const NewsletterDocumentsContainer = ({ newsletter }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const checkedItems = useSelector((state) => state.selection.checkedItems);
  const allDocsIds = _.map(newsletter.panierDocuments, 'idext');
  const allCheckedDocs = _.keys(checkedItems);
  const isNewsletterEmpty = _.isEmpty(newsletter?.panierDocuments);
  const allArticlesChecked = !isNewsletterEmpty && _.isEmpty(_.without(allDocsIds, ...allCheckedDocs));

  const handleGetDocumentComplete = useCallback((iddoc, baseId) => {
    dispatch(fetchResultsComplete(iddoc, baseId));
  }, [dispatch]);

  const handleOpenSendToFriend = () => {
    dispatch(openDialogSendToFriend());
    return false;
  };

  const handleOpenDeleteDocumentConfirmDialog = () => {
    const ids = _.keys(checkedItems);
    dispatch(setConfirmDialogData({
      title: t('newsletters.delete'),
      message: `${t('newsletters.delete_newsletter_documents', { count: ids.length })}`,
      submitColor: 'danger',
      waitForActions: [types.DELETE_NEWSLETTER_DOCUMENTS_SUCCESS, types.DELETE_NEWSLETTER_DOCUMENTS_ERROR],
      action: {
        type: types.DELETE_NEWSLETTER_DOCUMENTS,
        id: newsletter.panier,
        ids,
      },
    }));
    return false;
  };

  // GESTION DES RUBRIQUES
  const naturalOrderedGroupNames = useMemo(
    () => (
      _.sortBy(
        _.uniq(
          _.map(newsletter.panierDocuments, 'intitule'),
        ),
      )
    ),
    [newsletter.panierDocuments],
  );
  const [filterGroup, setFilterGroup] = useState(new Set(naturalOrderedGroupNames));

  const addGroupToFilters = useCallback((name) => {
    const newSet = new Set(filterGroup);
    newSet.add(name);
    setFilterGroup(newSet);
  }, [filterGroup]);

  const removesGroupFromFilters = (name) => {
    const newSet = new Set(filterGroup);
    newSet.delete(name);
    setFilterGroup(newSet);
  };

  const handleApplyGroupToSelection = (groupName) => {
    const updatedDocs = _.map(checkedItems, (item) => ({
      panier: newsletter.panier,
      panier_document: item.panierdocument,
      intitule: groupName,
    }));

    dispatch(patchPanierDocuments(updatedDocs));
    addGroupToFilters(groupName);
  };

  const handleSaveOrderAndTopic = useCallback((doc, order, topic) => {
    dispatch(patchPanierDocuments([{
      panier: newsletter.panier,
      panier_document: doc.panier_document,
      ordre: parseInt(order, 10),
      intitule: topic,
    }]));
    addGroupToFilters(topic);
  }, [addGroupToFilters, dispatch, newsletter]);

  // GESTION DE L'ÉDITION D'ARTICLES
  const handleSaveDescEdition = (doc, renduHtml) => {
    dispatch(patchPanierDocuments([{
      panier: newsletter.panier,
      panier_document: doc.panier_document,
      rendu_html: renduHtml,
    }]));
  };

  // GESTION DES CHECKBOX
  const handleToggleCheckbox = useCallback((panierDocument) => {
    dispatch(toggleCheckbox({
      id: panierDocument.idext,
      item: {
        title: '',
        documentitem: panierDocument.documentitem,
        panierdocument: panierDocument.panier_document,
      },
    }));
  }, [dispatch]);

  const handleToggleCheckboxAll = (event) => {
    const items = newsletter.panierDocuments.reduce((accumulateur, panierDocument) => {
      if (Array.from(filterGroup).includes(panierDocument.intitule)) {
        accumulateur[panierDocument.idext] = {
          title: '',
          documentitem: panierDocument.documentitem,
          panierdocument: panierDocument.panier_document,
        };
      }

      return accumulateur;
    }, {});

    dispatch(clearSelection());
    dispatch(toggleCheckboxAll({
      checked: event.target.checked,
      items,
    }));
  };

  // GESTION DE L'ORDRE VIA DRAG'n'DROP
  // utilisation d'un state local pour éviter de rerendre tous les composants parents qui dépendent de
  // `props.newsletter` les modifications sur le back sont faites en optimistic, ce qui veut dire qu'on
  // ne met pas à jour redux
  const [panierDocs, setPanierDocs] = useState(newsletter.panierDocuments);
  useEffect(() => {
    setPanierDocs(newsletter.panierDocuments);
  }, [newsletter.panierDocuments]);

  const handleDocumentMoved = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setPanierDocs((prevDocs) => {
        const oldIndex = _.findIndex(prevDocs, { panier_document: active.id });
        const newIndex = _.findIndex(prevDocs, { panier_document: over.id });
        const ordered = arrayMove(prevDocs, oldIndex, newIndex).map((doc, index) => ({
          ...doc,
          ordre: index + 1,
        }));

        // Extraire les éléments dont l'ordre a changé pour envoyer un minimum de requête au backoffice
        const modifiedDocs = ordered.filter((doc, index) => doc.panier_document !== prevDocs[index].panier_document);
        dispatch(updatePanierDocumentsOrderByIndex(modifiedDocs));

        return ordered;
      });
    }
  };

  // SUGAR METHODS

  /**
   * Permet de regrouper par `panierDoc.intitule` dans l'ordre naturel croissant, puis de trier par `panierDoc.ordre`
   * pour chaque groupe.
   */
  const handleRegroupAndReorder = () => {
    const groupedDocs = _.groupBy(panierDocs, 'intitule');
    let order = 1;
    const organize = [];
    naturalOrderedGroupNames.forEach((groupName) => {
      groupedDocs[groupName].forEach((doc) => {
        const params = {
          panier: newsletter.panier,
          panier_document: doc.panier_document,
          ordre: order++,
          intitule: groupName,
        };
        organize.push(params);
      });
    });
    dispatch(patchPanierDocuments(organize));
  };

  return (
    <NewsletterDocuments
      hasOrdering={newsletter.mode_manuel}
      hasDocsEdition={false} // TODO: trouver un bon moyen de le faire
      allArticlesChecked={allArticlesChecked}
      checkedItems={checkedItems}
      isEmpty={_.isEmpty(newsletter?.panierDocuments)}
      handleToggleCheckboxAll={handleToggleCheckboxAll}
      handleOpenDeleteDocumentConfirmDialog={handleOpenDeleteDocumentConfirmDialog}
      handleOpenSendToFriend={handleOpenSendToFriend}
      handleApplyGroupToSelection={handleApplyGroupToSelection}
      handleDocumentMoved={handleDocumentMoved}
      handleToggleCheckbox={handleToggleCheckbox}
      handleSaveOrderAndTopic={handleSaveOrderAndTopic}
      handleSaveDescEdition={handleSaveDescEdition}
      handleGetDocumentComplete={handleGetDocumentComplete}
      panierDocs={panierDocs}
      groupNames={naturalOrderedGroupNames}
      addGroupToFilters={addGroupToFilters}
      filterGroup={filterGroup}
      removesGroupFromFilters={removesGroupFromFilters}
      handleRegroupAndReorder={handleRegroupAndReorder}
    />
  );
};

NewsletterDocumentsContainer.propTypes = {
  newsletter: cartOrNewsletterPropType.isRequired,
};

NewsletterDocumentsContainer.defaultProps = {};

export default NewsletterDocumentsContainer;
