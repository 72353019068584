import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';

import { isHeaderBackgroundColorDark } from 'generic/utils/colorUtils';

export default styled(MenuItem, {
  shouldForwardProp: () => true,
  name: 'HeaderMenuItem',
  slot: 'Root',
})(({ theme }) => {
  let menuColors = '#000000';
  let adaptedHeaderTransparency = '22';
  let adaptedHeaderTransparencyOnHover = '11';

  if (isHeaderBackgroundColorDark(theme)) {
    menuColors = '#ffffff';
    adaptedHeaderTransparency = 'dd';
    adaptedHeaderTransparencyOnHover = '55';
  }

  return {
    color: menuColors,
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: `${menuColors}${adaptedHeaderTransparency}`,
      color: theme.palette.primary.main,
    },
    '&:not(.Mui-selected):hover': {
      backgroundColor: `${menuColors}${adaptedHeaderTransparencyOnHover}`,
    },
  };
});
