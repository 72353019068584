import { types } from 'generic/core/dashboard/actions';
import _ from 'lodash';

const dashboardReducer = (state = {
  widgets: {},
}, action) => {
  switch (action.type) {
    case types.SET_DASHBOARD_LOADING:
    case types.CLEANUP_DASHBOARD:
    case types.FETCH_WIDGETS: {
      return {
        ...state,
        widgets: action.keepWidgets ? _.omit({ ...state.widgets }, _.keys(action.widgets)) : {},
      };
    }
    case types.FETCH_WIDGET_SUCCESS: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          [action.widgetId]: action.widget,
        },
      };
    }
    case types.FETCH_WIDGET_ERROR: {
      return {
        ...state,
        widgets: {
          ...state.widgets,
          [action.widgetId]: {
            error: action.response,
            series: [],
          },
        },
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
