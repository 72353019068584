/* eslint-disable prefer-template */
import _ from 'lodash';
import ANR_CONSTANTS from 'generic/core/anr/constants';

const { QUOTES_TYPES_MAPPING, URL } = ANR_CONSTANTS;

const arrayUnique = (arrayName) => {
  if (arrayName !== null) {
    const newArray = [];
    for (let i = 0; i < arrayName.length; i++) {
      for (let j = 0; j < newArray.length; j++) {
        if (newArray[j] === arrayName[i]) {
          continue;
        }
      }
      newArray.push(arrayName[i]);
    }
    return newArray;
  }
  return arrayName;
};

const arrayTrim = (arrayName) => {
  const newArray = [];
  if (arrayName !== null) {
    for (let i = 0; i < arrayName.length; i++) {
      const value = arrayName[i].trim();
      if (value !== '') {
        newArray.push(value);
      }
    }
  }
  return newArray;
};

const getWords = (query) => {
  const wordsQuery = ` ${query} `;
  return arrayTrim(wordsQuery
    .replace(/(\s+)?\|(\s+)?/g, '|')
    .replace(/(\s+)?>>(\s+)?/g, '>>')
    .replace(/\s+/g, ' ')
    .replace(/" "/g, '"  "')
    .replace(/" \(/g, '"  (')
    .replace(/\) "/g, ')  "')
    .match(/[+-=]?(\(.*?\)\s|".*?"(~\d+)?\s|".*?"(\/\d+)?\s|\S+)/g));
};

const parenthesisToBrackets = (query) => {
  let tmpFlag = 0;
  if (_.isEmpty(query)) {
    return '';
  }
  let bracketsQuery = query.replace(/\s+/g, ' ');
  for (let i = 0; i < bracketsQuery.length; i++) {
    switch (bracketsQuery.substring(i, i + 1)) {
      case '(':
        bracketsQuery = bracketsQuery.substring(0, i) + '[' + bracketsQuery.substring(i + 1, bracketsQuery.length);
        tmpFlag++;
        break;
      case ')':
        bracketsQuery = bracketsQuery.substring(0, i) + ']' + bracketsQuery.substring(i + 1, bracketsQuery.length);
        tmpFlag--;
        break;
      case '|':
        if (tmpFlag) {
          bracketsQuery = bracketsQuery.substring(0, i) + '§' + bracketsQuery.substring(i + 1, bracketsQuery.length);
        }
        break;
      case '"':
        bracketsQuery = bracketsQuery.substring(0, i) + '`' + bracketsQuery.substring(i + 1, bracketsQuery.length);
        break;
      case ' ':
        if (tmpFlag) {
          bracketsQuery = bracketsQuery.substring(0, i) + '_' + bracketsQuery.substring(i + 1, bracketsQuery.length);
        }
        break;
      default:
        break;
    }
  }
  return bracketsQuery;
};

const bracketsToParenthesis = (query) => {
  if (_.isEmpty(query)) {
    return '';
  }
  return query
    .replace(/\[/g, '(')
    .replace(/\]/g, ')')
    .replace(/[§|]/g, ' | ')
    .replace(/`/g, '"')
    .replace(/_/g, ' ')
    .replace(/\s+/g, ' ');
};

const simpleToExpertQuery = (expertQuery) => {
  const query = parenthesisToBrackets(expertQuery).replace(/\s+/g, ' ').replace(/=\s/g, '=');
  const words = getWords(query);
  let queryMay = [];
  const queryMust = [];
  const queryMustNot = [];

  if (!_.isEmpty(words)) {
    for (let i = words.length - 1; i >= 0; i--) {
      if (words[i].indexOf('+') === 0) {
        queryMust.push(words[i].substring(1, words[i].length));
      } else if (words[i].indexOf('-') === 0) {
        queryMustNot.push(words[i].substring(1, words[i].length));
      } else if (words[i].indexOf('|') !== -1 && _.isEmpty(queryMay)) {
        queryMay = words[i].split('|');
      } else {
        queryMust.push(words[i]);
      }
    }
  }
  return {
    queryMust: bracketsToParenthesis(queryMust.reverse().join(';')).trim().replace(/;/g, '\n'),
    queryMay: bracketsToParenthesis(queryMay.join(';')).trim().replace(/;/g, '\n'),
    queryMustNot: bracketsToParenthesis(queryMustNot.reverse().join(';')).trim().replace(/;/g, '\n'),
  };
};

const expertToSimpleQuery = (queries) => {
  let query = '';
  const queryMust = parenthesisToBrackets(queries.queryMust)
    .replace(/(\s+)?\|(\s+)?/g, '|')
    .replace(/[;\n]/g, ' ')
    .trim()
    .replace(/=\s/g, '=')
    .toLowerCase();
  const queryMay = parenthesisToBrackets(queries.queryMay)
    .replace(/(\s+)?\|(\s+)?/g, '|')
    .replace(/[;\n]/g, ' ')
    .trim()
    .replace(/=\s/g, '=')
    .toLowerCase();
  const queryMustNot = parenthesisToBrackets(queries.queryMustNot)
    .replace(/(\s+)?\|(\s+)?/g, '|')
    .replace(/[;\n]/g, ' ')
    .trim()
    .replace(/=\s/g, '=')
    .toLowerCase();
  const wordsMust = arrayUnique(getWords(queryMust));
  if (wordsMust != null) {
    query = wordsMust.join(' ');
  }
  const wordsMay = arrayUnique(getWords(queryMay));
  if (wordsMay != null) {
    query = (`${query} ${wordsMay.join(' | ')}`).trim();
  }
  const wordsMustNot = arrayUnique(getWords(queryMustNot));
  if (wordsMustNot != null) {
    for (let i = 0; i < wordsMustNot.length; i++) {
      if (wordsMustNot[i][0] !== '+' && wordsMustNot[i][0] !== '-') {
        wordsMustNot[i] = `-${wordsMustNot[i]}`;
      }
      query = (`${query} ${wordsMustNot[i]}`).trim();
    }
  }
  return bracketsToParenthesis(query).replace(/\|/g, ' | ').replace(/\s+/g, ' ');
};

const getArticleIframeUrl = (article) => {
  let iframeUrl;
  if (article.quote_type
    && (article.quote_type === QUOTES_TYPES_MAPPING.YOUTUBE || article.site_host === 'www.youtube.com')) {
    // Si l'article est de type citation "youtube"
    // ou renvoie vers une vidéo youtube, on prépare l'URL "embed"
    let quoteUrlId = article.quote_url.substring(32, article.quote_url.length);
    if (article.quote_type !== QUOTES_TYPES_MAPPING.YOUTUBE) {
      quoteUrlId = article.url.substring(article.url.indexOf('?v=') + 3, article.url.indexOf('&'));
    }
    if (!_.isEmpty(quoteUrlId)) {
      iframeUrl = `https://www.youtube.com/embed/${quoteUrlId}?autoplay=1`;
    }
  } else if (article.quote_type && article.quote_type === QUOTES_TYPES_MAPPING.DAILYMOTION) {
    // Si l'article est de type citation "dailymotion", on prépare l'URL "embed"
    iframeUrl = `https://www.dailymotion.com/embed/video/${article.quote_id}?autoplay=1`;
  } else if (article.url || article.attachment_name) {
    iframeUrl = `${URL}/api/article?article_id=${article.id}&iframe=true`;
  }
  return iframeUrl;
};

const checkIfItemHasQuery = (item) => {
  if (
    !_.isEmpty(item) && (item.id === 'glimpse' || item.has_quotes || item.has_news)
  ) {
    return true;
  }
  return false;
};

export {
  checkIfItemHasQuery,
  expertToSimpleQuery,
  getArticleIframeUrl,
  simpleToExpertQuery,
};
