import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import {
  Box,
  Card,
} from '@mui/material';
import Highcharts from 'highcharts';
import THEME_CONFIG from 'generic/core/theme';

Highcharts.setOptions(THEME_CONFIG.HIGHCHARTS);

const globalHighChartsOptions = {
  options: {
    accessibility: { enabled: false },
    credits: { enabled: false },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen', 'printChart', 'separator',
            'downloadJPEG', 'downloadPNG', 'downloadSVG', 'downloadPDF', 'separator',
            'downloadCSV', 'downloadXLS',
          ],
        },
      },
    },
  },
};

const DashboardWidget = ({
  height, highchartsOptions, componentProps, component: Component, flat,
}) => {
  const finalOptions = merge({}, globalHighChartsOptions, highchartsOptions);
  return (
    <Box>
      {flat ? (
        <Box>
          {Component == null
            ? ''
            : (
              <Component
                highchartsOptions={finalOptions}
                {...componentProps}
              />
            )}
        </Box>
      ) : (
        <Card>
          <Box
            position="relative"
            ml={0}
            // Attention, pour tout changement de height ou de p et pb ici, il faut
            // également adapter WidgetContainer pour que le Skeleton fasse la bonne
            // hauteur
            height={height}
            p={2}
            pb={3}
          >
            {Component == null
              ? ''
              : (
                <Component
                  highchartsOptions={finalOptions}
                  {...componentProps}
                />
              )}
          </Box>
        </Card>
      )}
    </Box>
  );
};

DashboardWidget.propTypes = {
  height: PropTypes.number,
  component: PropTypes.elementType,
  componentProps: PropTypes.oneOfType([
    PropTypes.shape({ title: PropTypes.string, documents: PropTypes.arrayOf(PropTypes.shape()) }),
  ]),
  highchartsOptions: PropTypes.shape(),
  flat: PropTypes.bool,
};

DashboardWidget.defaultProps = {
  height: THEME_CONFIG.HIGHCHARTS.chartsDefaultHeight,
  component: null,
  highchartsOptions: {},
  componentProps: {},
  flat: false,
};

export default DashboardWidget;
