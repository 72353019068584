import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
  Box,
  FormControlLabel,
  Link,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import {
  DoDisturbOff,
  DoDisturbOffOutlined,
  DoDisturbOn,
  DoDisturbOnOutlined,
  Search,
} from '@mui/icons-material';

import MUIDataTable from 'mui-datatables';
import TooltipButton from 'generic/components/ui/TooltipButton';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { format } from 'generic/utils/dateUtils';

const { ALERT } = QES_CONSTANTS;

const customToolbarSelect = (rowsSelected, data, statusFilter, t, handleClickActivateDeactivateSelected) => {
  const isActive = statusFilter !== ALERT.STATUSES.INACTIVE.join(',');
  const actionText = isActive ? t('alerts.deactivate') : t('alerts.activate');
  const actionIcon = isActive ? <DoDisturbOn /> : <DoDisturbOff />;

  const handleClick = () => handleClickActivateDeactivateSelected(rowsSelected, data, !isActive);

  return (
    <Button
      color="primary"
      variant="text"
      sx={{
        marginRight: 2,
      }}
      onClick={handleClick}
      startIcon={actionIcon}
    >
      {actionText}
    </Button>
  );
};

const customBodyRenderActions = (tableMeta, alerts, t, handleClickActivateDeactivate, handleClickSearch) => {
  const alertId = tableMeta.rowData[0];
  const alert = _.find(alerts, { veille: alertId });
  const etat = alert ? alert.etat_veille : null;
  const isActive = ALERT.STATUSES.ACTIVE.includes(etat);

  const buttonProps = {
    tag: 'fab',
    color: 'primary',
    size: 'extraSmall',
  };

  const activateDeactivateButton = (
    <TooltipButton
      {...buttonProps}
      title={isActive ? t('alerts.deactivate') : t('alerts.activate')}
      onClick={() => handleClickActivateDeactivate(alertId, !isActive)}
    >
      {isActive ? <DoDisturbOnOutlined /> : <DoDisturbOffOutlined />}
    </TooltipButton>
  );

  const searchButton = isActive && (
    <TooltipButton
      {...buttonProps}
      title={t('alerts.search')}
      onClick={() => handleClickSearch(alertId)}
    >
      <Search />
    </TooltipButton>
  );

  return (
    <Stack spacing={0.5} direction="row">
      {activateDeactivateButton}
      {searchButton}
    </Stack>
  );
};

const customBodyRenderName = (value, tableMeta) => (
  <Link
    component={RouterLink}
    underline="none"
    to={`alerts/${tableMeta.rowData[0]}`}
  >
    {value}
  </Link>
);

const customBodyRenderDate = (value) => (value ? format(value) : null);

const Alerts = ({
  alerts,
  loading,
  setStatusFilter,
  statusFilter,
  setSelectedRows,
  selectedRows,
  handleClickActivateDeactivate,
  handleClickActivateDeactivateSelected,
  handleClickSearch,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const smallerThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
  let responsiveTableType = 'standard';
  if (smallerThanLarge) {
    responsiveTableType = 'verticalAlways';
    if (smallerThanSmall) {
      responsiveTableType = 'simple';
    }
  }

  const handleToggleButtonChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'name',
      label: t('alerts.name'),
      options: {
        filter: false,
        customBodyRender: customBodyRenderName,
      },
    },
    {
      name: 'type',
      label: t('alerts.type'),
      options: {
        filter: true,
      },
    },
    {
      name: 'date_of_creation',
      label: t('alerts.date_of_creation'),
      options: {
        filter: false,
        customBodyRender: customBodyRenderDate,
      },
    },
    {
      name: 'frequence',
      label: t('alerts.frequence'),
      options: {
        filter: false,
      },
    },
    {
      name: 'date_of_execution',
      label: t('alerts.date_of_execution'),
      options: {
        filter: false,
        customBodyRender: customBodyRenderDate,
      },
    },
    {
      name: 'date_of_visualisation',
      label: t('alerts.date_of_visualisation'),
      options: {
        filter: false,
        customBodyRender: customBodyRenderDate,
      },
    },
    {
      name: 'number_of_documents',
      label: t('alerts.number_of_documents'),
      options: {
        filter: false,
      },
    },
    {
      name: 'date_of_next_execution',
      label: t('alerts.date_of_next_execution'),
      options: {
        filter: false,
        customBodyRender: customBodyRenderDate,
      },
    },
    {
      name: '',
      label: t('actions.actions'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          customBodyRenderActions(tableMeta, alerts, t, handleClickActivateDeactivate, handleClickSearch)
        ),
      },
    },
  ];

  const data = alerts.map(
    (alert) => [
      alert.veille,
      alert.libelle,
      t(`alerts.type_values.${alert.type_veille}`),
      alert.date_creation,
      t(`alerts.mode_values.${alert.mode_veille}`),
      alert.date_time_dernier_reussi,
      alert.date_derniere_consommation,
      alert.nombre_nouveau_document,
      alert.date_prochaine,
    ],
  );

  const textLabels = t('datatable.textLabels', { returnObjects: true });
  const options = {
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    filterType: 'checkbox',
    fixedHeader: true,
    textLabels: {
      ...textLabels,
      body: {
        ...textLabels.body,
        noMatch: loading ? t('datatable.loading') : textLabels.body.noMatch,
      },
    },
    downloadOptions: { filename: 'alerts_list.csv' },
    onChangePage: () => {
      const table = document.querySelector('#datatable .MuiTable-root');
      table.scrollIntoView(true);
    },
    sortOrder: {
      name: 'date_of_creation',
      direction: 'desc',
    },
    responsive: responsiveTableType,
    setTableProps: () => ({ size: 'small' }),
    rowsSelected: selectedRows,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setSelectedRows(rowsSelected);
    },
    customToolbarSelect: (rowsSelected) => (
      customToolbarSelect(rowsSelected, data, statusFilter, t, handleClickActivateDeactivateSelected)
    ),
  };
  return (
    <Box
      width="100%"
      m={smallerThanLarge ? 0 : 2}
      id="datatable"
    >
      <MUIDataTable
        title={(
          <Stack direction="row" spacing={2}>
            <Typography variant="h6" mr={2} lineHeight="38px">{t('alerts.list')}</Typography>
            <FormControlLabel
              control={(
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  onChange={(event) => handleToggleButtonChange(event)}
                  value={statusFilter}
                  size="small"
                >
                  <ToggleButton value={ALERT.STATUSES.ACTIVE.join(',')}>
                    {t('alerts.active_other')}
                  </ToggleButton>
                  <ToggleButton value={ALERT.STATUSES.INACTIVE.join(',')}>
                    {t('alerts.inactive_other')}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Stack>
        )}
        data={data}
        columns={columns}
        options={options}
      />
    </Box>
  );
};

Alerts.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  setStatusFilter: PropTypes.func.isRequired,
  statusFilter: PropTypes.string,
  setSelectedRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  handleClickActivateDeactivate: PropTypes.func.isRequired,
  handleClickActivateDeactivateSelected: PropTypes.func.isRequired,
  handleClickSearch: PropTypes.func.isRequired,
};

Alerts.defaultProps = {
  alerts: [],
  selectedRows: [],
  loading: false,
  statusFilter: ALERT.STATUSES.ACTIVE.join(','),
};

export default Alerts;
