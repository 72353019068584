import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import Highmaps from 'highcharts/highmaps';
import worldMap from '@highcharts/map-collection/custom/world.geo.json';
import proj4 from 'proj4';
import THEME_CONFIG from 'generic/core/theme';

if (typeof window !== 'undefined') {
  window.proj4 = window.proj4 || proj4;
}

HighchartsNoData(Highmaps);
Highmaps.setOptions(THEME_CONFIG.HIGHCHARTS);

const defaultOptions = {
  highcharts: Highmaps,
  constructorType: 'mapChart',
  options: {
    chart: {
      map: worldMap,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: 'spacingBox',
        align: 'right',
      },
    },
    colorAxis: {
      min: 0,
      type: 'linear',
      minColor: Highcharts.getOptions().colors.at(0),
      maxColor: Highcharts.getOptions().colors.at(-1),
      stops: Highcharts.getOptions().colors.map((c, i, arr) => (
        [(i / arr.length), c]
      )),
    },
    credits: { enabled: false },
    plotOptions: {
      series: {
        keys: ['name', 'value'],
        joinBy: ['iso-a2', 'name'],
        states: {
          hover: { color: '#BADA55' },
        },
        tooltip: {
          headerFormat: '',
        },
      },
    },
  },
};

const Map = ({ highchartsOptions }) => {
  const finalConfig = merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact {...finalConfig} />
  );
};

Map.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

Map.defaultProps = {
};

export default Map;
