import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

import { TextField } from 'formik-mui';
import {
  Grid,
  Typography,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';

const textFieldStyle = (theme) => ({
  root: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontFamily: 'Rajdhani, sans-serif',
    fontWeight: 'bold',
    width: '100%',

    '& > .MuiFormHelperText-root': {
      backgroundColor: theme.palette.primary.transparent,
      padding: '.25em 0 .25em .75em',
    },
  },
  input: {
    padding: '.25em 0 .25em .75em',
  },
});

const NewPasswordTextField = withStyles(textFieldStyle)(({ classes, innerRef, ...rest }) => (
  <TextField
    className={classes.root}
    InputProps={{
      disableUnderline: true,
      inputProps: {
        ref: innerRef,
        className: classes.input,
        autocomplete: 'new-password',
      },
    }}
    variant="standard"
    {...rest}
  />
));

const LoadingButtonWithIndicator = ({ ...props }) => (
  <LoadingButton
    sx={{ minWidth: '180px', fontFamily: 'Rajdhani, sans-serif' }}
    color="secondary"
    loadingIndicator={<CircularProgress color="primary" size={16} />}
    {...props}
  />
);

const FormResetPasswordConfirmation = ({ loading }) => {
  const { t } = useTranslation();

  const usernameInput = useRef(null);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-around"
      sx={{ minHeight: '30vh' }}
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
      >
        <Typography variant="h3">
          {t('logon.reset_password_confirmation_title')}
        </Typography>

        <Typography
          variant="subtitle2"
          align="center"
        >
          {t('logon.reset_password_confirmation_description')}
        </Typography>
      </Grid>

      <Grid
        item
        container
        justifyContent="center"
        direction="column"
      >
        <Field name="a" type="hidden" />

        <Box mb={1}>
          <Field
            autoFocus
            component={NewPasswordTextField}
            name="newPassword"
            placeholder={t('logon.password')}
            innerRef={usernameInput}
            required
            type="password"
          />
        </Box>

        <Box mb={1}>
          <Field
            component={NewPasswordTextField}
            name="newPasswordConfirmation"
            placeholder={t('logon.password_confirmation')}
            required
            type="password"
          />
        </Box>
      </Grid>

      <Grid
        alignItems="center"
        container
        direction="row"
        item
        justifyContent="space-around"
      >
        <div>
          <LoadingButtonWithIndicator
            disabled={loading}
            loading={loading}
            type="submit"
          >
            {t('form.send')}
          </LoadingButtonWithIndicator>
        </div>
      </Grid>

    </Grid>
  );
};

FormResetPasswordConfirmation.propTypes = {
  loading: PropTypes.bool.isRequired,
};

FormResetPasswordConfirmation.defaultProps = {
};

export default FormResetPasswordConfirmation;
