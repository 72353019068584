import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { fetchResults, fetchResultsComplete, refreshResults } from 'generic/core/search/actions';
import { stripHtmlTags } from 'generic/utils/utils';
import { computeMLTParams } from 'generic/utils/qesUtils';
import { toggleCheckbox, toggleCheckboxAll } from 'generic/core/selection/actions';
import {
  openDialogAddToCart,
  openDialogAddToNewsletter,
  openDialogCreateAlert,
  openDialogExport,
  openDialogSendToFriend,
} from 'generic/core/actions/actions';
import { fetchMoreLikeThis } from 'generic/core/moreLikeThis/actions';
import { fastGedOpen, setMultiple, types } from 'generic/core/ged/actions';
import { setBackPageLocation } from 'generic/core/ux/actions';
import AddToCartDialogContainer from 'generic/containers/AddToCartDialogContainer';
import AddToNewsletterDialogContainer from 'generic/containers/AddToNewsletterDialogContainer';
import SendToFriendDialogContainer from 'generic/containers/SendToFriendDialogContainer';
import CreateAlertDialogContainer from 'generic/containers/CreateAlertDialogContainer';
import ExportDialogContainer from 'generic/containers/ExportDialogContainer';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import Results from 'generic/components/pages/Results';
import GEDModalContainer from 'generic/containers/GEDModalContainer';

const ResultsContainer = () => {
  const activeBase = useSelector((state) => state.config.activeBase);
  const checkedItems = useSelector((state) => state.selection.checkedItems);
  const loading = useSelector((state) => state.search.loading);
  const loadingBlank = useSelector((state) => state.search.loadingBlank);
  const loadingComplete = useSelector((state) => state.search.loadingComplete);
  const results = useSelector((state) => state.search.results);
  const resultsComplete = useSelector((state) => state.search.resultsComplete);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const { ETAT_DOCUMENT } = QES_CONSTANTS;

  const handlePageChangePagination = (event, page) => {
    const { mouvement, tranche } = results;
    dispatch(fetchResults({
      uriParams: {
        mouvement,
        premier: ((page - 1) * tranche) + 1,
        dernier: (page) * tranche,
      },
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    const { mouvement } = results;
    const { value: tranche } = event.target;
    localStorage.setItem('rowsPerPage', tranche);
    dispatch(fetchResults({
      uriParams: {
        dernier: tranche,
        mouvement,
        premier: 1,
        tranche,
      },
      clearSelection: true,
    }));
  };

  const handleToggleCheckbox = (document) => {
    dispatch(toggleCheckbox({
      id: document.idext,
      item: {
        title: stripHtmlTags(document.titre),
        documentitem: document.documentitem,
        base: document.base,
        idext: document.idext,
      },
    }));
  };

  const handleToggleCheckboxAll = (event) => {
    const { documents } = results;
    const items = documents.reduce((accumulateur, document) => {
      accumulateur[document.idext] = {
        title: stripHtmlTags(document.titre),
        documentitem: document.documentitem,
        base: document.base,
        idext: document.idext,
      };
      return accumulateur;
    }, {});

    dispatch(toggleCheckboxAll({
      checked: event.target.checked,
      items,
    }));
  };

  const handleOpenMultipleGed = () => {
    dispatch(setMultiple(true));
    dispatch(setBackPageLocation(location));
    const firstCheckedItemId = Object.keys(checkedItems)[0];
    const firstCheckedItemBase = checkedItems[firstCheckedItemId].base;
    history.push(`/ged/${firstCheckedItemBase}/${encodeURIComponent(firstCheckedItemId)}`);
  };

  const handleFetchMoreLikeThis = (document) => {
    dispatch(fetchMoreLikeThis(computeMLTParams(activeBase, document.idext)));
  };

  const handleGetDocumentComplete = (documentIdext) => {
    dispatch(fetchResultsComplete(documentIdext));
  };

  const handleOpenDeleteArticleDialog = () => {
    dispatch(setConfirmDialogData({
      title: t('actions.delete'),
      message: (
        <Fragment>
          {t('ged.delete_documents', { count: Object.keys(checkedItems).length })}
        </Fragment>
      ),
      submitColor: 'danger',
      waitForActions: [types.DELETE_DOCUMENTS_SUCCESS, types.DELETE_DOCUMENTS_ERROR],
      action: {
        type: types.DELETE_DOCUMENTS,
        refresh: true,
      },
    }));
  };

  const handleOpenUpdateStatusArticleDialog = (status) => {
    dispatch(setConfirmDialogData({
      title: t('actions.update_status'),
      message: (
        <Fragment>
          {
            t(
              'ged.update_status_to',
              { count: Object.keys(checkedItems).length, status: t(`ged.document.status.${status}`) },
            )
          }
        </Fragment>
      ),
      submitColor: 'primary',
      waitForActions: [types.UPDATE_STATUS_DOCUMENTS_SUCCESS, types.UPDATE_STATUS_DOCUMENTS_ERROR],
      action: {
        type: types.UPDATE_STATUS_DOCUMENTS,
        status,
        refresh: true,
      },
    }));
  };

  if (!loading && _.isEmpty(results.strategie)) {
    return null;
  }

  const allArticlesChecked = !_.isEmpty(results.documents) && _.every(results.documents, (doc) => (
    Object.prototype.hasOwnProperty.call(checkedItems, doc.idext)
  ));

  const someArticlesChecked = !_.isEmpty(results.documents) && _.some(results.documents, (doc) => (
    Object.prototype.hasOwnProperty.call(checkedItems, doc.idext)
  ));

  return (
    <Fragment>
      <Results
        allArticlesChecked={allArticlesChecked}
        canAddToCart={activeBase.panier}
        canAddToNewsletter={activeBase.newsletter}
        canComment={activeBase.commentaire}
        canCreateAlerts={activeBase.veille}
        canEditInGED={activeBase.gedModification}
        canFastEditInGed={activeBase.avecGedRapide}
        canSendToFriend={activeBase.envoi}
        canSortResults={activeBase.triable}
        canDeleteDocument={_.find(results.actions, { etat: ETAT_DOCUMENT.SUPPRIMER }) !== undefined}
        canExport={activeBase.export || activeBase.exportCsv || activeBase.exportPdfWord}
        withMoreLikeThis={activeBase.avecMoreLikeThis}
        checkedItems={checkedItems}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleGetDocumentComplete={handleGetDocumentComplete}
        handleFetchMoreLikeThis={handleFetchMoreLikeThis}
        handleOpenAddToCart={() => dispatch(openDialogAddToCart())}
        handleOpenAddToNewsletter={() => dispatch(openDialogAddToNewsletter())}
        handleOpenCreateAlert={() => dispatch(openDialogCreateAlert())}
        handleOpenMultipleGed={handleOpenMultipleGed}
        handleOpenSendToFriend={() => dispatch(openDialogSendToFriend())}
        handleOpenExport={() => dispatch(openDialogExport())}
        handleOpenDeleteArticleDialog={handleOpenDeleteArticleDialog}
        handleOpenUpdateStatusArticleDialog={handleOpenUpdateStatusArticleDialog}
        handlePageChangePagination={handlePageChangePagination}
        handleToggleCheckbox={handleToggleCheckbox}
        handleToggleCheckboxAll={handleToggleCheckboxAll}
        loading={loading}
        loadingComplete={loadingComplete}
        results={results}
        loadingBlank={loadingBlank}
        resultsComplete={resultsComplete}
        rowsPerPageList={activeBase.tranches}
        openFastGed={(idext, base) => dispatch(fastGedOpen(idext, base))}
        someArticlesChecked={someArticlesChecked}
      />
      <AddToCartDialogContainer scope="qes" />
      <AddToNewsletterDialogContainer scope="qes" />
      <SendToFriendDialogContainer scope="results" />
      <ExportDialogContainer scope="results" />
      <CreateAlertDialogContainer />
      <GEDModalContainer
        afterSave={() => {
          dispatch(refreshResults());
        }}
      />
    </Fragment>
  );
};

export default ResultsContainer;
