import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isDate } from 'date-fns';
import {
  Formik,
  Form,
  FastField,
  Field,
} from 'formik';
import {
  Autocomplete,
  CheckboxWithLabel,
  TextField,
  Select,
  Switch,
} from 'formik-mui';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Select as MUISelect,
  Switch as MUISwitch,
  TextField as MUITextField,
  Tooltip,
  InputLabel,
  Typography,
} from '@mui/material';
import {
  Add,
  Search,
  Remove,
} from '@mui/icons-material';
import clsx from 'clsx';
import { alpha, getContrastRatio } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import FormikDatePicker from 'generic/components/forms/FormikDatePicker';
import AutocompleteAjaxSearch from 'generic/components/forms/AutocompleteAjaxSearch';
import { baseProptype } from 'generic/core/qes/proptypes';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { DATE_INTERVAL_COMPARATOR } = QES_CONSTANTS;
const betweenDatesCode = DATE_INTERVAL_COMPARATOR.between.toString();

const autocompleteRenderOption = (props, option, { inputValue }) => {
  const matches = match(option.libelle, inputValue, { insideWords: true });
  const parts = parse(option.libelle, matches);

  const highlightStyle = {
    fontWeight: 'bold',
  };

  return (
    <li {...props}>
      <div>
        {parts.map((part, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index} style={part.highlight ? highlightStyle : {}}>
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
};

const useStyles = makeStyles((theme) => {
  const headerBackgroundColor = theme.palette?.header?.main || theme.palette.primary.main;
  const headerBackgroundColorIsDark = getContrastRatio(headerBackgroundColor, '#fff') > 3;
  let itemsColors = '#000000';
  if (headerBackgroundColorIsDark) {
    itemsColors = '#ffffff';
  }
  return {
    root: {
      position: 'relative',
      width: '100%',
    },
    search: {
      transition: 'border-radius 0.3s ease 0.2s',
      borderRadius: '18px',
      backgroundColor: alpha(itemsColors, 0.15),
      '&:hover': {
        backgroundColor: alpha(itemsColors, 0.25),
      },
      color: itemsColors,
    },
    toggled: {
      transition: 'border-radius 0s',
      borderRadius: '18px 18px 0 0',
    },
    searchIcon: {
      padding: '0 8px',
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      '&::before, &:hover': {
        border: 'none',
      },
      color: headerBackgroundColorIsDark ? '#fff' : '#111',
    },
    inputInput: {
      padding: '9px 8px 8px 40px',
      transition: theme.transitions.create('width'),
    },
    advancedSearchBtn: {
      padding: '2px',
      marginRight: '3px',
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    formContainer: {
      position: 'absolute',
      width: '100%',
      boxShadow: 'rgb(0 0 0) 0px 4px 8px -2px',
      backgroundColor: '#fff',
      color: '#000',
      zIndex: '2',
    },
  };
});

const FormSearchAdvanced = ({
  activeBaseId,
  activeBaseLabel,
  bases,
  handleChangeActiveBase,
  onSubmit,
  loading,
  initialValues,
  handleClear,
  fieldSimple,
  fieldsAdvanced,
  isVisible,
  fullPage,
  formDisabled,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(fullPage);
  const classes = useStyles();
  const { t } = useTranslation();
  const mainInput = useRef();

  useEffect(() => {
    if (isVisible && mainInput.current) {
      mainInput.current.focus();
    }
  }, [isVisible]);

  const renderField = (field, form) => {
    switch (field.type) {
      case 'text':
        return (
          <FastField
            component={TextField}
            label={field.libelle}
            name={field.code}
            fullWidth
            disabled={formDisabled}
          />
        );
      case 'liste':
        return (
          <Field
            label={field.libelle}
            component={Select}
            name={field.code}
            fullWidth
            displayEmpty
            MenuProps={{
              disablePortal: true,
            }}
          >
            {_.map(
              field.values,
              (value) => (
                <MenuItem
                  key={value.code}
                  value={value.code}
                >
                  {value.code && value.libelle ? (
                    value.libelle
                  ) : (
                    <Typography color="text.neutral" fontStyle="italic">
                      {t('form.unspecified')}
                    </Typography>
                  )}
                </MenuItem>
              ),
            )}
          </Field>
        );
      case 'liste_multi':
      case 'liste_chosen':
        return (
          <FastField
            component={Autocomplete}
            name={field.code}
            options={field.values}
            getOptionLabel={(option) => option?.libelle || ''}
            multiple
            disableCloseOnSelect
            onBlur={() => form.setFieldTouched(field.name)}
            renderInput={(params) => (
              <MUITextField
                {...params}
                label={field.libelle}
              />
            )}
            renderOption={autocompleteRenderOption}
            disabled={formDisabled}
          />
        );
      case 'autocomplete_colonne_direct_regexp':
        return (
          <FastField
            component={AutocompleteAjaxSearch}
            activeBaseId={activeBaseId}
            name={field.code}
            freeSolo
            getOptionLabel={(option) => option}
            textFieldProps={{
              label: field.libelle,
            }}
            disabled={formDisabled}
          />
        );
      case 'liste_checkbox_ou':
      case 'liste_checkbox_et_ou':
        return (
          <FormControl
            component="fieldset"
            className="formFieldset"
            sx={{ marginTop: '0' }}
            fullWidth
          >
            <FormLabel component="legend">
              <Box component="span" mr={2} color={formDisabled ? '#00000061' : 'auto'}>{field.libelle}</Box>
              {field.type === 'liste_checkbox_et_ou' && (
                <FormControlLabel
                  control={(
                    <FastField
                      component={Switch}
                      size="small"
                      type="checkbox"
                      name={`${field.code}_comp`}
                      color="primary"
                      onChange={(e) => {
                        form.setFieldValue(
                          `${field.code}_comp`,
                          e.target.checked ? '1' : '',
                        );
                      }}
                    />
                  )}
                  label={t('form.checkbox.and')}
                  disabled={formDisabled}
                />
              )}
              <FormControlLabel
                control={(
                  <MUISwitch
                    size="small"
                    type="checkbox"
                    color="primary"
                    onChange={(e) => {
                      form.setFieldValue(
                        field.code,
                        e.target.checked ? _.map(field.values, (item) => item.code) : [],
                      );
                    }}
                    disabled={formDisabled}
                  />
                )}
                sx={{ mr: '0' }}
                label={t('form.checkbox.all')}
              />
            </FormLabel>
            <Box ml={1}>
              <FormGroup row>
                {_.map(
                  field.values,
                  (item) => (
                    <FastField
                      component={CheckboxWithLabel}
                      name={field.code}
                      key={item.code}
                      value={item.code}
                      Label={{ label: item.libelle }}
                      type="checkbox"
                      color="primary"
                      disabled={formDisabled}
                    />
                  ),
                )}
              </FormGroup>
            </Box>
          </FormControl>
        );
      case 'date_intervalle': {
        const minDate = new Date(1900, 1, 1);
        const maxDate = new Date(2100, 11, 31);
        const fromValue = form.values[`${field.code}_from`];
        const toValue = form.values[`${field.code}_to`];
        return (
          <Grid
            container
            display="flex"
          >
            <Grid
              item
            >
              <Box
                mr="15px"
              >
                <Field
                  component={Select}
                  name={`${field.code}_comp`}
                  fullWidth
                  MenuProps={{
                    disablePortal: true,
                  }}
                  disabled={formDisabled}
                  onChange={(e) => {
                    // Si la valeur ne correspond pas à "Entre", on vire la valeur
                    // du champ "_to"
                    if (e.target.value !== betweenDatesCode) {
                      form.setFieldValue(
                        `${field.code}_to`,
                        null,
                      );
                    }
                  }}
                >
                  {_.map(
                    field.comparateurs,
                    (comparator) => (
                      <MenuItem
                        key={comparator.code}
                        value={comparator.code}
                      >
                        {comparator.libelle}
                      </MenuItem>
                    ),
                  )}
                </Field>
              </Box>
            </Grid>
            <Grid
              item
            >
              <Box
                width={210}
                mr={2}
              >
                <Field
                  component={FormikDatePicker}
                  name={`${field.code}_from`}
                  minDate={minDate}
                  maxDate={isDate(toValue) && _.isEmpty(form.errors[`${field.code}_to`]) ? toValue : maxDate}
                  inputFormat="dd/MM/yyyy"
                  textFieldProps={{
                    label: `${field.libelle}${form.values[`${field.code}_comp`] === betweenDatesCode ? (
                      ` ${t('form.datepicker.from')}`
                    ) : ''}`,
                    inputProps: {
                      placeholder: t('form.datepicker.placeholder'),
                    },
                  }}
                  disabled={formDisabled}
                />
              </Box>
            </Grid>
            {form.values[`${field.code}_comp`] === betweenDatesCode && (
              <Grid
                item
              >
                <Box
                  width={210}
                >
                  <Field
                    component={FormikDatePicker}
                    name={`${field.code}_to`}
                    minDate={isDate(fromValue) && _.isEmpty(form.errors[`${field.code}_from`]) ? fromValue : minDate}
                    maxDate={maxDate}
                    inputFormat="dd/MM/yyyy"
                    textFieldProps={{
                      label: `${field.libelle}${form.values[`${field.code}_comp`] === betweenDatesCode ? (
                        ` ${t('form.datepicker.to')}`
                      ) : ''}`,
                      inputProps: {
                        placeholder: t('form.datepicker.placeholder'),
                      },
                    }}
                    disabled={formDisabled}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        );
      }
      default:
        return '';
    }
  };

  if (_.isEmpty(initialValues)) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
        setIsFormVisible(false);
      }}
    >
      {(form) => {
        const nbParamSearch = _.filter(
          form.values,
          (val, key) => !_.isEmpty(_.toString(val)) && !/^DATE_\d+_comp$/.test(key),
        ).length;

        let searchAdvancedTooltip = t('header.advanced_search');

        if (nbParamSearch > 0) {
          searchAdvancedTooltip = `${searchAdvancedTooltip} ${t('header.number_criteria', { count: nbParamSearch })}`;
        }

        return (
          <ClickAwayListener
            onClickAway={() => (isFormVisible && !fullPage ? setIsFormVisible(false) : _.noop)}
            mouseEvent="onMouseDown"
          >
            <Box className={
                clsx({
                  [classes.root]: true,
                  [classes.search]: !fullPage,
                  [classes.toggled]: isFormVisible,
                })
              }
            >
              {!fullPage && (
                <Box className={classes.searchIcon}>
                  <Search />
                </Box>
              )}
              <Form
                className={classes.root}
              >
                {!fullPage && (
                  <Field
                    margin="none"
                    sx={{ margin: '0' }}
                    variant="standard"
                    component={TextField}
                    fullWidth
                    autoFocus
                    inputRef={mainInput}
                    name={fieldSimple.code}
                    label=""
                    placeholder={t('form.omni_placeholder', { activeBaseLabel })}
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      },
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title={searchAdvancedTooltip}
                          >
                            <IconButton
                              className={classes.advancedSearchBtn}
                              onClick={() => setIsFormVisible(!isFormVisible)}
                              size="large"
                            >
                              <Badge
                                badgeContent={nbParamSearch}
                                color="error"
                                max={99}
                                size="small"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                sx={{
                                  '& .MuiBadge-badge': {
                                    zIndex: 3,
                                  },
                                }}
                                invisible={nbParamSearch === 0}
                              >
                                {isFormVisible ? <Remove /> : <Add />}
                              </Badge>
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                <Box
                  className={!fullPage ? classes.formContainer : ''}
                >
                  <Collapse in={isFormVisible}>
                    <Box display="flex" flexDirection="column">
                      <Box overflow="auto" maxHeight={!fullPage ? '500px' : 'auto'}>
                        {_.map(
                          fieldsAdvanced,
                          (field) => (
                            <Box key={field.code} mt={1} pl={2} pr={2}>
                              {renderField(field, form)}
                            </Box>
                          ),
                        )}
                      </Box>
                      {!formDisabled && (
                        <Box
                          p={1.5}
                          pt={2}
                          borderTop="1px solid rgba(0, 0, 0, 0.12)"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <div>
                            {bases && bases.length > 1 && (
                              <FormControl sx={{ margin: 0, display: 'flex', flexBasis: '400px' }}>
                                <InputLabel id="select-base-label">
                                  {t('config.base')}
                                </InputLabel>
                                <MUISelect
                                  label={t('config.base')}
                                  labelId="select-base-label"
                                  value={activeBaseId}
                                  onChange={(event) => handleChangeActiveBase(event.target.value)}
                                >
                                  {_.map(bases, (base) => (
                                    <MenuItem key={base.base} value={base.base}>{base.libelle}</MenuItem>
                                  ))}
                                </MUISelect>
                              </FormControl>
                            )}
                          </div>
                          <Stack
                            direction="row"
                            spacing={2}
                          >
                            <Button
                              type="reset"
                              variant="text"
                              onClick={handleClear}
                            >
                              {t('form.clear')}
                            </Button>
                            <Button
                              type="submit"
                              disabled={loading || !form.isValid || nbParamSearch === 0}
                            >
                              {t('form.search')}
                            </Button>
                          </Stack>
                        </Box>
                      )}
                    </Box>
                  </Collapse>
                </Box>
              </Form>
            </Box>
          </ClickAwayListener>
        );
      }}
    </Formik>
  );
};

FormSearchAdvanced.propTypes = {
  activeBaseId: PropTypes.number.isRequired,
  activeBaseLabel: PropTypes.string.isRequired,
  bases: PropTypes.arrayOf(
    baseProptype,
  ).isRequired,
  handleChangeActiveBase: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  handleClear: PropTypes.func,
  initialValues: PropTypes.shape({
    code: PropTypes.string,
    libelle: PropTypes.string,
  }),
  fieldSimple: PropTypes.shape({
    code: PropTypes.string,
    libelle: PropTypes.string,
  }),
  fieldsAdvanced: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      libelle: PropTypes.string,
    }),
  ),
  hasActiveSearch: PropTypes.bool,
  isVisible: PropTypes.bool,
  fullPage: PropTypes.bool,
  formDisabled: PropTypes.bool,
};

FormSearchAdvanced.defaultProps = {
  fieldSimple: {},
  fieldsAdvanced: [],
  initialValues: {},
  handleChangeActiveBase: null,
  onSubmit: null,
  handleClear: null,
  hasActiveSearch: false,
  isVisible: true,
  fullPage: false,
  formDisabled: false,
};

export default FormSearchAdvanced;
