export const types = {
  FETCH_MORE_LIKE_THIS: '@MORE_LIKE_THIS/FETCH_MORE_LIKE_THIS',
  FETCH_MORE_LIKE_THIS_ERROR: '@MORE_LIKE_THIS/FETCH_MORE_LIKE_THIS_ERROR',
};

export const fetchMoreLikeThis = (params, formatComplete) => ({
  type: types.FETCH_MORE_LIKE_THIS,
  params,
  formatComplete,
});

export const fetchMoreLikeThisError = (response) => ({
  type: types.FETCH_MORE_LIKE_THIS_ERROR,
  response,
});
