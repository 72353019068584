export const types = {
  FETCH_WIDGETS: '@DASHBOARD/FETCH_WIDGETS',
  FETCH_WIDGET_SUCCESS: '@DASHBOARD/FETCH_WIDGET_SUCCESS',
  FETCH_WIDGET_ERROR: '@DASHBOARD/FETCH_WIDGET_ERROR',
  CLEANUP_DASHBOARD: '@DASHBOARD/CLEANUP_DASHBOARD',
  SET_DASHBOARD_LOADING: '@DASHBOARD/SET_DASHBOARD_LOADING',
};

export const fetchWidgets = (query, widgets, keepWidgets, baseId) => ({
  type: types.FETCH_WIDGETS,
  query,
  widgets,
  keepWidgets,
  baseId,
});

export const fetchWidgetSuccess = (widgetId, widget) => ({
  type: types.FETCH_WIDGET_SUCCESS,
  widgetId,
  widget,
});

export const fetchWidgetError = (widgetId, response) => ({
  type: types.FETCH_WIDGET_ERROR,
  response,
  widgetId,
});

export const cleanupDashboard = () => ({
  type: types.CLEANUP_DASHBOARD,
});

export const setDashboardLoading = () => ({
  type: types.SET_DASHBOARD_LOADING,
});
