import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import { format } from 'date-fns';

import Highcharts from 'highcharts';
import Highcharts3d from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

Highcharts3d(Highcharts);
HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    colorAxis: {
      min: 0,
      type: 'linear',
      minColor: Highcharts.getOptions().colors.at(0),
      maxColor: Highcharts.getOptions().colors.at(-1),
      stops: Highcharts.getOptions().colors.map((c, i, arr) => (
        [(i / arr.length), c]
      )),
    },
    chart: {
      type: 'scatter3d',
      margin: 180,
      options3d: {
        axisLabelPosition: 'auto',
        enabled: true,
        alpha: 10,
        beta: 30,
        depth: 250,
        viewDistance: 5,
        fitToPlot: false,
      },
    },
    plotOptions: {
      scatter3d: {
        marker: {
          radius: 2,
        },
        dataLabels: {
          enabled: false,
        },
      },
    },
    tooltip: {
      formatter: function tooltipFormatter() {
        return `
        ${this.series.xAxis.options.title.text}: <b>${format(this.point.x)}</b><br />
        ${this.series.yAxis.options.title.text}: <b>${this.point.y}</b><br />
        ${this.series.zAxis.options.title.text}: <b>${this.point.z}</b><br />
        `;
      },
    },
    xAxis: {
      gridLineWidth: 1,
      type: 'datetime',
    },
    yAxis: {
      min: 0,
    },
    zAxis: {
      min: 0,
    },
  },
};

const Scatter3d = ({ highchartsOptions }) => {
  const finalConfig = merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact {...finalConfig} />
  );
};

Scatter3d.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

Scatter3d.defaultProps = {
};

export default Scatter3d;
