import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  Logout,
  NotInterested,
  People,
  RssFeed,
  Notifications,
  ShoppingBasket,
} from '@mui/icons-material';

import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { utilisateurPropType } from 'generic/core/qes/proptypes';
import { civiliteToGender } from 'generic/utils/qesUtils';
import UserAvatar from 'generic/components/ui/UserAvatar';

const TooltipContent = React.memo(({ user }) => {
  const gender = civiliteToGender(user.civilite);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Typography fontWeight="bold">
        {t('account_menu.signed_in_as', { context: gender, logon: user.logon })}
      </Typography>
      {`${user.prenom} ${user.nom}`}
      <br />
      <i>{user.email}</i>
    </React.Fragment>
  );
});

TooltipContent.propTypes = {
  user: utilisateurPropType.isRequired,
};

const AccountMenu = ({
  user,
  hasWatches,
  hasCarts,
  avatarSx,
  webTrackingAccess,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => { setAnchorEl(event.currentTarget); };
  const handleClose = () => { setAnchorEl(null); };
  const theme = useTheme();
  const smallerThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  const { t } = useTranslation();
  const menuWithAnchorStyle = {
    overflow: 'visible',
    boxShadow: 5,
    mt: 1.5,
    minWidth: '15%',
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      marginLeft: -0.5,
      marginRight: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: smallerThanMedium ? 10 : 15,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  };

  return (
    <Fragment>
      <Tooltip title={<TooltipContent user={user} />}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, p: 0 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <UserAvatar user={user} sx={avatarSx} />
        </IconButton>
      </Tooltip>

      <Menu
        PaperProps={{
          sx: menuWithAnchorStyle,
        }}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          component={Link}
          to="/profile"
          selected={location.pathname === '/profile'}
        >
          <ListItemIcon><UserAvatar user={user} sx={{ boxShadow: '0 0 3px 0px #c5c5c5' }} /></ListItemIcon>
          <ListItemText>{t('profile.account')}</ListItemText>
        </MenuItem>

        {(hasCarts || hasWatches) && (
          <Divider />
        )}

        { hasCarts && (
          <MenuItem
            component={Link}
            to="/carts"
            selected={location.pathname.startsWith('/carts')}
          >
            <ListItemIcon><ShoppingBasket fontSize="small" /></ListItemIcon>
            <ListItemText>{t('header.carts')}</ListItemText>
          </MenuItem>
        )}

        { hasWatches && (
          <MenuItem
            component={Link}
            to="/alerts"
            selected={location.pathname.startsWith('/alerts')}
          >
            <ListItemIcon><Notifications fontSize="small" /></ListItemIcon>
            <ListItemText>{t('header.alerts')}</ListItemText>
          </MenuItem>
        )}

        { webTrackingAccess && (
          [
            <Divider key="divider_1" />,
            <MenuItem
              component={Link}
              key="accountMenu/anr/sources"
              selected={location.pathname === '/anr/sources'}
              to="/anr/sources"
            >
              <ListItemIcon><RssFeed fontSize="small" /></ListItemIcon>
              <ListItemText>{t('account_menu.sources')}</ListItemText>
            </MenuItem>,

            <Divider key="divider_2" />,

            <MenuItem
              component={Link}
              key="accountMenu/anr/banned"
              selected={location.pathname === '/anr/banned'}
              to="/anr/banned"
            >
              <ListItemIcon><NotInterested fontSize="small" /></ListItemIcon>
              <ListItemText>{t('anr.banned_sites')}</ListItemText>
            </MenuItem>,
          ]
        )}

        {user.admin && <Divider />}
        {user.admin && (
          <MenuItem
            component={Link}
            to="/users"
            selected={location.pathname === '/users'}
          >
            <ListItemIcon><People fontSize="small" /></ListItemIcon>
            <ListItemText>{t('users.list')}</ListItemText>
          </MenuItem>
        )}

        <Divider />
        <MenuItem
          component={Link}
          to="/logout"
        >
          <ListItemIcon><Logout fontSize="small" /></ListItemIcon>
          <ListItemText>{t('account_menu.logout')}</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

AccountMenu.propTypes = {
  user: utilisateurPropType.isRequired,
  hasWatches: PropTypes.bool,
  hasCarts: PropTypes.bool,
  webTrackingAccess: PropTypes.bool,
  avatarSx: PropTypes.shape({
    sx: PropTypes.shape(),
  }),
};

AccountMenu.defaultProps = {
  hasWatches: false,
  hasCarts: false,
  webTrackingAccess: false,
  avatarSx: {},
};

export default AccountMenu;
