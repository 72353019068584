import React, { Fragment } from 'react';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { FormatListBulleted, QueryStats } from '@mui/icons-material';

import { overrideRessource } from 'generic/utils/utils';
import SearchContainer from 'generic/containers/SearchContainer';

const HeaderMenuItem = overrideRessource('components/ui/HeaderMenuItem');

const HeaderSearchWrapper = ({ showSearchInput }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Box display="flex" alignItems="center" columnGap="5px" flexGrow="1" maxWidth="680px" mx={2}>
      <Box
        display={showSearchInput ? 'block' : 'none'}
        flexGrow="1"
      >
        <SearchContainer
          version="advanced"
          isVisible={showSearchInput}
        />
      </Box>
      {!smallerThanLarge && (
        <Fragment>
          <Tooltip
            title={t('header.dashboard')}
          >
            <HeaderMenuItem
              component={NavLink}
              to={`/dashboard${location?.search || ''}`}
              selected={location.pathname.startsWith('/dashboard')}
              sx={{
                textAlign: 'center',
                p: '6px',
                ml: 0.5,
              }}
            >
              <QueryStats />
            </HeaderMenuItem>
          </Tooltip>
          <Divider orientation="vertical" color="white" flexItem />
          <Tooltip
            title={t('header.results')}
          >
            <HeaderMenuItem
              component={NavLink}
              to={`/results${location?.search || ''}`}
              selected={location.pathname.startsWith('/results')}
              sx={{
                textAlign: 'center',
                p: '6px',
              }}
            >
              <FormatListBulleted />
            </HeaderMenuItem>
          </Tooltip>
        </Fragment>
      )}
    </Box>
  );
};

HeaderSearchWrapper.propTypes = {
  showSearchInput: PropTypes.bool,
};

HeaderSearchWrapper.defaultProps = {
  showSearchInput: true,
};

export default HeaderSearchWrapper;
