import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Chip,
} from '@mui/material';
import {
  ArrowLeftOutlined,
  MoreHorizOutlined,
} from '@mui/icons-material';
import Tag from 'generic/components/ui/Tag';

const ExpandableTagBox = ({
  qesdocument, threshold, tags, disableRefine,
}) => {
  const { t } = useTranslation();
  const [isTagsExpanded, setIsTagsExpanded] = useState(false);

  let totalFiltered = 0;
  let total = 0;

  const myTags = _.map(tags, ({
    fieldId, fieldName, icon, externalLink, color,
  }) => {
    const allTags = _.get(qesdocument, fieldName, []).reduce((acc, fieldValue) => (
      acc.concat(...fieldValue.split(';'))
    ), []);
    total += allTags.length;

    const toDisplay = allTags.slice(0, threshold);
    totalFiltered += toDisplay.length;

    return {
      fieldId,
      fieldName,
      icon,
      externalLink,
      key: `${fieldId}_${fieldName}`,
      allTags,
      toDisplay,
      color,
    };
  });

  return (
    <Box mt={0.5}>
      { myTags.map((tag) => {
        const props = {};
        if (tag.icon) {
          const Icon = tag.icon;
          props.icon = <Icon />;
        }

        return (
          <Tag
            {...props}
            disableRefine={disableRefine}
            key={tag.key}
            tags={isTagsExpanded ? tag.allTags : tag.toDisplay}
            idChamp={tag.fieldId}
            externalLink={tag.externalLink}
            color={tag.color}
          />
        );
      }) }

      { (total > totalFiltered)
      && (
        <Chip
          key="more-less-trigger"
          size="extraSmall"
          sx={{
            height: '20px',
            mt: '0px',
            mb: '4px',
            '& .MuiChip-icon': {
              ml: '2px',
              mr: '-14px',
            },
          }}
          onClick={() => setIsTagsExpanded(!isTagsExpanded)}
          variant="outlined"
          icon={isTagsExpanded ? <ArrowLeftOutlined /> : <MoreHorizOutlined />}
          title={t(`results.tags.${isTagsExpanded ? 'less' : 'more'}`)}
        />
      )}
    </Box>
  );
};

ExpandableTagBox.propTypes = {
  disableRefine: PropTypes.bool,
  qesdocument: PropTypes.shape().isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    fieldId: PropTypes.number.isRequired,
    fieldName: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
    externalLink: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
    ]),
    color: PropTypes.string,
  })).isRequired,
  threshold: PropTypes.number,
};

ExpandableTagBox.defaultProps = {
  threshold: 2,
  disableRefine: false,
};

export default ExpandableTagBox;
