import _ from 'lodash';

import { types } from 'generic/core/selection/actions';

const selectionReducer = (state = { checkedItems: {}, singleItem: null }, action) => {
  switch (action.type) {
    case types.TOGGLE_CHECKBOX: {
      const checkedItems = { ...state.checkedItems };
      if (checkedItems[action.values.id]) {
        delete checkedItems[action.values.id];
      } else {
        checkedItems[action.values.id] = action.values.item;
      }
      return {
        ...state,
        checkedItems,
      };
    }
    case types.TOGGLE_CHECKBOX_ALL: {
      let checkedItems = { ...state.checkedItems };
      if (action.values.checked) {
        checkedItems = { ...checkedItems, ...action.values.items };
      } else {
        checkedItems = _.omitBy(
          checkedItems,
          (value, key) => Object.prototype.hasOwnProperty.call(action.values.items, key),
        );
      }
      return {
        ...state,
        checkedItems,
      };
    }
    case types.SET_SINGLE_ITEM: {
      return {
        ...state,
        singleItem: action.value,
      };
    }
    case types.CLEAR: {
      return {
        ...state,
        checkedItems: {},
      };
    }
    case types.CLEAR_SINGLE_ITEM: {
      return {
        ...state,
        singleItem: null,
      };
    }
    case types.UPDATE_SELECTION: {
      const checkedItems = { ...state.checkedItems };
      _.forOwn(action.items, (item, key) => {
        if (checkedItems[key] !== undefined) {
          checkedItems[key] = {
            ...checkedItems[key],
            ...item,
          };
        }
      });
      return {
        ...state,
        checkedItems,
      };
    }
    default:
      return state;
  }
};

export default selectionReducer;
