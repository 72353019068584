import React from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import {
  TextField,
} from 'formik-mui';
import {
  Box,
} from '@mui/material';
import {
  Search,
} from '@mui/icons-material';
import { alpha, getContrastRatio } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
  const headerBackgroundColor = theme.palette?.header?.main || theme.palette.primary.main;
  const headerBackgroundColorIsDark = getContrastRatio(headerBackgroundColor, '#fff') > 3;
  let itemsColors = '#000000';
  if (headerBackgroundColorIsDark) {
    itemsColors = '#ffffff';
  }
  return {
    search: {
      transition: 'border-radius 0.3s ease 0.2s',
      borderRadius: '18px',
      position: 'relative',
      backgroundColor: alpha(itemsColors, 0.15),
      '&:hover': {
        backgroundColor: alpha(itemsColors, 0.25),
      },
      marginRight: '16px',
      marginLeft: 0,
      width: '100%',
      color: itemsColors,
      [theme.breakpoints.up('sm')]: {
        marginLeft: '24px',
        width: 'auto',
      },
    },
    toggled: {
      transition: 'border-radius 0s',
      borderRadius: '18px 18px 0 0',
    },
    searchIcon: {
      padding: '0 8px',
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      '&::before, &:hover': {
        border: 'none',
      },
      color: '#fff',
    },
    inputInput: {
      padding: '9px 8px 8px 40px',
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('md')]: {
        width: '60ch',
      },
    },
    advancedSearchBtn: {
      padding: '2px',
      marginRight: '3px',
      backgroundColor: '#fff',
    },
    formContainer: {
      position: 'absolute',
      width: '100%',
      boxShadow: 'rgb(0 0 0) 0px 4px 8px -2px',
      backgroundColor: '#fff',
      color: '#000',
      zIndex: '2',
    },
  };
});

const FormSearchSimple = ({
  activeBaseLabel,
  onSubmit,
  initialValues,
  fieldSimple,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {() => (
        <Box className={classes.search}>
          <Box className={classes.searchIcon}>
            <Search />
          </Box>

          <Form>
            <Field
              margin="none"
              variant="standard"
              component={TextField}
              fullWidth
              name={fieldSimple.code}
              label=""
              placeholder={t('form.omni_placeholder', { activeBaseLabel })}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  input: classes.inputInput,
                },
                disableUnderline: true,
              }}
            />
          </Form>
        </Box>
      )}
    </Formik>
  );
};

FormSearchSimple.propTypes = {
  activeBaseLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    code: PropTypes.string,
    libelle: PropTypes.string,
  }),
  fieldSimple: PropTypes.shape({
    code: PropTypes.string,
    libelle: PropTypes.string,
  }),
};

FormSearchSimple.defaultProps = {
  initialValues: {},
  fieldSimple: {},
};

export default FormSearchSimple;
