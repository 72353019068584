import {
  put,
  call,
  takeLatest,
  select,
  all,
} from 'redux-saga/effects';
import i18next from 'i18next';
import _ from 'lodash';
import {
  types,
  fetchNewsletters,
  fetchNewslettersSuccess,
  fetchNewslettersError,
  deleteNewslettersSuccess,
  deleteNewslettersError,
  fetchNewsletterSuccess,
  fetchNewsletterError,
  deleteNewsletterDocumentsSuccess,
  deleteNewsletterDocumentsError,
  fetchNewsletter,
  saveNewsletterError,
  saveNewsletterSuccess,
  addToNewsletterSuccess,
  addToNewsletterError,
  sendNewsletterSuccess,
  sendNewsletterError,
  sendNewsletterHistoSuccess,
  sendNewsletterHistoError,
  patchPanierDocumentsError,
} from 'generic/core/newsletters/actions';
import {
  fetchNewsletters as fetchNewslettersApi,
  fetchNewsletter as fetchNewsletterApi,
  addToNewsletter as addToNewsletterApi,
  deleteNewsletters,
  deleteNewsletterDocuments,
  updateNewsletter,
  sendNewsletter,
  sendNewsletterHisto,
  patchPanierDocuments,
} from 'generic/api/newsletter';
import { snackActions } from 'generic/utils/snackbar';
import { clearSelection, clearSingleItem } from 'generic/core/selection/actions';
import { closeDialogAddToNewsletter } from 'generic/core/actions/actions';
import { workAnRAddToNewsletter } from 'generic/core/anr/sagas';

function* workFetchNewsletters() {
  try {
    const newsletters = yield call(fetchNewslettersApi);
    yield put(fetchNewslettersSuccess(newsletters));
  } catch (error) {
    yield put(fetchNewslettersError(error));
    console.error(error);
    snackActions.error(i18next.t('newsletters.error_fetching_newsletters'));
  }
}

function* watchFetchNewsletters() {
  yield takeLatest(types.FETCH_NEWSLETTERS, workFetchNewsletters);
}

function* workDeleteNewsletters({ ids }) {
  try {
    yield call(deleteNewsletters, ids);
    yield put(deleteNewslettersSuccess());
    snackActions.success(i18next.t('newsletters.newsletters_deleted', { count: ids.length }));
    yield put(fetchNewsletters());
  } catch (error) {
    yield put(deleteNewslettersError(error));
    console.error(error);
    snackActions.error(i18next.t('newsletters.error_deleting_newsletters', { count: ids.length }));
  }
}

function* watchDeleteNewsletters() {
  yield takeLatest(types.DELETE_NEWSLETTERS, workDeleteNewsletters);
}

function* workFetchNewsletter(action) {
  try {
    const newsletter = yield call(fetchNewsletterApi, action.id);
    yield put(fetchNewsletterSuccess(newsletter));
  } catch (error) {
    yield put(fetchNewsletterError(error));
    console.error(error);
    snackActions.error(i18next.t('newsletters.error_fetching_newsletter'));
  }
}

function* watchFetchNewsletter() {
  yield takeLatest(types.FETCH_NEWSLETTER, workFetchNewsletter);
}

function* workDeleteNewsletterDocuments({ id, ids }) {
  try {
    yield call(deleteNewsletterDocuments, ids);
    yield put(deleteNewsletterDocumentsSuccess());
    snackActions.success(i18next.t('newsletters.newsletter_documents_deleted', { count: ids.length }));
    yield put(fetchNewsletter(id));
  } catch (error) {
    yield put(deleteNewsletterDocumentsError(error));
    console.error(error);
    snackActions.error(i18next.t('newsletters.error_deleting_newsletter_documents', { count: ids.length }));
  } finally {
    yield put(clearSelection());
  }
}

function* watchDeleteNewsletterDocuments() {
  yield takeLatest(types.DELETE_NEWSLETTER_DOCUMENTS, workDeleteNewsletterDocuments);
}

function* workSaveNewsletter({ params }) {
  try {
    const results = yield call(updateNewsletter, { bodyItems: { ...params } });
    yield put(saveNewsletterSuccess(results));
    snackActions.success(i18next.t('newsletters.newsletter_updated'));
    yield put(fetchNewsletter(params.panier));
  } catch (error) {
    yield put(saveNewsletterError(error));
    console.error(error);
    snackActions.error(i18next.t('newsletters.error_updating_newsletter'));
  }
}

function* watchSaveNewsletter() {
  yield takeLatest(types.SAVE_NEWSLETTER, workSaveNewsletter);
}

/* Saga Ajout de documents QES à une newsletter */
function* workQesAddToNewsletter(params) {
  try {
    const singleItem = yield select((state) => state.selection.singleItem);

    let documentitemIds;
    if (singleItem) {
      documentitemIds = [singleItem.documentitem];
    } else {
      const checkedItems = yield select((state) => state.selection.checkedItems);
      documentitemIds = _.map(
        checkedItems,
        (item) => item.documentitem,
      );
    }
    const {
      newsletterId: panier,
      newNewsletterName: intituleNewPanier,
      theme: themePanier,
    } = params;
    const newsletterParams = {
      panier,
      intituleNewPanier,
      themePanier,
    };
    yield call(addToNewsletterApi, { bodyItems: { ...newsletterParams, documentitem: documentitemIds } });
    yield put(addToNewsletterSuccess());
    if (_.isEmpty(singleItem)) {
      yield put(clearSelection());
    } else {
      yield put(clearSingleItem());
    }
    snackActions.success(i18next.t('newsletters.docs_added_to_newsletter', { count: documentitemIds.length }));
    yield put(closeDialogAddToNewsletter());
  } catch (error) {
    yield put(addToNewsletterError(error));
    console.error(error);
    snackActions.error(i18next.t('newsletters.error_adding_to_newsletter'));
  }
}

function* workAddToNewsletter({ params, scope }) {
  if (scope === 'anr') {
    yield call(workAnRAddToNewsletter, params);
  } else {
    yield call(workQesAddToNewsletter, params);
  }
}

function* watchAddToNewsletter() {
  yield takeLatest(types.ADD_TO_NEWSLETTER, workAddToNewsletter);
}

function* sendNewsletterWorker({ id, params }) {
  try {
    const newsletter = yield call(sendNewsletter, id, params);
    yield put(sendNewsletterSuccess(newsletter));
    snackActions.success(i18next.t('newsletters.send_success'));
  } catch (error) {
    yield put(sendNewsletterError(error));
    console.error(error);
    snackActions.error(i18next.t('newsletters.send_error'));
  }
}

function* watchSendNewsletter() {
  yield takeLatest(types.SEND_NEWSLETTER, sendNewsletterWorker);
}

function* sendNewsletterHistoWorker({ id, params }) {
  try {
    const newsletter = yield call(sendNewsletterHisto, id, params);
    yield put(sendNewsletterHistoSuccess(newsletter));
    snackActions.success(i18next.t('newsletters.send_success'));
  } catch (error) {
    yield put(sendNewsletterHistoError(error));
    console.error(error);
    snackActions.error(i18next.t('newsletters.send_error'));
  }
}

function* watchSendNewsletterHisto() {
  yield takeLatest(types.SEND_NEWSLETTER_HISTO, sendNewsletterHistoWorker);
}

function* patchPanierDocumentsWorker({ panierDocuments }) {
  try {
    yield all(panierDocuments.map((pdoc) => call(patchPanierDocuments, { bodyItems: pdoc })));
    const newsletter = yield call(fetchNewsletterApi, panierDocuments[0].panier);
    yield put(fetchNewsletterSuccess(newsletter));
  } catch (error) {
    yield put(patchPanierDocumentsError(error));
    console.error(error);
    snackActions.error(i18next.t('newsletters.patch_documents_error'));
  }
}

function* watchPatchPanierDocuments() {
  yield takeLatest(types.PATCH_PANIER_DOCUMENTS, patchPanierDocumentsWorker);
}

function* updatePanierDocumentsOrderByIndexWorker({ panierDocuments }) {
  try {
    yield all(panierDocuments.map((p) => {
      const bodyItems = {
        panier_document: p.panier_document,
        ordre: p.ordre,
      };
      return call(patchPanierDocuments, { bodyItems });
    }));

    const newsletter = yield call(fetchNewsletterApi, panierDocuments[0].panier);
    yield put(fetchNewsletterSuccess(newsletter));
  } catch (error) {
    yield put(patchPanierDocumentsError(error));
    console.error(error);
    snackActions.error(i18next.t('newsletters.patch_documents_error'));
  }
}
function* watchUpdatePanierDocumentsOrderByIndex() {
  yield takeLatest(types.UPDATE_PANIER_DOCUMENTS_ORDER_BY_INDEX, updatePanierDocumentsOrderByIndexWorker);
}

export default {
  watchFetchNewsletters,
  watchDeleteNewsletters,
  watchFetchNewsletter,
  watchDeleteNewsletterDocuments,
  watchSaveNewsletter,
  watchAddToNewsletter,
  watchSendNewsletter,
  watchSendNewsletterHisto,
  watchPatchPanierDocuments,
  watchUpdatePanierDocumentsOrderByIndex,
};
