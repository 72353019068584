import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  CircularProgress,
  Input,
} from '@mui/material';
import 'react-image-crop/dist/ReactCrop.css';
import { Image, AddPhotoAlternateOutlined } from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';
import { uploadUserImage } from 'generic/core/config/actions';
import QES_CONSTANTS from 'generic/core/qes/constants';

const { API_ENTRY_POINT } = QES_CONSTANTS;

const AvatarUploadImage = ({
  imageUploading,
  userId,
  userImage,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef();

  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();
  const avatarSrc = `${API_ENTRY_POINT}/utilisateur/${userId}/avatar?logon=${logon}&key=${key}&a=${userImage}`;

  const handleFileChange = (target) => {
    if (target.files && target.files.length > 0) {
      const fd = new FormData();
      fd.append('file', target.files[0]);
      dispatch(uploadUserImage(fd));
    }
  };

  useEffect(() => {
    if (!imageUploading) {
      inputRef.current.value = null;
    }
  }, [imageUploading]);

  return (
    <Box
      position="relative"
      display="block"
    >
      <Avatar
        src={avatarSrc}
        alt=""
        sx={{
          flexGrow: 1,
          width: 130,
          height: 130,
          borderRadius: '50%',
          border: '1px solid #CCCCCC',
        }}
      >
        <Image sx={{ fontSize: 80 }} />
      </Avatar>
      <Box
        position="absolute"
        bottom="0%"
        right="0%"
      >
        <TooltipButton
          title={t('profile.upload_img_user')}
          disabled={imageUploading}
          edge="start"
          size="extraSmall"
          tag="fab"
          color="primary"
          sx={{
            borderRadius: '5px',
            boxShadow: 'none',
            '&:active': {
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              backgroundColor: 'primary.main',
              color: 'white',
            },
          }}
        >
          {imageUploading ? <CircularProgress color="inherit" size={20} /> : <AddPhotoAlternateOutlined />}
          <Input
            inputRef={inputRef}
            type="file"
            sx={{ position: 'absolute', width: '100%', opacity: 0 }}
            onChange={(event) => handleFileChange(event.target)}
            disabled={imageUploading}
            inputProps={{
              accept: '.jpg, .jpeg, .png, .tiff',
            }}
            title=""
          />
        </TooltipButton>
      </Box>
    </Box>
  );
};

AvatarUploadImage.propTypes = {
  imageUploading: PropTypes.bool,
  userId: PropTypes.number.isRequired,
  userImage: PropTypes.string,
};

AvatarUploadImage.defaultProps = {
  imageUploading: false,
  userImage: '',
};

export default AvatarUploadImage;
