import React from 'react';
import { Box } from '@mui/material';
import CenteredCircularProgress from 'generic/components/ui/CenteredCircularProgress';

const GlobalLoading = () => (
  <Box
    position="relative"
    height="100%"
    width="100%"
  >
    <Box
      position="absolute"
      height="100%"
      width="100%"
    >
      <CenteredCircularProgress />
    </Box>
  </Box>
);

export default GlobalLoading;
