import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import {
  Badge,
  Box,
  Button,
  Drawer,
  Fab,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Clear,
  Close,
  FilterAlt,
  RestartAlt,
  Search,
} from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';
import FormikDatePicker from 'generic/components/forms/FormikDatePicker';
import clsx from 'clsx';
import { subDays } from 'date-fns';

const FormAnRFilters = ({
  initialValues,
  numberParams,
  stateOpenFilterNav,
  handleOpenFilterNav,
  handleResetForm,
  handleSubmit,
  showNewsInputs,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Fragment>
      { /* ______ Bouton Access Filtre ______ */ }
      <Badge
        badgeContent={numberParams}
        color="error"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <TooltipButton
          title={t('anr.actions.filters')}
          size="small"
          color="secondary"
          sx={{ minWidth: '0px' }}
          onClick={handleOpenFilterNav}
        >
          <FilterAlt />
        </TooltipButton>
      </Badge>
      { /* ______ Container Filtres ______ */ }
      <Drawer
        variant={smallerThanLarge ? 'temporary' : 'persistent'}
        open={stateOpenFilterNav}
        onClose={handleOpenFilterNav}
        PaperProps={{
          sx: !smallerThanLarge ? {
            height: 'auto',
            width: '25%',
            top: '64px',
            bottom: '0',
            boxShadow: '4px 0 8px 0 rgba(0, 0, 0, 0.2)',
          } : {
            width: '75%',
          },
        }}
      >
        { /* Titre */ }
        <Box
          position="sticky"
          top="0"
          display="flex"
          p={2}
          backgroundColor="white"
          borderBottom="1px solid"
          borderColor="divider"
        >
          <Typography
            variant="h6"
            flexGrow={1}
            textAlign="center"
          >
            {t('anr.actions.filters')}
          </Typography>
          <Fab
            size="extraSmall"
            color="secondary"
            onClick={handleOpenFilterNav}
          >
            <Close />
          </Fab>
        </Box>
        { /* Formulaire Filtres */ }
        <Formik
          initialValues={initialValues}
          validateOnChange
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {(form) => (
            <Form
              className={
                clsx(
                  'displayFlex',
                  'flexGrow1',
                  'flexDirectionColumn',
                  'overflowAuto',
                )
              }
            >
              { /* Inputs */ }
              <Box
                flexGrow="1"
                p={2}
                className={
                  clsx(
                    'overflowAuto',
                  )
                }
              >
                { /* Inputs dédiés aux actualités */ }
                {showNewsInputs && (
                  <Fragment>
                    { /* Input Filtre Recherche */ }
                    <Field
                      autoFocus
                      component={TextField}
                      name="subquery"
                      label={t('form.search')}
                      color="primary"
                      InputProps={{
                        sx: {
                          pr: 0.5,
                          mb: 1.5,
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                          >
                            <TooltipButton
                              title={t('form.clear')}
                              tag="iconButton"
                              onClick={() => {
                                form.setFieldValue('subquery', '');
                                form.handleSubmit();
                              }}
                              disabled={!form.values.subquery}
                            >
                              <Clear />
                            </TooltipButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    { /* Input Filtre Site */ }
                    <Field
                      component={TextField}
                      name="site"
                      label={t('anr.site')}
                      color="primary"
                      InputProps={{
                        sx: {
                          pr: 0.5,
                          mb: 1.5,
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                          >
                            <TooltipButton
                              title={t('form.clear')}
                              tag="iconButton"
                              onClick={() => {
                                form.setFieldValue('site', '');
                                form.handleSubmit();
                              }}
                              disabled={!form.values.site}
                            >
                              <Clear />
                            </TooltipButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Fragment>
                )}
                { /* Input Filtre Date */ }
                <Stack direction="row" gap={1} mb={1.5}>
                  { /* Input Date Début */ }
                  <Field
                    component={FormikDatePicker}
                    name="from"
                    inputFormat="dd/MM/yyyy"
                    minDate={subDays(new Date(), 13)}
                    maxDate={new Date()}
                    textFieldProps={{
                      label: t('form.datepicker.from'),
                      inputProps: {
                        placeholder: t('form.datepicker.placeholder'),
                      },
                    }}
                    onAccept={form.handleSubmit}
                  />
                  { /* Input Date Fin */ }
                  <Field
                    component={FormikDatePicker}
                    name="to"
                    inputFormat="dd/MM/yyyy"
                    minDate={subDays(new Date(), 13)}
                    maxDate={new Date()}
                    textFieldProps={{
                      label: t('form.datepicker.to'),
                      inputProps: {
                        placeholder: t('form.datepicker.placeholder'),
                      },
                    }}
                    onAccept={form.handleSubmit}
                  />
                </Stack>
              </Box>
              { /* Bouton Filtrer et Reset */ }
              <Stack
                className="bottomBtnsContainer"
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                { /* Bouton Filtrer */ }
                <Tooltip title={t('anr.actions.filter')} placement="left">
                  <Button color="primary" type="submit" disabled={!form.isValid}>
                    <Search />
                  </Button>
                </Tooltip>
                { /* Bouton Reset */ }
                <Tooltip title={t('anr.actions.reset')} placement="right">
                  <Button
                    color="primary"
                    onClick={() => {
                      form.resetForm();
                      handleResetForm();
                    }}
                  >
                    <RestartAlt />
                  </Button>
                </Tooltip>
              </Stack>
            </Form>
          )}
        </Formik>
      </Drawer>
    </Fragment>
  );
};

FormAnRFilters.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  numberParams: PropTypes.number.isRequired,
  stateOpenFilterNav: PropTypes.bool.isRequired,
  handleOpenFilterNav: PropTypes.func.isRequired,
  handleResetForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showNewsInputs: PropTypes.bool.isRequired,
};

export default FormAnRFilters;
