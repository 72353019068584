import {
  call,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import _ from 'lodash';
import { format } from 'generic/utils/dateUtils';
import {
  types,
  deleteAnRFolderSuccess,
  deleteAnRFolderError,
  fetchAnRUserSuccess,
  fetchAnRUserError,
  fetchAnRFolders,
  fetchAnRFoldersSuccess,
  fetchAnRFoldersError,
  fetchAnRFolderNewsSuccess,
  fetchAnRFolderNewsError,
  fetchAnRFolderQuotesSuccess,
  fetchAnRFolderQuotesError,
  fetchAnRGlimpseSuccess,
  fetchAnRGlimpseError,
  fetchAnRFolder,
  fetchAnRFolderSuccess,
  fetchAnRFolderError,
  saveAnRFolderSuccess,
  saveAnRFolderError,
  fetchResultsMethodsMap,
  saveAnRFavoriteStatusSuccess,
  saveAnRFavoriteStatusError,
  incrementOrDecrementNbFolders,
  openArticle,
  fetchActiveItemResults,
  fetchAnRSourcesSuccess,
  fetchAnRSourcesError,
  demandNewSourceSuccess,
  demandNewSourceError,
  fetchAnRBannedSitesSuccess,
  fetchAnRBannedSitesError,
  fetchAnrDuplicatesSuccess,
  fetchAnrDuplicatesError,
  unbanSiteSuccess,
  unbanSiteError,
  collectSuccess,
  collectError,
  banSiteError,
  banSiteSuccess,
  closeBanSiteDialog,
  closeDemandSourceDialog,
  validateAction,
  validateActionSuccess,
  deleteArticlesSuccess,
  deleteArticlesError,
  sendAction,
  sendActionSuccess,
  reportExtractionError,
  reportExtractionSuccess,
  setLoadingDuplicatesArticle,
} from 'generic/core/anr/actions';
import {
  createFolder,
  deleteFolder,
  demandNewSource,
  getUser,
  getFolders,
  getFolder,
  getNews,
  getQuotes,
  getGlimpse,
  setFavoriteStatus,
  updateFolder,
  getSources,
  getBannedSites,
  unbanSite,
  validate,
  banSite,
  deleteArticles,
  send,
  reportExtraction,
  getDuplicates,
} from 'generic/api/anr';
import {
  addToCartOrNewsletter,
  buildSendToFriend,
  collect,
  submitSendToFriend,
} from 'generic/api/anrToQes';
import { snackActions } from 'generic/utils/snackbar';
import { clearSelection, clearSingleItem, updateSelection } from 'generic/core/selection/actions';
import { addToCartError, addToCartSuccess } from 'generic/core/carts/actions';
import {
  closeDialogAddToCart,
  closeDialogAddToNewsletter,
  closeDialogSendToFriend,
} from 'generic/core/actions/actions';
import { addToNewsletterError, addToNewsletterSuccess } from 'generic/core/newsletters/actions';
import {
  buildSendToFriendError,
  buildSendToFriendSuccess,
  submitSendToFriendError,
  submitSendToFriendSuccess,
} from 'generic/core/sendToFriend/actions';
import ANR_CONSTANTS from 'generic/core/anr/constants';

const {
  BANNED_SITES_FIELDS,
  FOLDER_FIELDS,
  FOLDER_RESULTS_VIEWS,
  FOLDERS_FIELDS,
  NEWS_FIELDS,
  QUOTES_FIELDS,
  RESULTS_VIEWS,
  SOURCES_FIELDS,
  USER_FIELDS,
} = ANR_CONSTANTS;

/* Saga récupération de l'utilisateur */
function* user() {
  try {
    const results = yield call(getUser, { uriParams: { fields: USER_FIELDS } });
    yield put(fetchAnRUserSuccess(results));
  } catch (error) {
    yield put(fetchAnRUserError(error));
    console.error(error);
    snackActions.error(i18next.t('anr.error_user_fetch'));
  }
}

function* watchUser() {
  yield takeLatest(types.FETCH_ANR_USER, user);
}

/* ------------- SAGAS SUR LES DOSSIERS ---------------- */

/* Saga récupération des dossiers */
function* folders() {
  try {
    const results = yield call(getFolders, { uriParams: { fields: FOLDERS_FIELDS } });
    yield put(fetchAnRFoldersSuccess(results));
  } catch (error) {
    yield put(fetchAnRFoldersError(error));
    console.error(error);
    snackActions.error(i18next.t('anr.error_fetching_folders'));
  }
}

function* watchFolders() {
  yield takeLatest(types.FETCH_ANR_FOLDERS, folders);
}

/* Saga récupération d'un dossier pour son édition */
function* fetchFolder({ params }) {
  try {
    const results = yield call(
      getFolder,
      { uriParams: { ...params, fields: FOLDER_FIELDS, force_expert_quotes: 1 } },
    );
    yield put(fetchAnRFolderSuccess(results));
  } catch (error) {
    yield put(fetchAnRFolderError(error));
    console.error(error);
    snackActions.error(i18next.t('anr.error_fetching_folder'));
  }
}

function* watchFolder() {
  yield takeLatest(types.FETCH_ANR_FOLDER, fetchFolder);
}

function* setEditFolder({ editFolderId }) {
  yield put(fetchAnRFolder({ folder_id: editFolderId }));
}

function* watchSetEditFolder() {
  yield takeLatest(types.SET_EDIT_FOLDER, setEditFolder);
}

/* Saga enregistrement d'un dossier */
function* saveAnRFolder({ params }) {
  try {
    const isNew = _.isEmpty(params.id);
    const endpoint = isNew ? createFolder : updateFolder;
    const results = yield call(endpoint, { bodyItems: { ...params } });
    yield put(saveAnRFolderSuccess(results));
    if (isNew) {
      yield put(incrementOrDecrementNbFolders(1));
    }
    const translationKey = isNew ? 'anr.folder_created' : 'anr.folder_updated';
    snackActions.success(i18next.t(translationKey));
    yield put(fetchAnRFolders());
    const { folder } = results;
    const view = folder.has_news || (!folder.has_news && !folder.has_quotes) ? 'news' : 'quotes';
    yield put(push(`/anr/folders/${folder.id}/${view}/scroll`));
  } catch (error) {
    yield put(saveAnRFolderError(error));
    console.error(error);
    snackActions.error(i18next.t('anr.error_saving_folder'));
  }
}

function* watchSaveFolder() {
  yield takeLatest(types.SAVE_ANR_FOLDER, saveAnRFolder);
}

/* Saga suppression d'un dossier */
function* deleteAnRFolder({ params }) {
  let message;
  try {
    const results = yield call(deleteFolder, { bodyItems: { ...params } });
    message = _.get(results, 'response.message');
    if (message !== 'OK') {
      throw new Error(message);
    }
    yield put(deleteAnRFolderSuccess());
    yield put(incrementOrDecrementNbFolders(-1));
    snackActions.success(i18next.t('anr.folder_deleted'));
    yield put(fetchAnRFolders());
    yield put(push('/anr/folders'));
  } catch (error) {
    yield put(deleteAnRFolderError(error));
    console.error(error);
    snackActions.error(message || i18next.t('anr.error_deleting_folder'));
  }
}

function* watchDeleteFolder() {
  yield takeLatest(types.DELETE_FOLDER, deleteAnRFolder);
}

/* Saga ajout d'un dossier ou d'une branche en Favori */
function* setAnRFavoriteStatus({ params }) {
  try {
    const results = yield call(setFavoriteStatus, { bodyItems: { ...params } });
    yield put(saveAnRFavoriteStatusSuccess(results));
    yield put(fetchAnRFolders());
  } catch (response) {
    yield put(saveAnRFavoriteStatusError(response));
    console.error(response);
    snackActions.error(i18next.t('anr.error_bookmarking'));
  }
}

function* watchSaveFavoriteStatus() {
  yield takeLatest(types.SAVE_ANR_FAVORITE_STATUS, setAnRFavoriteStatus);
}
function checkIfLastValidatedExists(results) {
  if (_.get(results, 'response.go_to_last_validated', false) === true && _.get(results, 'response.slice', 0) === -1) {
    snackActions.warning(i18next.t('anr.warning_unreachable_validated_article'));
  }
}

/* ------------- SAGAS SUR LES RESULTATS ---------------- */

/* Saga récupération des news d'un dossier */
function* fetchNews({ params }) {
  try {
    const results = yield call(getNews, { uriParams: { ...params, fields: NEWS_FIELDS } });
    checkIfLastValidatedExists(results);
    yield put(fetchAnRFolderNewsSuccess(results));
  } catch (error) {
    yield put(fetchAnRFolderNewsError(error));
    console.error(error);
    snackActions.error(i18next.t('anr.error_fetching_news'));
  }
}
/* Saga récupération de l'œil sur l'actualité */
function* fetchGlimpse({ params }) {
  try {
    const results = yield call(getGlimpse, { uriParams: { ...params, fields: NEWS_FIELDS } });
    checkIfLastValidatedExists(results);
    yield put(fetchAnRGlimpseSuccess(results));
  } catch (error) {
    yield put(fetchAnRGlimpseError(error));
    console.error(error);
    snackActions.error(i18next.t('anr.error_fetching_news'));
  }
}

/* Saga récupération des citations d'un dossier */
function* fetchQuotes({ params }) {
  try {
    const results = yield call(getQuotes, { uriParams: { ...params, fields: QUOTES_FIELDS } });
    checkIfLastValidatedExists(results);
    yield put(fetchAnRFolderQuotesSuccess(results));
  } catch (error) {
    yield put(fetchAnRFolderQuotesError(error));
    console.error(error);
    snackActions.error(i18next.t('anr.error_fetching_quotes'));
  }
}

/* Sagas récupération des infos (Œil, actus/citations d'un dossier) en fonction de l'action */
function* fetchResults(action) {
  const sagasMap = {
    [types.FETCH_ANR_FOLDER_NEWS]: fetchNews,
    [types.FETCH_ANR_GLIMPSE]: fetchGlimpse,
    [types.FETCH_ANR_FOLDER_QUOTES]: fetchQuotes,
  };
  yield call(sagasMap[action.type], action);
}

function* watchResults() {
  yield takeLatest([
    types.FETCH_ANR_FOLDER_NEWS,
    types.FETCH_ANR_FOLDER_QUOTES,
    types.FETCH_ANR_GLIMPSE,
  ], fetchResults);
}

/* Saga de récupération des résultats pour l'élément actif
   (premier chargement, pagination, filtrage...) */
function* workFetchActiveItemResults({ fetchParams, options }) {
  const {
    id,
    view,
    has_news: hasNews,
    has_quotes: hasQuotes,
  } = yield select((state) => state.anr.activeItem);
  const canFetch = _.includes(RESULTS_VIEWS, view) && (hasNews || hasQuotes);
  if (canFetch) {
    const forceClearSelection = _.get(options, 'forceClearSelection', null);
    if (forceClearSelection) {
      yield put(clearSelection());
    }
    const limit = yield select((state) => state.anr.user.nb_links);
    let params = { limit };
    if (_.includes(FOLDER_RESULTS_VIEWS, view)) {
      params.folder_id = id;
    }

    if (!_.isEmpty(fetchParams)) {
      params = {
        ...params,
        ...fetchParams,
      };
    }

    const responseData = yield select((state) => state.anr.responseData);
    if (!_.isNumber(params.offset) && _.get(responseData, 'offset', null) !== null) {
      params.offset = responseData.offset;
    }

    const filtersParams = yield select((state) => state.anr.filtersParams);
    if (!_.isEmpty(filtersParams)) {
      const alteredFiltersParams = { ...filtersParams };
      _.forOwn(filtersParams, (value, key) => {
        if (_.includes(['from', 'to'], key) && value !== null) {
          alteredFiltersParams[key] = format(value);
        }
      });
      params = {
        ...params,
        ...alteredFiltersParams,
      };
    }
    const openArticleOption = _.get(options, 'openArticleOption', null);
    yield put(fetchResultsMethodsMap[view](params, openArticleOption));
  }
}

function* watchFetchActiveItemResults() {
  yield takeLatest([
    types.CHANGE_ACTIVE_ITEM,
    types.FETCH_ACTIVE_ITEM_RESULTS,
  ], workFetchActiveItemResults);
}

function* workChangeActiveItemCleaning() {
  yield put(clearSelection());
}

function* watchChangeActiveItem() {
  yield takeLatest(types.CHANGE_ACTIVE_ITEM, workChangeActiveItemCleaning);
}

/* ------------- SAGAS SUR LES ARTICLES ---------------- */

function* workTryToOpenArticle() {
  const openArticleOption = yield select((state) => state.anr.openArticleOption);
  if (!_.isEmpty(openArticleOption)) {
    const articles = yield select((state) => state.anr.articles);
    let article;
    if (!_.isEmpty(articles)) {
      if (openArticleOption === 'first') {
        [article] = articles;
      } else if (openArticleOption === 'last') {
        const lastIndex = articles.length - 1;
        article = articles[lastIndex];
      } else {
        article = _.find(articles, { id: openArticleOption });
      }
    }
    if (article) {
      yield put(openArticle(article));
    }
  }
}

function* watchFetchSuccess() {
  yield takeLatest([
    types.FETCH_ANR_FOLDER_NEWS_SUCCESS,
    types.FETCH_ANR_FOLDER_QUOTES_SUCCESS,
    types.FETCH_ANR_GLIMPSE_SUCCESS,
  ], workTryToOpenArticle);
}

/* Saga ouverture de l'article précédent ou suivant dans la liste */
function* workOpenSiblingArticle(action) {
  const articles = yield select((state) => state.anr.articles);
  const responseData = yield select((state) => state.anr.responseData);
  const limit = yield select((state) => state.anr.user.nb_links);
  const currentIndex = _.findIndex(articles, { opened: true });
  let siblingIndex;
  let openArticleOption = null;
  let offset;
  if (action.type === types.OPEN_PREVIOUS_ARTICLE) {
    siblingIndex = currentIndex - 1;
    if (siblingIndex < 0) {
      openArticleOption = 'last';
      offset = responseData.offset - limit;
    }
  } else {
    siblingIndex = currentIndex + 1;
    if (siblingIndex >= articles.length) {
      openArticleOption = 'first';
      offset = responseData.offset + limit;
    }
  }
  if (openArticleOption === null) {
    yield put(openArticle(articles[siblingIndex]));
  } else {
    yield put(fetchActiveItemResults({ offset }, { openArticleOption }));
  }
}

function* watchOpenSiblingArticle() {
  yield takeLatest([
    types.OPEN_PREVIOUS_ARTICLE,
    types.OPEN_NEXT_ARTICLE,
  ], workOpenSiblingArticle);
}

/* ------------- SAGAS SUR LES SOURCES ---------------- */

/* Saga récupération des sources de l'utilisateur */
function* workFetchSources({ params }) {
  try {
    const results = yield call(getSources, { uriParams: { ...params, fields: SOURCES_FIELDS } });
    yield put(fetchAnRSourcesSuccess(results));
  } catch (error) {
    yield put(fetchAnRSourcesError(error));
    console.error(error);
    snackActions.error(i18next.t('anr.error_fetching_sources'));
  }
}

function* watchFetchSources() {
  yield takeLatest(types.FETCH_ANR_SOURCES, workFetchSources);
}

/* Saga demande d'ajout de source */
function* workDemandNewSource({ params }) {
  try {
    const results = yield call(demandNewSource, { bodyItems: { ...params } });
    yield put(demandNewSourceSuccess(results));
    yield put(closeDemandSourceDialog());
    snackActions.success(i18next.t('anr.demand_new_source_ok'));
  } catch (error) {
    yield put(demandNewSourceError(error));
    console.error(error);
    snackActions.error(i18next.t('anr.demand_new_source_error'));
  }
}

function* watchDemandNewSource() {
  yield takeLatest(types.DEMAND_NEW_SOURCE, workDemandNewSource);
}

/* Saga récupération des sites bannis de l'utilisateur */
function* workFetchBannedSites({ params }) {
  try {
    const results = yield call(
      getBannedSites,
      { uriParams: { ...params, fields: BANNED_SITES_FIELDS } },
    );
    yield put(fetchAnRBannedSitesSuccess(results));
  } catch (error) {
    yield put(fetchAnRBannedSitesError(error));
    console.error(error);
    snackActions.error(i18next.t('anr.error_fetching_banned_sites'));
  }
}

function* watchFetchBannedSites() {
  yield takeLatest(types.FETCH_ANR_BANNED_SITES, workFetchBannedSites);
}

/* Saga débannissement d'un site */
function* workUnbanSite({ params }) {
  let message;
  try {
    const results = yield call(unbanSite, { bodyItems: { ...params } });
    message = _.get(results, 'response.message');
    if (message !== 'OK') {
      throw new Error(message);
    }
    yield put(unbanSiteSuccess(results));
    snackActions.success(i18next.t('anr.unbanned_site'));
  } catch (error) {
    yield put(unbanSiteError(error));
    console.error(error);
    snackActions.error(message || i18next.t('anr.unbanning_site_error'));
  }
}

function* watchUnbanSite() {
  yield takeLatest(types.UNBAN_SITE, workUnbanSite);
}

/* Saga collecte d'un ou plusieurs articles */
function* workCollect({ item }) {
  try {
    let articles = [];
    if (_.isEmpty(item)) {
      const checkedItems = yield select((state) => state.selection.checkedItems);
      articles = _.filter(_.values(checkedItems), (article) => _.isEmpty(article.validation));
    } else {
      articles = [item];
    }
    if (articles.length > 0) {
      const {
        list: qesCollectResultList,
      } = yield call(collect, { bodyItems: [{ articles }] });
      yield put(collectSuccess());
      yield put(validateAction(articles, qesCollectResultList));
      // Si on agissait sur la selection, on la vide
      if (_.isEmpty(item)) {
        yield put(clearSelection());
      }
      snackActions.success(i18next.t('anr.actions.collect_done'));
    }
  } catch (error) {
    yield put(collectError(error));
    console.error(error);
    snackActions.error(i18next.t('anr.actions.error_collecting'));
  }
}

function* watchCollect() {
  yield takeLatest(types.COLLECT, workCollect);
}

/* Saga utile à la validation via l'API Ask'n'Read après une collecte réussie */
function* workValidate({ articles, qesCollectResultList }) {
  // On va préparer les articles tels qu'ils doivent être envoyés à l'API
  // AnR, en fonction des résultats fournis par la collecte QES
  const anrApiArticles = _.map(
    articles,
    (article) => {
      let externalId = null;
      if (!_.isEmpty(qesCollectResultList)) {
        // Dans les résultats renvoyés par la collecte QES, on va tenter de
        // récupérer l'ID du doc dans la base de collecte, afin de l'envoyer
        // à l'API de validation AnR dans 'external_id', sachant que les
        // résultats renvoyés par QES sont sous cette forme :
        // [
        //   {anr: {id: "63bd5743b46e9c92e2afbc30"}, qes: {id: "20230110/1673353351941.xml"}},
        //   {anr: {id: "63bd5743b46e9c92e2afbc31"}, qes: {id: "20230110/1673353351942.xml"}},
        // ]
        // On tente d'abord de récupérer l'article dans les résultats QES
        const articleInQesResults = _.find(qesCollectResultList, { anr: { id: article.id } });
        if (!_.isEmpty(articleInQesResults)) {
          // Si l'article est bien trouvé (normalement toujouts le cas), on
          // tente de récupérer l'ID QES dans l'objet ayant pour clé 'qes'
          externalId = _.get(articleInQesResults, 'qes.id', null);
        }
      }
      return {
        article_id: article.id,
        folders_ids: _.map(article.folders, 'id'),
        external_id: externalId,
      };
    },
  );
  const anrResults = yield call(validate, { bodyItems: { articles: anrApiArticles } });
  yield put(validateActionSuccess(anrResults));
  const checkedItemsUpdate = {};
  for (let i = 0; i < anrResults.list.length; i++) {
    checkedItemsUpdate[anrResults.list[i]] = {
      validation: { article_id: anrResults.list[i] },
    };
  }
  yield put(updateSelection(checkedItemsUpdate));
}

function* watchValidate() {
  yield takeLatest(types.VALIDATE, workValidate);
}

/* Saga bannissement d'un site */
function* workBanSite({ params }) {
  let message;
  try {
    const results = yield call(banSite, { bodyItems: { ...params } });
    message = _.get(results, 'response.message');
    if (message !== 'OK') {
      throw new Error(message);
    }
    yield put(banSiteSuccess(results));
    yield put(closeBanSiteDialog());
    snackActions.success(i18next.t('anr.banned_site'));
  } catch (error) {
    yield put(banSiteError(error));
    console.error(error);
    snackActions.error(message || i18next.t('anr.banning_site_error'));
  }
}

function* watchBanSite() {
  yield takeLatest(types.BAN_SITE, workBanSite);
}

// /* Saga signalement mauvaise extraction d'un article */
function* workReportExtraction({ article }) {
  let message;
  const params = {
    host: article.source,
    article_id: article.id,
    profiles_ids: _.map(article.folders, 'id'),
  };
  try {
    const results = yield call(reportExtraction, { bodyItems: params });
    message = _.get(results, 'response.message');
    if (message !== 'OK') {
      throw new Error(message);
    }
    yield put(reportExtractionSuccess(results));
    snackActions.success(i18next.t('anr.reported_extraction'));
  } catch (error) {
    yield put(reportExtractionError(error));
    console.error(error);
    snackActions.error(message || i18next.t('anr.reported_extraction_error'));
  }
}

function* watchReportExtraction() {
  yield takeLatest(types.REPORT_EXTRACTION, workReportExtraction);
}

/* Saga Ajout d'articles ou de citations AnR à un panier (appelée dans carts/sagas.js) */
export function* workAnRAddToCart(params) {
  try {
    const singleItem = yield select((state) => state.selection.singleItem);

    let articles = [];
    if (_.isEmpty(singleItem)) {
      const checkedItems = yield select((state) => state.selection.checkedItems);
      articles = _.values(checkedItems);
    } else {
      articles = [singleItem];
    }
    if (articles.length > 0) {
      const {
        cartId: id,
        newCartName: intitule,
      } = params;
      const cartParams = {
        id,
        intitule,
      };
      const {
        list: qesCollectResultList,
      } = yield call(
        addToCartOrNewsletter,
        { uriParams: { ...cartParams }, bodyItems: [{ articles }] },
      );
      yield put(validateAction(articles, qesCollectResultList));
      if (_.isEmpty(singleItem)) {
        yield put(clearSelection());
      } else {
        yield put(clearSingleItem());
      }
      yield put(addToCartSuccess());
      snackActions.success(i18next.t('carts.docs_added_to_cart', { count: articles.length }));
    }
    yield put(closeDialogAddToCart());
  } catch (error) {
    yield put(addToCartError(error));
    console.error(error);
    snackActions.error(i18next.t('carts.error_adding_to_cart'));
  }
}

/* Saga Ajout d'articles ou de citations AnR à une newsletter (appelée dans carts/sagas.js) */
export function* workAnRAddToNewsletter(params) {
  try {
    const singleItem = yield select((state) => state.selection.singleItem);

    let articles = [];
    if (_.isEmpty(singleItem)) {
      const checkedItems = yield select((state) => state.selection.checkedItems);
      articles = _.values(checkedItems);
    } else {
      articles = [singleItem];
    }
    if (articles.length > 0) {
      const {
        newsletterId: id,
        newNewsletterName: intitule,
        theme,
      } = params;
      const cartParams = {
        id,
        intitule,
        theme,
      };
      const {
        list: qesCollectResultList,
      } = yield call(
        addToCartOrNewsletter,
        { uriParams: { ...cartParams }, bodyItems: [{ articles }] },
      );
      yield put(validateAction(articles, qesCollectResultList));
      if (_.isEmpty(singleItem)) {
        yield put(clearSelection());
      } else {
        yield put(clearSingleItem());
      }
      yield put(addToNewsletterSuccess());
      snackActions.success(i18next.t('newsletters.docs_added_to_newsletter', { count: articles.length }));
    }
    yield put(closeDialogAddToNewsletter());
  } catch (error) {
    yield put(addToNewsletterError(error));
    console.error(error);
    snackActions.error(i18next.t('newsletters.error_adding_to_newsletter'));
  }
}

/* Saga de construction du mail pour l'envoi d'articles ou de citations AnR
(appelée dans sendToFriend/sagas.js) */
export function* workAnRBuildSendToFriend() {
  try {
    const singleItem = yield select((state) => state.selection.singleItem);
    let articles;
    if (!_.isEmpty(singleItem)) {
      articles = [singleItem];
    } else {
      const checkedItems = yield select((state) => state.selection.checkedItems);
      articles = _.values(checkedItems);
    }
    const sendToFriend = yield call(buildSendToFriend, {
      bodyItems: {
        articles,
      },
    });
    yield put(buildSendToFriendSuccess(sendToFriend));
  } catch (error) {
    yield put(buildSendToFriendError(error));
    console.error(error);
    snackActions.error(i18next.t('send_to_friend.error_building_dialog'));
  }
}

/* Saga d'envoi d'articles ou de citations AnR par mail (appelée dans sendToFriend/sagas.js) */
export function* workAnRSendToFriend(params) {
  try {
    const singleItem = yield select((state) => state.selection.singleItem);

    let articles;
    if (!_.isEmpty(singleItem)) {
      articles = [singleItem];
    } else {
      const checkedItems = yield select((state) => state.selection.checkedItems);
      articles = _.values(checkedItems);
    }
    const {
      subject: sujet,
      to: a,
      cc,
      bcc: cci,
      comment,
    } = params;
    yield call(submitSendToFriend, {
      bodyItems: {
        sujet,
        a,
        cc,
        cci,
        comment,
        articles,
      },
    });
    // On prépare les valeurs qui vont être utilisées par la saga
    // d'envoi de mail par AnR (envoi "simulé" car le vrai est fait
    // côté QES), mais ça permet d'avoir la trace de l'envoi dans
    // les résultats AnR
    const articlesIds = _.map(articles, 'id');
    // Petite explication pour la construction de emails :
    // les éléments reçus par le formulaire d'envoi sont sous la forme
    // "truc@mail.com;chose@mail.com" OU "truc@mail.com;" OU "", donc,
    // on split sur ';' chaque champ, on _.concat tous les résultats
    // des split, et on _.filter le tableau pour virer les valeurs vides
    const emails = _.filter(
      _.concat(
        a.split(';'),
        cc.split(';'),
        cci.split(';'),
      ),
    );
    yield put(sendAction(articlesIds, emails));
    if (_.isEmpty(singleItem)) {
      yield put(clearSelection());
    } else {
      yield put(clearSingleItem());
    }
    snackActions.success(i18next.t('send_to_friend.docs_sent', { count: articles.length }));
    yield put(closeDialogSendToFriend());
    yield put(submitSendToFriendSuccess());
  } catch (error) {
    yield put(submitSendToFriendError(error));
    console.error(error);
    snackActions.error(i18next.t('send_to_friend.error_sending'));
  }
}

/* Saga utile à l'enregistrement de l'envoi de mail via l'API Ask'n'Read suite à un envoi
de mail via le sendToFriend QES */
function* workSend({ articlesIds, emails }) {
  yield call(send, { bodyItems: { articles_ids: articlesIds, emails } });
  yield put(sendActionSuccess(articlesIds, emails));
}

function* watchSend() {
  yield takeLatest(types.SEND, workSend);
}

/* Saga suppression d'un ou plusieurs articles (ou citations) */
function* workDeleteArticles({ articles }) {
  let message;
  try {
    const articlesParam = _.map(
      articles,
      (article) => ({
        article_id: article.id,
        folders_ids: _.map(article.folders, 'id'),
      }),
    );
    const results = yield call(deleteArticles, { bodyItems: { articles: articlesParam } });
    message = _.get(results, 'response.message');
    if (message !== 'OK') {
      throw new Error(message);
    }
    yield put(deleteArticlesSuccess(results));
    snackActions.success(i18next.t('anr.delete_articles_ok', { count: articles.length }));
    yield put(clearSelection());
    yield put(fetchActiveItemResults());
  } catch (error) {
    yield put(deleteArticlesError(error));
    console.error(error);
    snackActions.error(message || i18next.t('anr.delete_articles_error'));
  }
}

function* watchDeleteArticles() {
  yield takeLatest(types.DELETE_ARTICLES, workDeleteArticles);
}

/* Saga récupération des duplicates des artciles */
function* workFecthDuplicates({ article }) {
  const params = {
    article_id: article.id,
    profiles_ids: _.map(article.folders, 'id'),
  };
  try {
    yield put(setLoadingDuplicatesArticle(article.id, true));
    const results = yield call(getDuplicates, { uriParams: params, fields: NEWS_FIELDS });
    if (results.articles) {
      yield put(fetchAnrDuplicatesSuccess(article, results.articles));
    }
  } catch (error) {
    yield put(fetchAnrDuplicatesError(error));
    console.error(error);
    snackActions.error(i18next.t('results.duplicates.error_fetching_duplicates'));
  } finally {
    yield put(setLoadingDuplicatesArticle(article.id, false));
  }
}

function* watchFetchDuplicates() {
  yield takeLatest(types.FETCH_ANR_DUPLICATES, workFecthDuplicates);
}

export default {
  watchChangeActiveItem,
  watchCollect,
  watchValidate,
  watchBanSite,
  watchReportExtraction,
  watchDeleteArticles,
  watchDeleteFolder,
  watchDemandNewSource,
  watchFetchActiveItemResults,
  watchFetchBannedSites,
  watchFetchSources,
  watchFetchSuccess,
  watchFolder,
  watchFolders,
  watchOpenSiblingArticle,
  watchResults,
  watchSaveFavoriteStatus,
  watchSaveFolder,
  watchSend,
  watchSetEditFolder,
  watchUser,
  watchUnbanSite,
  watchFetchDuplicates,
};
