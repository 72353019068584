import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    chart: {
      polar: true,
    },
    credits: { enabled: false },
    title: { text: 'Titre Polar' },
    accessibility: {
      point: {
        valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.',
      },
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
    },
    xAxis: {
      lineWidth: 0,
      categories: [],
      tickmarkPlacement: 'on',
    },
    yAxis: {
      gridLineColor: 'rgba(164, 177, 205, 0.2)',
      alternateGridColor: 'rgba(164, 177, 205, 0.05)',
      gridLineInterpolation: 'polygon',
      allowDecimals: false,
      lineWidth: 0,
      endOnTick: false,
      labels: {
        enabled: false,
      },
    },
    series: [{
      showInLegend: false,
      type: 'line',
      name: 'Line',
      data: [],
    }],
  },
};

const Polar = ({ highchartsOptions }) => {
  const finalConfig = merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact {...finalConfig} />
  );
};

Polar.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

Polar.defaultProps = {
};

export default Polar;
