const primary = '#263238';
const secondary = '#005063';

const themeConfig = {
  THEME: {
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
    },
  },
  HIGHCHARTS: {
    entitiesColors: [
      '#006064',
      '#E91E63',
      '#31A6F3',
      '#A758E9',
      '#049698',
    ],
  },
};

export default themeConfig;
