import {
  put,
  call,
  takeLatest,
  select,
} from 'redux-saga/effects';
import i18next from 'i18next';
import _ from 'lodash';
import {
  types,
  fetchCarts,
  fetchCartsSuccess,
  fetchCartsError,
  deleteCartsSuccess,
  deleteCartsError,
  fetchCartSuccess,
  fetchCartError,
  deleteCartDocumentsSuccess,
  deleteCartDocumentsError,
  fetchCart,
  saveCartError,
  saveCartSuccess,
  addToCartSuccess,
  addToCartError,
} from 'generic/core/carts/actions';
import {
  fetchCarts as fetchCartsApi,
  fetchCart as fetchCartApi,
  addToCart as addToCartApi,
  deleteCarts,
  deleteCartDocuments,
  updateCart,
} from 'generic/api/cart';
import { snackActions } from 'generic/utils/snackbar';
import { clearSelection, clearSingleItem } from 'generic/core/selection/actions';
import { closeDialogAddToCart } from 'generic/core/actions/actions';
import { workAnRAddToCart } from 'generic/core/anr/sagas';

function* workFetchCarts() {
  try {
    const carts = yield call(fetchCartsApi, { uriParams: { themePanier: '1000' } });
    yield put(fetchCartsSuccess(carts));
  } catch (error) {
    yield put(fetchCartsError(error));
    console.error(error);
    snackActions.error(i18next.t('carts.error_fetching_carts'));
  }
}

function* watchFetchCarts() {
  yield takeLatest(types.FETCH_CARTS, workFetchCarts);
}

function* workDeleteCarts({ ids }) {
  try {
    yield call(deleteCarts, ids);
    yield put(deleteCartsSuccess());
    snackActions.success(i18next.t('carts.carts_deleted', { count: ids.length }));
    yield put(fetchCarts());
  } catch (error) {
    yield put(deleteCartsError(error));
    console.error(error);
    snackActions.error(i18next.t('carts.error_deleting_carts', { count: ids.length }));
  }
}

function* watchDeleteCarts() {
  yield takeLatest(types.DELETE_CARTS, workDeleteCarts);
}

function* workFetchCart(action) {
  try {
    const cart = yield call(fetchCartApi, action.id);
    yield put(fetchCartSuccess(cart));
  } catch (error) {
    yield put(fetchCartError(error));
    console.error(error);
    snackActions.error(i18next.t('carts.error_fetching_cart'));
  }
}

function* watchFetchCart() {
  yield takeLatest(types.FETCH_CART, workFetchCart);
}

function* workDeleteCartDocuments({ id, ids }) {
  try {
    yield call(deleteCartDocuments, ids);
    yield put(deleteCartDocumentsSuccess());
    snackActions.success(i18next.t('carts.cart_documents_deleted', { count: ids.length }));
    yield put(fetchCart(id));
  } catch (error) {
    yield put(deleteCartDocumentsError(error));
    console.error(error);
    snackActions.error(i18next.t('carts.error_deleting_cart_documents', { count: ids.length }));
  } finally {
    yield put(clearSelection());
  }
}

function* watchDeleteCartDocuments() {
  yield takeLatest(types.DELETE_CART_DOCUMENTS, workDeleteCartDocuments);
}

function* workSaveCart({ params }) {
  try {
    const results = yield call(updateCart, { bodyItems: { ...params } });
    yield put(saveCartSuccess(results));
    snackActions.success(i18next.t('carts.cart_updated'));
    yield put(fetchCart(params.panier));
  } catch (error) {
    yield put(saveCartError(error));
    console.error(error);
    snackActions.error(i18next.t('carts.error_updating_cart'));
  }
}

function* watchSaveCart() {
  yield takeLatest(types.SAVE_CART, workSaveCart);
}

/* Saga Ajout de documents QES à un panier */
function* workQesAddToCart(params) {
  try {
    const singleItem = yield select((state) => state.selection.singleItem);

    let documentitemIds;
    if (singleItem) {
      documentitemIds = [singleItem.documentitem];
    } else {
      const checkedItems = yield select((state) => state.selection.checkedItems);
      documentitemIds = _.map(
        checkedItems,
        (item) => item.documentitem,
      );
    }
    const {
      cartId: panier,
      newCartName: intituleNewPanier,
    } = params;
    const cartParams = {
      panier,
      intituleNewPanier,
    };
    yield call(addToCartApi, { bodyItems: { ...cartParams, documentitem: documentitemIds } });
    yield put(addToCartSuccess());
    if (_.isEmpty(singleItem)) {
      yield put(clearSelection());
    } else {
      yield put(clearSingleItem());
    }
    snackActions.success(i18next.t('carts.docs_added_to_cart', { count: documentitemIds.length }));
    yield put(closeDialogAddToCart());
  } catch (error) {
    yield put(addToCartError(error));
    console.error(error);
    snackActions.error(i18next.t('carts.error_adding_to_cart'));
  }
}

function* workAddToCart({ params, scope }) {
  if (scope === 'anr') {
    yield call(workAnRAddToCart, params);
  } else {
    yield call(workQesAddToCart, params);
  }
}

function* watchAddToCart() {
  yield takeLatest(types.ADD_TO_CART, workAddToCart);
}

export default {
  watchFetchCarts,
  watchDeleteCarts,
  watchFetchCart,
  watchDeleteCartDocuments,
  watchSaveCart,
  watchAddToCart,
};
