import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  fetchActiveItemResults,
  setFiltersParams,
  setOpenFilterNav,
  unsetFiltersParams,
} from 'generic/core/anr/actions';
import FormAnRFilters from 'generic/components/forms/FormAnRFilters';

const FiltersContainer = () => {
  const activeItem = useSelector((state) => state.anr.activeItem);
  const filtersParams = useSelector((state) => state.anr.filtersParams);
  const stateOpenFilterNav = useSelector((state) => state.anr.stateOpenFilterNav);

  const dispatch = useDispatch();

  const handleOpenFilterNav = () => {
    dispatch(setOpenFilterNav(!stateOpenFilterNav));
  };

  const handleSubmit = (values) => {
    dispatch(setFiltersParams(values));
    dispatch(fetchActiveItemResults({ offset: 0 }, { forceClearSelection: true }));
  };

  const handleResetForm = () => {
    dispatch(unsetFiltersParams());
    dispatch(fetchActiveItemResults({ offset: 0 }, { forceClearSelection: true }));
  };

  if (_.isEmpty(activeItem)) {
    return null;
  }
  const showNewsInputs = _.includes(['glimpse', 'news', 'archives'], activeItem.view);

  const numberParams = _.filter(
    filtersParams,
    (val) => !_.isEmpty(_.toString(val)),
  ).length;

  return (
    <FormAnRFilters
      initialValues={{ ...filtersParams }}
      numberParams={numberParams}
      stateOpenFilterNav={stateOpenFilterNav}
      handleOpenFilterNav={handleOpenFilterNav}
      handleResetForm={handleResetForm}
      handleSubmit={handleSubmit}
      showNewsInputs={showNewsInputs}
    />
  );
};

export default FiltersContainer;
