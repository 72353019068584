import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  Grid,
} from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import DashboardWidget from 'generic/components/dashboard-items/DashboardWidget';
import Bar from 'generic/components/dashboard-items/Bar';
import Heatmap from 'generic/components/dashboard-items/Heatmap';
import Map from 'generic/components/dashboard-items/Map';
import Pie from 'generic/components/dashboard-items/Pie';
import Sankey from 'generic/components/dashboard-items/Sankey';
import Wordcloud from 'generic/components/dashboard-items/Wordcloud';
import NetworkGraph from 'generic/components/dashboard-items/NetworkGraph';
import ColumnHisto from 'generic/components/dashboard-items/ColumnHisto';
import WidgetContainer from 'generic/containers/WidgetContainer';
import { refineAddFacetsValues } from 'generic/core/search/actions';
import { fetchWidgets } from 'generic/core/dashboard/actions';
import { format } from 'generic/utils/dateUtils';
import { getRandomColor } from 'generic/utils/colorUtils';
import QES_CONSTANTS from 'generic/core/qes/constants';
import THEME_CONFIG from 'generic/core/theme';

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const {
  DATE_INTERVAL_COMPARATOR,
} = QES_CONSTANTS;
const { entitiesColors, relationsEntitiesColors } = THEME_CONFIG.HIGHCHARTS;

const monoColors = (indexColor) => {
  const colors = [];
  const base = Highcharts.getOptions().colors[indexColor];
  let i;

  for (i = 0; i < 20; i += 1) {
    colors.push(Highcharts.color(base).brighten(i / 20).get());
  }
  return colors;
};

const WORDCLOUD_CONCEPTS = {
  'QES_Organonoff.verbatim': { field: 2, label: 'Organisations non officielles' },
  'QES_Organization.verbatim': { field: 2, label: 'Organisations' },
  'QES_Product.verbatim': { field: 2, label: 'Moyens' },
  'QES_Event.verbatim': { field: 2, label: 'Événements' },
  'QES_ConceptSecurity.verbatim': { field: 2, label: 'Concepts Sécurité' },
  'QES_Concept.verbatim': { field: 2, label: 'Concepts' },
  'QES_Coldconcept.verbatim': { field: 2, label: 'Concepts froids' },
};

const cooccurrenceFields = [
  { name: 'Org. non off.', value: 'QES_Organonoff.verbatim', idField: 63 },
  { name: 'Organisations', value: 'QES_Organization.verbatim', idField: 920 },
  { name: 'Moyens', value: 'QES_Product.verbatim', idField: 24 },
  { name: 'Personnes', value: 'QES_Person.verbatim', idField: 901 },
  { name: 'Pays', value: 'QES_Country.verbatim' },
].map((field, index) => ({
  ...field,
  color: entitiesColors[index],
}));

const relationsNodesFields = [
  { value: 'Organonoff', name: 'Organonoff', idField: 63 },
  { value: 'Organization', name: 'Organization', idField: 920 },
  { value: 'Product', name: 'Product', idField: 24 },
  { value: 'Person', name: 'Person', idField: 901 },
  { value: 'Concept', name: 'Concept', idField: 30800115 },
  { value: 'Coldconcept', name: 'Coldconcept', idField: 922 },
  { value: 'Location', name: 'Location', idField: 902 },
  { value: 'Company', name: 'Company', idField: 1032000431 },
  { value: 'Event', name: 'Event', idField: 1032000315 },
  { value: 'Uppers', name: 'Uppers', idField: 1032000609 },
  { value: 'Media', name: 'Media', idField: 1032000610 },
].map((field, index) => ({
  ...field,
  color: relationsEntitiesColors[index],
}));

const relationsLinksFields = [
  { name: 'Between', value: 'Between' },
  { name: 'Attack', value: 'Any-attack-Any' },
  { name: 'Claim', value: 'Any-claim-Any' },
  { name: 'Deploy', value: 'Any-deploy-Any' },
  { name: 'Kill', value: 'Any-kill-Any' },
  { name: 'Support', value: 'Any-support-Any' },
  { name: 'Spy', value: 'Any-spy-Any' },
  { name: 'Risk', value: 'Risk' },
].map((field, index) => ({
  ...field,
  color: getRandomColor(index),
}));

const DashboardChartsContainer = () => {
  const strategy = useSelector((state) => state.search.results.strategie);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // On utilise ici des refs pour pouvoir les mettre à jour
  // depuis les widgets "enfants" sans re-render tout le
  // dashboard
  const cooccurrencesNodesFilterValue = useRef([cooccurrenceFields[0].value]);
  const relationsLinksFilterValue = useRef([relationsLinksFields[0].value]);
  const relationsNodesFilterValue = useRef(relationsNodesFields.map((relationNode) => relationNode.value));

  const buildNodesTypesAdditionalQuery = () => {
    const nodesTypesSources = [];
    const nodesTypesDestinations = [];
    _.forEach(
      relationsNodesFilterValue.current,
      (nodeTypeValue) => {
        nodesTypesSources.push(
          `QES_Relation_Source_Type:${nodeTypeValue}`,
        );
        nodesTypesDestinations.push(
          `QES_Relation_Destination_Type:${nodeTypeValue}`,
        );
      },
    );
    let additionalQuery = '';
    if (nodesTypesSources) {
      additionalQuery = `(${nodesTypesSources.join(' OR ')}) AND (${nodesTypesDestinations.join(' OR ')})`;
    }
    return additionalQuery;
  };

  const handleRefreshRelationChart = (nodesTypesValues, linksTypesValues) => {
    relationsNodesFilterValue.current = nodesTypesValues;
    relationsLinksFilterValue.current = linksTypesValues;
    const joinedTypesValue = linksTypesValues.join(',');

    const params = {
      facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
      relations: joinedTypesValue,
      aggregates: [joinedTypesValue],
      facetmax: 200,
      facetmax2: 5,
      mindoccount: 1,
      seriesNames: [joinedTypesValue],
      additionalQuery: buildNodesTypesAdditionalQuery(),
      type: 'networkgraph',
    };
    const keepWidgets = true;
    dispatch(fetchWidgets(strategy, {
      relations: params,
    }, keepWidgets));
  };
  const handleRefreshCooccurrencesChart = (filterValues) => {
    cooccurrencesNodesFilterValue.current = filterValues;
    const keepWidgets = true;
    const joinedValue = filterValues.join(',');
    dispatch(fetchWidgets(strategy, {
      cooccurrences: {
        facets: joinedValue,
        aggregates: [joinedValue],
        facetmax: 200,
        facetmax2: 5,
        mindoccount: 1,
        seriesNames: [joinedValue],
        type: 'networkgraph',
      },
    }, keepWidgets));
  };

  useEffect(() => {
    if (!_.isEmpty(strategy)) {
      const cooccurrencesNodesFilterValueJoined = cooccurrencesNodesFilterValue.current.join(',');
      const relationsLinksFilterValueJoined = relationsLinksFilterValue.current.join(',');
      dispatch(fetchWidgets(strategy, {
        publicationDates: {
          facets: 'PUB_DATE#auto_date_histogram|1000 _key:asc',
          aggregates: ['agg_PUB_DATE'],
          seriesNames: ['Date de publication'],
          type: 'columnhisto',
        },
        conceptOnCountries: {
          facets: 'QES_CountryTopicCode.verbatim, QES_Concept.verbatim',
          aggregates: ['agg_QES_CountryTopicCode.verbatim'],
          pivots: ['agg_QES_Concept.verbatim'],
          seriesNames: ['Concepts cités par pays'],
          type: 'heatmap',
          mindoccount: 1,
          facetmax: 5,
          facetmax2: 5,
        },
        countryTopic: {
          facets: 'QES_CountryTopicCode.verbatim',
          aggregates: ['agg_QES_CountryTopicCode.verbatim'],
          type: 'map',
          mindoccount: 1,
          facetmax: 10000,
          facetmax2: 10000,
        },
        eventsPersons: {
          facets: 'QES_Person.verbatim, QES_Event.verbatim',
          aggregates: ['agg_QES_Person.verbatim'],
          pivots: ['agg_QES_Event.verbatim'],
          facetmax: 5,
          facetmax2: 10,
          mindoccount: 3,
          type: 'sankey',
        },
        persons: {
          facets: 'QES_Person.verbatim',
          aggregates: ['agg_QES_Person.verbatim'],
          type: 'pie',
        },
        companies: {
          facets: 'QES_Company.verbatim',
          aggregates: ['agg_QES_Company.verbatim'],
          type: 'pie',
        },
        locations: {
          facets: 'QES_Location.verbatim',
          aggregates: ['agg_QES_Location.verbatim'],
          seriesNames: ['Lieux mentionnés'],
          type: 'bar',
        },
        concepts: {
          facets: _.keys(WORDCLOUD_CONCEPTS).join(';'),
          aggregates: _.keys(WORDCLOUD_CONCEPTS).map((c) => `agg_${c}`),
          seriesNames: _.map(WORDCLOUD_CONCEPTS, 'label'),
          facetmax: 100,
          type: 'wordcloud',
          splitPerSerie: true,
        },
        cooccurrences: {
          facets: cooccurrencesNodesFilterValueJoined,
          aggregates: [cooccurrencesNodesFilterValueJoined],
          facetmax: 200,
          facetmax2: 5,
          mindoccount: 1,
          seriesNames: [cooccurrencesNodesFilterValueJoined],
          type: 'networkgraph',
        },
        relations: {
          facets: 'QES_Relation_Source_Text.verbatim,QES_Relation_Destination_Text.verbatim',
          relations: relationsLinksFilterValueJoined,
          aggregates: [relationsLinksFilterValueJoined],
          facetmax: 200,
          facetmax2: 5,
          mindoccount: 1,
          seriesNames: [relationsLinksFilterValueJoined],
          additionalQuery: buildNodesTypesAdditionalQuery(),
          type: 'networkgraph',
        },
      }));
    }

    // On ne met pas cooccurrencesNodesFilterValue ni relationsLinksFilterValue dans les dépendances,
    // pour éviter de refresh tout le dashboard quand on change juste le type
    // des éléments affichés (refresh gérés dans handleChangeCooccurrenceValue
    // et handleChangeRelationValue).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, strategy]);

  return (
    <Grid
      container
      spacing={1}
      className="desktopOnlyOverflow"
      p={1}
    >
      <Grid item xs={12} md={12}>
        <WidgetContainer widgetId="publicationDates" height={200}>
          {({
            series: publicationDates,
            axisX,
            height,
          }) => (
            <DashboardWidget
              height={height}
              component={ColumnHisto}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  title: { text: '', align: 'left' },
                  series: publicationDates,
                  xAxis: {
                    categories: axisX.categories,
                    labels: {
                      formatter: (e) => format(e.value),
                    },
                    events: {
                      afterSetExtremes: (e) => {
                        if (e.trigger === 'zoom') {
                          const min = axisX.categories[0];
                          const max = axisX.categories[
                            axisX.categories.length - 1
                          ];
                          let valmin = axisX.categories[e.min];
                          let valmax = axisX.categories[e.max];
                          if (valmin < min) {
                            valmin = min;
                          }
                          if (valmax > max) {
                            valmax = max;
                          }
                          dispatch(refineAddFacetsValues([{
                            champ: 10000029,
                            formatFacet: 'date',
                            comparator: DATE_INTERVAL_COMPARATOR.between,
                            begin: format(new Date(valmin), 'yyyyMMdd'),
                            end: format(new Date(valmax), 'yyyyMMdd'),
                          }]));
                        }
                      },
                    },
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={5}>
        <WidgetContainer widgetId="conceptOnCountries">
          {({
            series: conceptOnCountriesSerie,
            axisX,
            axisY,
            height,
          }) => (
            <DashboardWidget
              height={height}
              component={Heatmap}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  title: { text: 'Concepts par pays', align: 'left' },
                  series: conceptOnCountriesSerie,
                  xAxis: {
                    categories: axisX
                      .categories
                      .map((countryCode) => t(`dashboard.countries.${countryCode}`)),
                  },
                  yAxis: {
                    categories: axisY.categories,
                  },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} md={7}>
        <WidgetContainer widgetId="countryTopic">
          {({ series: countryTopicSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Map}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    series: {
                      tooltip: {
                        pointFormatter: function getTranslatedCountryName() {
                          // eslint-disable-next-line react/no-this-in-sfc
                          return `${t(`dashboard.countries.${this['iso-a2']}`)}: <b>${this.value}</b>`;
                        },
                      },
                      point: {
                        events: {
                          click: (e) => {
                            dispatch(refineAddFacetsValues([
                              { champ: 42, strategie: e.point.options.name, dontQuoteStrategy: true },
                            ]));
                          },
                        },
                      },
                    },
                  },
                  series: countryTopicSerie,
                  title: { text: 'Les pays cités', align: 'left' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <WidgetContainer widgetId="eventsPersons">
          {({ series: eventsPersonsSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Sankey}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    series: {
                      point: {
                        events: {
                          click: (e) => {
                            const nodesIdsMap = {
                              0: 901,
                              1: 1032000315,
                            };
                            let fields;
                            if (e.point.from) {
                              // On a cliqué sur un lien, donc on prépare le double raffinage
                              fields = [
                                { champ: nodesIdsMap[e.point.fromNode.column], strategie: e.point.fromNode.name },
                                { champ: nodesIdsMap[e.point.toNode.column], strategie: e.point.toNode.name },
                              ];
                            } else {
                              // On a cliqué sur un noeud, on fait un simple raffinage
                              fields = [
                                { champ: nodesIdsMap[e.point.column], strategie: e.point.name },
                              ];
                            }
                            dispatch(refineAddFacetsValues(fields));
                          },
                        },
                      },
                    },
                  },
                  series: eventsPersonsSerie,
                  title: { text: 'Personnes et évènements', align: 'left' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <WidgetContainer widgetId="persons">
          {({ series: personsSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    pie: {
                      data: personsSerie[0].data,
                      point: {
                        events: {
                          click: (folder) => {
                            dispatch(refineAddFacetsValues([{ champ: 901, strategie: folder.point.options.name }]));
                          },
                        },
                      },
                    },
                  },
                  series: [
                    { name: 'Nombre d\'articles pour cette personne' },
                  ],
                  title: { text: 'Personnes mentionnées', align: 'left' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <WidgetContainer widgetId="companies">
          {({ series: companiesSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Pie}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    pie: {
                      data: companiesSerie[0].data,
                      point: {
                        events: {
                          click: (folder) => {
                            dispatch(refineAddFacetsValues([
                              {
                                champ: 1032000431,
                                strategie: folder.point.options.name,
                              },
                            ]));
                          },
                        },
                      },
                    },
                  },
                  series: [
                    { name: 'Nombre d\'articles pour cette société' },
                  ],
                  title: { text: 'Sociétés mentionnées', align: 'left' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <WidgetContainer widgetId="locations">
          {({ series: locationsSerie, height }) => (
            <DashboardWidget
              height={height}
              component={Bar}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  tooltip: {
                    enabled: false,
                  },
                  plotOptions: {
                    series: {
                      point: {
                        events: {
                          click: (e) => {
                            dispatch(refineAddFacetsValues([
                              { champ: 902, strategie: e.point.name },
                            ]));
                          },
                        },
                      },
                    },
                  },
                  title: { text: 'Lieux mentionnés dans les documents', align: 'left' },
                  series: locationsSerie,
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      { _.entries(WORDCLOUD_CONCEPTS).map(([fieldName, fieldDefinition]) => (
        <Grid
          item
          xs={12}
          sm={6}
          // eslint-disable-next-line no-nested-ternary
          md={(['Concept', 'Coldconcept'].includes(fieldName)) ? 12 : (fieldName === 'ConceptSecurity') ? 8 : 4}
          key={`concepts${fieldName}`}
        >
          <WidgetContainer widgetId={`concepts_${fieldDefinition.label}`}>
            {({ series: conceptsSerie, height }) => (
              <DashboardWidget
                height={height}
                component={Wordcloud}
                highchartsOptions={{
                  options: {
                    chart: {
                      height,
                    },
                    colors: monoColors(0),
                    plotOptions: {
                      wordcloud: {
                        spiral: ['Concept', 'Coldconcept', 'ConceptSecurity'].includes(fieldName)
                          ? 'rectangular' : 'archimedeanSpiral',
                        point: {
                          events: {
                            click: (folder) => {
                              dispatch(refineAddFacetsValues([
                                {
                                  champ: fieldDefinition.field,
                                  strategie: folder.point.options.name,
                                },
                              ]));
                            },
                          },
                        },
                      },
                    },
                    series: [{
                      data: conceptsSerie.data,
                      name: conceptsSerie.name,
                    }],
                    title: { text: conceptsSerie.name, align: 'left' },
                  },
                }}
              />
            )}
          </WidgetContainer>
        </Grid>
      ))}

      <Grid item xs={12} md={12}>
        <WidgetContainer widgetId="cooccurrences" height={700}>
          {({ series: cooccurrenceCompany, height }) => (
            <DashboardWidget
              height={height}
              component={NetworkGraph}
              componentProps={{
                nodesFilterValue: cooccurrencesNodesFilterValue.current,
                nodesFilterItems: cooccurrenceFields,
                handleRefreshChart: handleRefreshCooccurrencesChart,
                handleLinkClick: (fromNode, toNode) => {
                  const {
                    idField: fromIdField,
                  } = _.find(cooccurrenceFields, { value: fromNode.options.group });
                  const {
                    idField: toIdField,
                  } = _.find(cooccurrenceFields, { value: toNode.options.group });
                  const facetsValues = [{
                    champ: fromIdField,
                    strategie: fromNode.id,
                  }];
                  if (fromIdField === toIdField) {
                    // Si jamais l'ID de champ est le même pour les deux noeuds,
                    // on colle les deux valeurs dans un même tableau, qui sera
                    // découpé convenablement au moment du fetch des résultats
                    facetsValues[0].strategie = [fromNode.id, toNode.id];
                  } else {
                    // A l'inverse, si les ID de champs sont différents, on
                    // on ajoute une seconde "facetValue" avec les éléments
                    // du second noeud
                    facetsValues.push({
                      champ: toIdField,
                      strategie: toNode.id,
                    });
                  }
                  dispatch(refineAddFacetsValues(facetsValues));
                },
              }}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    series: {
                      point: {
                        events: {
                          click: (e) => {
                            const { idField } = _.find(cooccurrenceFields, { value: e.point.options.group });
                            if (idField) {
                              dispatch(refineAddFacetsValues([
                                {
                                  champ: idField,
                                  strategie: e.point.name,
                                },
                              ]));
                            }
                          },
                        },
                      },
                    },
                  },
                  series: [{
                    nodes: _.get(cooccurrenceCompany, '[0].nodes', []).map(
                      (node) => ({
                        ...node,
                        color: _.find(cooccurrenceFields, { value: node.group })?.color,
                      }),
                    ),
                    data: _.get(cooccurrenceCompany, '[0].data', []),
                  }],
                  title: { text: 'Graphe de cooccurrences', align: 'left' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item xs={12} md={12}>
        <WidgetContainer widgetId="relations" height={700}>
          {({ series: relations, height }) => (
            <DashboardWidget
              height={height}
              component={NetworkGraph}
              componentProps={{
                linksFilterValue: relationsLinksFilterValue.current,
                linksFilterItems: relationsLinksFields,
                nodesFilterValue: relationsNodesFilterValue.current,
                nodesFilterItems: relationsNodesFields,
                handleRefreshChart: handleRefreshRelationChart,
                handleLinkClick: (fromNode, toNode) => {
                  const {
                    idField: fromIdField,
                  } = _.find(relationsNodesFields, { value: fromNode.options.group });
                  const {
                    idField: toIdField,
                  } = _.find(relationsNodesFields, { value: toNode.options.group });
                  const facetsValues = [{
                    champ: fromIdField,
                    strategie: fromNode.id,
                  }];
                  if (fromIdField === toIdField) {
                    // Si jamais l'ID de champ est le même pour les deux noeuds,
                    // on colle les deux valeurs dans un même tableau, qui sera
                    // découpé convenablement au moment du fetch des résultats
                    facetsValues[0].strategie = [fromNode.id, toNode.id];
                  } else {
                    // A l'inverse, si les ID de champs sont différents, on
                    // on ajoute une seconde "facetValue" avec les éléments
                    // du second noeud
                    facetsValues.push({
                      champ: toIdField,
                      strategie: toNode.id,
                    });
                  }
                  dispatch(refineAddFacetsValues(facetsValues));
                },
              }}
              highchartsOptions={{
                options: {
                  chart: {
                    height,
                  },
                  plotOptions: {
                    series: {
                      point: {
                        events: {
                          click: (e) => {
                            const { idField } = _.find(relationsNodesFields, { value: e.point.options.group });
                            dispatch(refineAddFacetsValues([
                              {
                                champ: idField,
                                strategie: e.point.name,
                              },
                            ]));
                          },
                        },
                      },
                    },
                  },
                  series: [{
                    nodes: _.get(relations, '[0].nodes', []).map(
                      (node) => ({
                        ...node,
                        color: _.find(relationsNodesFields, { value: node.group })?.color,
                      }),
                    ),
                    data: _.get(relations, '[0].data', []).map(
                      (link) => ({
                        ...link,
                        color: link.color || _.find(relationsLinksFields, { value: link.type })?.color || '#c5c5c5',
                      }),
                    ),
                  }],
                  title: { text: 'Graphe de relations', align: 'left' },
                },
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

    </Grid>
  );
};

export default DashboardChartsContainer;
