export const types = {
  CLEANUP_RESULTS: '@SEARCH/CLEANUP_RESULTS',
  FETCH_RESULTS: '@SEARCH/FETCH_RESULTS',
  FETCH_RESULTS_SUCCESS: '@SEARCH/FETCH_RESULTS_SUCCESS',
  FETCH_RESULTS_ERROR: '@SEARCH/FETCH_RESULTS_ERROR',
  REFRESH_RESULTS: '@SEARCH/REFRESH_RESULTS',

  CLEANUP_RESULTS_COMPLETE: '@SEARCH/CLEANUP_RESULTS_COMPLETE',
  FETCH_RESULTS_COMPLETE: '@SEARCH/FETCH_RESULTS_COMPLETE',
  FETCH_RESULTS_COMPLETE_SUCCESS: '@SEARCH/FETCH_RESULTS_COMPLETE_SUCCESS',
  FETCH_RESULTS_COMPLETE_ERROR: '@SEARCH/FETCH_RESULTS_COMPLETE_ERROR',

  REFINE_REPLACE_CRITERION: '@SEARCH/REFINE_REPLACE_CRITERION',
  REFINE_EXCLUDE_CRITERION: '@SEARCH/REFINE_EXCLUDE_CRITERION',
  REFINE_INCLUDE_CRITERION: '@SEARCH/REFINE_INCLUDE_CRITERION',
  REFINE_REMOVE_CRITERION: '@SEARCH/REFINE_REMOVE_CRITERION',

  REFINE_ADD_FACETS_VALUES: '@SEARCH/REFINE_ADD_FACETS_VALUES',

  CHANGE_SORT: '@SEARCH/CHANGE_SORT',

  UPDATE_DOCUMENT_COMMENTS: '@SEARCH/UPDATE_DOCUMENT_COMMENTS',
  UPDATE_DOCUMENT_ALERT: '@SEARCH/UPDATE_DOCUMENT_ALERT',

  SET_DOCUMENT_MORE_LIKE_THIS_LOADING: '@SEARCH/SET_DOCUMENT_MORE_LIKE_THIS_LOADING',
  UPDATE_DOCUMENT_MORE_LIKE_THIS: '@SEARCH/UPDATE_DOCUMENT_MORE_LIKE_THIS',
};

export const cleanupResults = () => ({
  type: types.CLEANUP_RESULTS,
});

export const fetchResults = (values) => ({
  type: types.FETCH_RESULTS,
  values,
});

export const fetchResultsSuccess = (results) => ({
  type: types.FETCH_RESULTS_SUCCESS,
  results,
});

export const fetchResultsError = (response) => ({
  type: types.FETCH_RESULTS_ERROR,
  response,
});

export const refreshResults = () => ({
  type: types.REFRESH_RESULTS,
});

export const fetchResultsComplete = (id, baseId) => ({
  type: types.FETCH_RESULTS_COMPLETE,
  id,
  baseId,
});

export const fetchResultsCompleteSuccess = (results) => ({
  type: types.FETCH_RESULTS_COMPLETE_SUCCESS,
  results,
});

export const fetchResultsCompleteError = (response) => ({
  type: types.FETCH_RESULTS_COMPLETE_ERROR,
  response,
});

export const cleanupResultsComplete = () => ({
  type: types.CLEANUP_RESULTS_COMPLETE,
});

export const refineReplaceCriterion = (criterion, replacementValue) => ({
  type: types.REFINE_REPLACE_CRITERION,
  values: {
    criterion,
    replacementValue,
  },
});

export const refineExcludeCriterion = (criterion) => ({
  type: types.REFINE_EXCLUDE_CRITERION,
  values: {
    criterion,
  },
});

export const refineIncludeCriterion = (criterion) => ({
  type: types.REFINE_INCLUDE_CRITERION,
  values: {
    criterion,
  },
});

export const refineRemoveCriterion = (criterion) => ({
  type: types.REFINE_REMOVE_CRITERION,
  values: {
    criterion,
  },
});

export const refineAddFacetsValues = (facetValues) => ({
  type: types.REFINE_ADD_FACETS_VALUES,
  facetValues,
});

export const changeSort = (sortValue) => ({
  type: types.CHANGE_SORT,
  sortValue,
});

export const updateDocumentComments = (idext, comments, userId) => ({
  type: types.UPDATE_DOCUMENT_COMMENTS,
  idext,
  comments,
  userId,
});

export const updateDocumentAlert = (idext, alert) => ({
  type: types.UPDATE_DOCUMENT_ALERT,
  idext,
  alert,
});

export const setDocumentMoreLikeThisLoading = (idext, loadingMoreLikeThis, formatComplete) => ({
  type: types.SET_DOCUMENT_MORE_LIKE_THIS_LOADING,
  idext,
  loadingMoreLikeThis,
  formatComplete,
});

export const updateDocumentMoreLikeThis = (idext, morelikethis, formatComplete) => ({
  type: types.UPDATE_DOCUMENT_MORE_LIKE_THIS,
  idext,
  morelikethis,
  formatComplete,
});
