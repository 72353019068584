import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { refineAddFacetsValues } from 'generic/core/search/actions';
import { fetchWidgets } from 'generic/core/dashboard/actions';
import DateHistogram from 'generic/components/dashboard-items/DateHistogram';
import QES_CONSTANTS from 'generic/core/qes/constants';

const DateHistogramContainer = () => {
  const publicationDatesSerie = useSelector((state) => _.get(state, 'dashboard.widgets.publicationDates.series', null));
  const strategy = useSelector((state) => state.search.results.strategie);
  const bases = useSelector((state) => state.config.bases);
  const activeBaseId = useSelector((state) => state.config.activeBase.base);
  const [isHistogramVisible, setIsHistogramVisible] = useState(true);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  const documentBase = _.find(bases, { base: activeBaseId });
  const { champDate, idChampDate } = documentBase;

  const {
    DATE_INTERVAL_COMPARATOR,
  } = QES_CONSTANTS;

  useEffect(() => {
    if (!_.isEmpty(strategy) && champDate) {
      dispatch(fetchWidgets(strategy, {
        publicationDates: {
          facets: `${champDate}#auto_date_histogram|300 _key:asc`,
          aggregates: [`agg_${champDate}`],
          seriesNames: [t('dashboard.widget.publication_date')],
          type: 'spline',
        },
      }));
    }
  }, [champDate, dispatch, strategy, t]);

  // Si pas de champ date configuré, on n'affiche pas le DateHitogram
  if (!champDate) return null;

  if (isHistogramVisible && (_.isEmpty(strategy) || _.isEmpty(publicationDatesSerie))) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{
          bgcolor: theme.palette.primary.transparent,
          minHeight: '110px',
        }}
      />
    );
  }

  return (
    (publicationDatesSerie[0].data.length > 1) ? (
      <DateHistogram
        isHistogramVisible={isHistogramVisible}
        setIsHistogramVisible={setIsHistogramVisible}
        highchartsOptions={{
          options: {
            chart: {
              type: 'column',
              height: '110px',
              backgroundColor: theme.palette.primary.transparent,
            },
            title: { text: '' },
            legend: {
              enabled: false,
            },
            plotOptions: {
              column: {
                borderRadius: 3,
              },
            },
            series: publicationDatesSerie,
            tooltip: {
              pointFormat: '{point.x:%d/%m/%Y} - {point.y}',
            },
            xAxis: {
              title: { text: '' },
              startOnTick: true,
              endOnTick: true,
              gridLineWidth: 0,
              dateTimeLabelFormats: {
                second: '%d/%m/%Y<br/>%H:%M:%S',
                minute: '%d/%m/%Y<br/>%H:%M',
                hour: '%d/%m/%Y<br/>%H:%M',
                day: '%Y<br/>%d/%m',
                month: '%m/%Y',
                year: '%Y',
              },
              events: {
                afterSetExtremes: (e) => {
                  if (e.trigger === 'zoom') {
                    const min = publicationDatesSerie[0].data[0][0];
                    const max = publicationDatesSerie[0].data[
                      publicationDatesSerie[0].data.length - 1
                    ][0];
                    let valmin = e.min;
                    let valmax = e.max;
                    if (valmin < min) {
                      valmin = min;
                    }
                    if (valmax > max) {
                      valmax = max;
                    }
                    dispatch(refineAddFacetsValues([
                      {
                        champ: +idChampDate,
                        formatFacet: 'date',
                        comparator: DATE_INTERVAL_COMPARATOR.between,
                        begin: format(new Date(valmin), 'yyyyMMdd'),
                        end: format(new Date(valmax), 'yyyyMMdd'),
                      },
                    ]));
                  }
                },
              },
            },
            yAxis: {
              type: 'logarithmic',
              allowNegativeLog: true,
              gridLineColor: '#ffffff',
              title: { text: '' },
            },
          },
        }}
      />
    ) : (
      null
    )
  );
};

export default DateHistogramContainer;
