import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { fetchResults } from 'generic/core/search/actions';
import { changeActiveBase, resetFormFields } from 'generic/core/config/actions';
import FormSearchSimple from 'generic/components/forms/FormSearchSimple';
import { getNormalizedFormValues, computeSearchFormInitialValues } from 'generic/utils/qesUtils';
import FormSearchAdvanced from 'generic/components/forms/FormSearchAdvanced';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { setDashboardLoading } from 'generic/core/dashboard/actions';

const SearchContainer = ({ version, isVisible }) => {
  const activeBase = useSelector((state) => state.config.activeBase);
  const bases = useSelector((state) => state.config.bases);
  const fields = useSelector((state) => state.config.form.champs);
  const loading = useSelector((state) => state.search.loading);
  const results = useSelector((state) => state.search.results);
  const location = useLocation();
  const { push, replace } = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const locationParams = queryString.parse(_.get(location, 'search', ''));
  const initialValues = computeSearchFormInitialValues(fields, locationParams);

  let fieldSimple = null;
  const fieldsAdvanced = [];
  if (!_.isEmpty(initialValues)) {
    _.each(fields, (field) => {
      if (field.code === 'F_2') {
        fieldSimple = field;
      } else if (field.mode === 1000) {
        // Seuls les champs avec field.mode === 1000 sont valides,
        // les autres servent à remplir les initialValues (champs
        // "masqués" correspondant aux facettes cliquées, par ex.)
        fieldsAdvanced.push(field);
      }
    });
  } else {
    fieldSimple = {
      code: 'F_2',
    };
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formatSearch = (values) => {
    const {
      tranche: activeBaseDefaultTranche,
      tranches: activeBaseTranches,
    } = activeBase;
    let tranche = activeBaseDefaultTranche;
    const localStorageTranche = +localStorage.getItem('rowsPerPage');
    if (!_.isEmpty(activeBaseTranches)
      && activeBaseTranches.includes(localStorageTranche)) {
      tranche = localStorageTranche;
    }
    const formattedValues = {
      base: activeBase.base,
      premier: 1,
      langue: 1,
      dernier: tranche,
      tranche,
      champs: getNormalizedFormValues(values),
    };
    return formattedValues;
  };

  const handleClear = () => {
    replace(_.get(location, 'pathname', ''));
    dispatch(resetFormFields());
  };

  const handleChangeActiveBase = (baseId) => {
    dispatch(changeActiveBase(baseId));
    const pathname = _.get(location, 'pathname', '');
    if (pathname.indexOf('/ged') === 0) {
      replace('/dashboard');
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = (values) => {
    const formattedValues = formatSearch(values);
    const pathname = _.get(location, 'pathname', '');
    if (pathname.indexOf('/results') === -1) {
      push('/results');
    }
    dispatch(fetchResults({
      bodyItems: formattedValues,
      clearSelection: true,
      clearResults: true,
      refreshForm: true,
    }));
  };

  useEffect(() => {
    if (['/results', '/dashboard'].includes(location.pathname) && !loading && _.isEmpty(results)) {
      if (location.pathname === '/dashboard') {
        dispatch(setDashboardLoading());
      }
      dispatch(fetchResults({
        bodyItems: formatSearch(
          location?.search ? initialValues : QES_CONSTANTS.DEFAULT_SEARCH_FULL_BASE,
        ),
        refreshForm: true,
        clearResults: true,
        clearSelection: true,
      }));
    }
  }, [loading, location, initialValues, results, dispatch, formatSearch]);

  return (
    version === 'simple' ? (
      <FormSearchSimple
        onSubmit={handleSubmit}
        initialValues={initialValues}
        fieldSimple={fieldSimple}
        activeBaseLabel={activeBase.libelle || t('config.default_base_label')}
      />
    ) : (
      <FormSearchAdvanced
        activeBaseId={activeBase.base}
        activeBaseLabel={activeBase.libelle || t('config.default_base_label')}
        bases={bases}
        handleChangeActiveBase={handleChangeActiveBase}
        onSubmit={handleSubmit}
        loading={loading}
        initialValues={initialValues}
        handleClear={handleClear}
        fieldSimple={fieldSimple}
        fieldsAdvanced={fieldsAdvanced}
        isVisible={isVisible}
      />
    )
  );
};

SearchContainer.propTypes = {
  version: PropTypes.oneOf(['simple', 'advanced']),
  isVisible: PropTypes.bool,
};

SearchContainer.defaultProps = {
  version: 'simple',
  isVisible: true,
};

export default SearchContainer;
