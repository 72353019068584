import { useSelector } from 'react-redux';

import _ from 'lodash';
import { format } from 'generic/utils/dateUtils';
import { t } from 'i18next';
import {
  generateLinkToResource, getValueByBaseFieldsConfig, getValueOrFirstValueFromArray,
} from 'generic/utils/qesUtils';

export default (document) => {
  const { qesdocument, base: docBaseId } = document;

  const bases = useSelector((state) => state.config.bases);
  const documentBase = _.find(bases, { base: docBaseId });

  // noms des champs issues de la configuration de la base, permet d'être un peu dynamique
  // et avec de la chance, ne rien avoir à surcharger
  const id = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champId');
  const pdf = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champPdf');

  // noms des champs en durs, difficile de les rendre dynamique en l'état de l'API
  // on prend le premier élément du tableau de valeur s'il y en a plusieur.
  const text = getValueOrFirstValueFromArray(qesdocument?.TXT_CLEAN || qesdocument?.TEXTE);
  const datePubXml = getValueOrFirstValueFromArray(qesdocument?.PUB_DATE);
  const thumbXml = getValueOrFirstValueFromArray(qesdocument?.IMAGE || qesdocument?.THUMB);
  const linkout = getValueOrFirstValueFromArray(qesdocument?.URL || qesdocument?.QUOTE_URL);
  let source = '';
  if (linkout) {
    source = (new URL(linkout)).hostname;
  }

  // dernières retouches avant le retour du résulat
  let thumb;
  if (thumbXml) {
    thumb = qesdocument?.IMAGE ? thumbXml : generateLinkToResource(docBaseId, thumbXml);
  }
  const title = _.get(qesdocument, 'TITLE', [`[${t('document.title_undefined')}]`]);
  const status = undefined; // on ne veut pas l'afficher pour rens
  const date = format(datePubXml);
  const snippet = _.truncate(text, {
    length: 800,
    separator: /,? +/,
  });

  return {
    date,
    id,
    pdf,
    thumb,
    snippet,
    status,
    text,
    title,
    linkout,
    source,
  };
};
