import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTheme } from '@mui/styles';
import clsx from 'clsx';

import {
  AppBar,
  Box,
  ClickAwayListener,
  Divider,
  Toolbar,
  useMediaQuery,
  ListItemText,
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from '@mui/icons-material';

import ToggleMobileSearchIconContainer from 'generic/containers/ToggleMobileSearchIconContainer';
import { utilisateurPropType } from 'generic/core/qes/proptypes';
import AccountMenu from 'generic/components/ui/AccountMenu';
import DropdownMenu from 'generic/components/ui/DropdownMenu';
import { overrideRessource } from 'generic/utils/utils';
import { isHeaderBackgroundColorDark } from 'generic/utils/colorUtils';
import HeaderSearchWrapper from 'generic/components/ui/HeaderSearchWrapper';

const HeaderMenuItem = overrideRessource('components/ui/HeaderMenuItem');
const logoWhite = overrideRessource('img/header-logo.svg', true);
const logoBlack = overrideRessource('img/header-logo-black.svg', true);

const LinksMenu = ({
  webTrackingAccess, mobile, hasWatches, hasCarts, hasNewsletters,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Fragment>
      {webTrackingAccess && (
        <HeaderMenuItem
          component={NavLink}
          to="/anr/folders"
          selected={location.pathname.startsWith('/anr/folders')}
        >
          <ListItemText>{t('header.web_tracking')}</ListItemText>
        </HeaderMenuItem>
      )}

      {hasCarts && (
        <HeaderMenuItem
          component={NavLink}
          to="/carts"
          selected={location.pathname.startsWith('/carts')}
        >
          <ListItemText>{t('header.carts')}</ListItemText>
        </HeaderMenuItem>
      )}

      {hasNewsletters && (
        <HeaderMenuItem
          component={NavLink}
          to="/newsletters"
          selected={location.pathname.startsWith('/newsletters')}
        >
          <ListItemText>{t('header.newsletters')}</ListItemText>
        </HeaderMenuItem>
      )}

      {hasWatches && (
        <HeaderMenuItem
          component={NavLink}
          to="/alerts"
          selected={location.pathname.startsWith('/alerts')}
        >
          <ListItemText>{t('header.alerts')}</ListItemText>
        </HeaderMenuItem>
      )}

      {mobile && (
        <Fragment>
          <Divider />
          <HeaderMenuItem
            component={NavLink}
            to={`/dashboard${location?.search || ''}`}
            selected={location.pathname === '/dashboard'}
          >
            <ListItemText>{t('header.dashboard')}</ListItemText>
          </HeaderMenuItem>
          <HeaderMenuItem
            component={NavLink}
            to={`/results${location?.search || ''}`}
            selected={location.pathname === '/results'}
          >
            <ListItemText>{t('header.results')}</ListItemText>
          </HeaderMenuItem>
        </Fragment>
      )}
    </Fragment>
  );
};

LinksMenu.propTypes = {
  webTrackingAccess: PropTypes.bool,
  mobile: PropTypes.bool,
  hasWatches: PropTypes.bool,
  hasCarts: PropTypes.bool,
  hasNewsletters: PropTypes.bool,
};

LinksMenu.defaultProps = {
  webTrackingAccess: false,
  mobile: false,
  hasWatches: false,
  hasCarts: false,
  hasNewsletters: false,
};

const Header = ({
  user,
  hasWatches,
  hasCarts,
  hasNewsletters,
}) => {
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(true);
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    setIsSearchInputVisible(!smallerThanLarge);
  }, [smallerThanLarge]);

  const forceHideMenusForMobile = smallerThanLarge && isSearchInputVisible;
  const forceShowMenusForMobile = smallerThanLarge && !isSearchInputVisible;
  const toolbarSx = smallerThanLarge ? { px: '10px' } : {};
  const appBarColor = theme.palette.header ? 'header' : 'primary';
  const activeLogo = isHeaderBackgroundColorDark(theme) ? logoWhite : logoBlack;

  return (
    <ClickAwayListener
      onClickAway={() => (forceHideMenusForMobile ? setIsSearchInputVisible(false) : _.noop)}
      mouseEvent="onMouseDown"
    >
      <Box width="100%">
        <AppBar elevation={0} position="static" color={appBarColor}>
          <Toolbar
            className={clsx('displayFlex', 'justifyContentSpaceBetween')}
            sx={toolbarSx}
          >
            <Fragment>
              <Box
                display="flex"
                alignItems="center"
                gap={1}
              >
                <img
                  src={activeLogo}
                  className={theme.custom.logo}
                  alt="Qwam Logo"
                  style={{
                    display: forceHideMenusForMobile ? 'none' : 'block',
                    marginRight: '24px',
                  }}
                />
                <DropdownMenu
                  btnChildren={<Menu />}
                  btnProps={{
                    sx: {
                      display: forceShowMenusForMobile ? 'flex' : 'none',
                      backgroundColor: isHeaderBackgroundColorDark(theme) ? 'unset' : 'rgba(0, 0, 0, 0.1)',
                      padding: '8px',
                      color: isHeaderBackgroundColorDark(theme) ? '#ffffff' : '#000000',
                    },
                  }}
                >
                  <LinksMenu
                    mobile
                    webTrackingAccess={user.accesAnR}
                    hasWatches={hasWatches}
                    hasCarts={hasCarts}
                    hasNewsletters={hasNewsletters}
                  />
                </DropdownMenu>
                {!smallerThanLarge && (
                  <LinksMenu
                    webTrackingAccess={user.accesAnR}
                    hasWatches={hasWatches}
                    hasCarts={hasCarts}
                    hasNewsletters={hasNewsletters}
                  />
                )}
              </Box>
              <HeaderSearchWrapper showSearchInput={isSearchInputVisible} />
              <Box display="flex" alignItems="center">
                <ToggleMobileSearchIconContainer
                  handleClick={() => setIsSearchInputVisible(true)}
                  isVisible={forceShowMenusForMobile}
                  headerBackgroundColorIsDark={isHeaderBackgroundColorDark(theme)}
                />
                <Box
                  sx={{
                    display: forceHideMenusForMobile ? 'none' : 'inline-block',
                  }}
                >
                  { user && (
                    <AccountMenu
                      user={user}
                      hasWatches={hasWatches}
                      hasCarts={hasCarts}
                      webTrackingAccess={user.accesAnR}
                      avatarSx={
                        { boxShadow: `0 0 4px 0px ${isHeaderBackgroundColorDark(theme) ? '#ffffff' : '#c5c5c5'}` }
                      }
                    />
                  )}
                </Box>
              </Box>
            </Fragment>
          </Toolbar>
        </AppBar>
      </Box>
    </ClickAwayListener>
  );
};

Header.propTypes = {
  user: utilisateurPropType,
  hasWatches: PropTypes.bool,
  hasCarts: PropTypes.bool,
  hasNewsletters: PropTypes.bool,
};

Header.defaultProps = {
  user: undefined,
  hasWatches: false,
  hasCarts: false,
  hasNewsletters: false,
};

export default Header;
