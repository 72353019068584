import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonGroup,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  AddAlert,
  EventRepeat,
  NotificationAddOutlined,
  RestartAlt,
  Settings,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import TooltipButton from 'generic/components/ui/TooltipButton';

const CriteriaButtons = ({
  handleReset,
  handleSearchPeriod,
  handleSearchAlert,
  showSearchPeriodButton,
  canCreateAlerts,
  requests,
  handleOpenCreateAlert,
}) => {
  const [anchorElPeriod, setAnchorElPeriod] = React.useState(null);
  const open = Boolean(anchorElPeriod);
  const handleOpenPeriodsMenu = (event) => setAnchorElPeriod(event.currentTarget);
  const handleClosePeriodsMenu = () => setAnchorElPeriod(null);
  const [anchorElAlert, setAnchorElAlert] = React.useState(null);
  const openAlert = Boolean(anchorElAlert);
  const handleOpenAlertsMenu = (event) => setAnchorElAlert(event.currentTarget);
  const handleCloseAlertsMenu = () => setAnchorElAlert(null);
  const { t } = useTranslation();

  const paperProps = {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    maxWidth: '300px',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 40,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  };

  return (
    <Fragment>
      <Box p={1} pb={0.5}>
        <ButtonGroup
          sx={{ height: '32px' }}
          disableElevation
        >
          <TooltipButton
            key="reset"
            sx={{ borderColor: 'primary', borderRadius: showSearchPeriodButton ? '15px 0 0 15px' : '15px' }}
            title={t('results.criteria.reset')}
            onClick={handleReset}
          >
            <RestartAlt />
          </TooltipButton>
          {canCreateAlerts && (
            <TooltipButton
              key="alert"
              sx={{ borderColor: 'primary' }}
              title={t('actions.create_alert')}
              onClick={handleOpenAlertsMenu}
            >
              <NotificationAddOutlined />
            </TooltipButton>
          )}
          {showSearchPeriodButton && (
            <TooltipButton
              key="period"
              sx={{ borderColor: 'primary', borderRadius: '0 15px 15px 0' }}
              title={t('results.criteria.period')}
              onClick={handleOpenPeriodsMenu}
            >
              <EventRepeat />
            </TooltipButton>
          )}
        </ButtonGroup>
      </Box>
      <Menu
        anchorEl={anchorElAlert}
        id="account-menu"
        open={openAlert}
        onClose={handleCloseAlertsMenu}
        onClick={handleCloseAlertsMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            ...paperProps,
            ml: 4,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleOpenCreateAlert()}>
          <ListItemIcon>
            <AddAlert fontSize="small" />
          </ListItemIcon>
          {t('actions.create_alert')}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/alerts"
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t('actions.manage_alert')}
        </MenuItem>
        {requests.length > 0 && (
          <Divider />
        )}
        <Box maxHeight="300px" overflow="auto">
          {(
            requests.map((request) => (
              <MenuItem
                key={request.veille}
                onClick={() => handleSearchAlert(request.veille)}
                dense
              >
                <ListItemText
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                >
                  {request.libelle}
                </ListItemText>
              </MenuItem>
            ))
          )}
        </Box>
      </Menu>
      <Menu
        anchorEl={anchorElPeriod}
        id="account-menu"
        open={open}
        onClose={handleClosePeriodsMenu}
        onClick={handleClosePeriodsMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            ...paperProps,
            ml: canCreateAlerts ? 11 : 4,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleSearchPeriod('today')}>
          {t('commons.periods.today')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('yesterday')}>
          {t('commons.periods.yesterday')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('this_week')}>
          {t('commons.periods.this_week')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('last_week')}>
          {t('commons.periods.last_week')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('last_7_days')}>
          {t('commons.periods.last_7_days')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('this_month')}>
          {t('commons.periods.this_month')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('last_month')}>
          {t('commons.periods.last_month')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('last_30_days')}>
          {t('commons.periods.last_30_days')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('this_year')}>
          {t('commons.periods.this_year')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('last_year')}>
          {t('commons.periods.last_year')}
        </MenuItem>
        <MenuItem onClick={() => handleSearchPeriod('last_12_months')}>
          {t('commons.periods.last_12_months')}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

CriteriaButtons.propTypes = {
  handleReset: PropTypes.func.isRequired,
  handleSearchPeriod: PropTypes.func.isRequired,
  handleSearchAlert: PropTypes.func.isRequired,
  showSearchPeriodButton: PropTypes.bool.isRequired,
  canCreateAlerts: PropTypes.bool,
  requests: PropTypes.arrayOf(PropTypes.shape()),
  handleOpenCreateAlert: PropTypes.func.isRequired,
};

CriteriaButtons.defaultProps = {
  canCreateAlerts: false,
  requests: [],
};

export default CriteriaButtons;
