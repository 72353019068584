import {
  take,
  put,
  call,
  fork,
  cancel,
  takeEvery,
  takeLeading,
  all,
  takeLatest,
} from 'redux-saga/effects';
import i18next from 'i18next';
import _ from 'lodash';

import {
  fetchError,
  logonSuccess,
  logonError,
  restoreStateFromStorageError,
  saveProfileSuccess,
  saveProfileError,
  uploadUserImageSuccess,
  uploadUserImageError,
  resetPasswordSuccess,
  resetPasswordError,
  resetPasswordConfirmationSuccess,
  resetPasswordConfirmationError,
} from 'generic/core/auth/actions';

import {
  logon,
  logonTest,
  logout,
  resetPassword,
} from 'generic/api/auth';

import * as types from 'generic/core/auth/actionTypes';
import { snackActions } from 'generic/utils/snackbar';
import { cleanupConfig, setConfig } from 'generic/core/config/actions';
import { cleanupAnRUser } from 'generic/core/anr/actions';
import { patchUser, uploadAvatar } from 'generic/api/users';

const clearAuth = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('config');
};

const storeAuth = ({ user, token, config }) => {
  if (user && token && config) {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('config', JSON.stringify(config));
  }
};

function* authenticate({ login, password, callback }) {
  try {
    const response = yield call(logon, { logon: login, password });
    const { key: token, utilisateur: user, config } = response;
    yield call(callback, { response });
    yield call(storeAuth, { token, user, config });
    yield all([
      put(setConfig(config, user)),
      put(logonSuccess(token)),
    ]);
  } catch (error) {
    yield call(callback, { error });
    yield put(logonError(error));
  }
}

function* unauthenticate() {
  // On se fout de savoir si la déconnexion s'est bien passé ou non.
  // Souvent, le token déjà périmé et demander une invalidation de session est inutile.
  try {
    yield call(logout);
  } catch (error) {
    console.error(error);
  }
}

function* checkAuthValidity(object) {
  const { response } = object;
  // Lorsqu'une action est dispatchée et si son nom match /.*_ERROR$/ il est possible que
  // ce soit le token qui ne soit plus valide.
  // Si l'erreur fait suite à un `fetch` on peut alors voir si on a une erreur 401 et demander
  // une reconnexion si besoin.
  const isPromiseResponse = response instanceof Response;
  if (isPromiseResponse && !response.ok && response.status === 401) {
    yield put({ type: types.LOGOUT });
    snackActions.error(i18next.t('logon.error_unauthorized'));
  }
}

function* restoreStateFromStorage() {
  let user;

  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch (e) {
    console.error('can\'t retrieve keys from store', e);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('config');
  }

  if (user) {
    const response = yield call(logonTest, { logon: user.logon });
    yield call(checkAuthValidity, { response });
  }

  const token = localStorage.getItem('token');
  const config = JSON.parse(localStorage.getItem('config'));
  if (user && token && config) {
    yield all([
      put(setConfig(config, user)),
      put(logonSuccess(token)),
    ]);
  } else {
    yield put(restoreStateFromStorageError('can\'t retrieve user from storage'));
  }
}

function* workLogout() {
  // on invalide le localstorage
  yield call(clearAuth);

  // On vide la config
  yield put(cleanupConfig());

  // on vire également les infos sur le user Ask'n'Read dans Redux
  yield put(cleanupAnRUser());

  yield call(unauthenticate);
}

function* watchLogout() {
  yield takeLeading(types.LOGOUT, workLogout);
}

function* watchFetchErrors() {
  yield takeEvery(types.FETCH_ERROR, checkAuthValidity);
}

function* watchAuthRestauration() {
  yield takeEvery(types.RESTORE_STATE_FROM_STORAGE, restoreStateFromStorage);
}

function* watchAuth() {
  while (true) {
    const {
      type, login, password, callback,
    } = yield take([types.LOGON, types.LOGON_SUCCESS]);
    let taskAuthenticate;
    if (type === types.LOGON) {
      taskAuthenticate = yield fork(authenticate, { login, password, callback });
    }

    yield take([types.LOGOUT, types.LOGON_ERROR]);
    // si un tache d'authentification est en cours, on l'annule
    if (taskAuthenticate) yield cancel(taskAuthenticate);
  }
}

function* handleAjaxFailures({ response }) {
  yield put(fetchError(response));
}

function* watchAjaxFailures() {
  yield takeLeading((action) => /.*_ERROR$/.test(action.type), handleAjaxFailures);
}

function* workSaveProfile({ params }) {
  try {
    const user = yield call(patchUser, { bodyItems: { ...params } });
    yield put(saveProfileSuccess(user));
    localStorage.setItem('user', JSON.stringify(user));
    snackActions.success(i18next.t('profile.profile_saved'));
  } catch (error) {
    yield put(saveProfileError(error));
    console.error(error);
    snackActions.error(i18next.t('profile.profile_saved_error'));
  }
}

function* watchSaveProfile() {
  yield takeLatest(types.SAVE_PROFILE, workSaveProfile);
}

function* workUploadAvatar({ file }) {
  let message;
  try {
    const results = yield call(uploadAvatar, { fileUpload: true, bodyItems: file });
    message = _.get(results, 'status');
    if (message === 'KO') {
      message = _.get(results, 'error.message');
      throw new Error(message);
    }
    yield put(uploadUserImageSuccess(results));
  } catch (error) {
    yield put(uploadUserImageError(error));
    console.error(error);
    snackActions.error(message || i18next.t('profile.upload_avatar_error'));
  }
}

function* watchUploadAvatar() {
  yield takeLatest(types.UPLOAD_USER_IMAGE, workUploadAvatar);
}

function* workResetPassword({ emailOrLogin, callback }) {
  let message;
  try {
    const response = yield call(resetPassword, { emailOrLogin });

    message = _.get(response, 'status');
    if (message === 'KO') {
      message = _.get(response, 'error.message');
      throw new Error(message);
    }
    yield call(callback, { response });
    yield put(resetPasswordSuccess(response));
    snackActions.success(message || i18next.t('logon.forgotten_password_sent'));
  } catch (error) {
    yield call(callback, { error });
    yield put(resetPasswordError(error));
    console.error(error);
    snackActions.error(message || i18next.t('logon.forgotten_password_error'));
  }
}
function* watchResetPassword() {
  yield takeLatest(types.RESET_PASSWORD, workResetPassword);
}

function* workResetPasswordConfirmation({
  a, newPassword, newPasswordConfirmation, callback,
}) {
  let message;
  try {
    const response = yield call(resetPassword, { a, newPassword, newPasswordConfirmation });

    message = _.get(response, 'status');
    if (message === 'KO') {
      message = _.get(response, 'error.message');
      throw new Error(message);
    }
    yield put(resetPasswordConfirmationSuccess(response));
    yield call(callback, { response });
    snackActions.success(message || i18next.t('logon.reset_password_confirmation_success'), {
      onExiting: () => {
        // push('/'); // devrait fonctionner... mais bon...
        window.location.href = '/';
      },
    });
  } catch (error) {
    yield put(resetPasswordConfirmationError(error));
    yield call(callback, { error });
    console.error(error);
    snackActions.error(message || i18next.t('logon.forgotten_password_error'));
  }
}
function* watchResetPasswordConfirmation() {
  yield takeLatest(types.RESET_PASSWORD_CONFIRMATION, workResetPasswordConfirmation);
}

export default {
  watchAjaxFailures,
  watchAuthRestauration,
  watchAuth,
  watchFetchErrors,
  watchLogout,
  watchResetPassword,
  watchResetPasswordConfirmation,
  watchSaveProfile,
  watchUploadAvatar,
};
