import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Box } from '@mui/material';
import { cleanupNewsletters, fetchNewsletters, types } from 'generic/core/newsletters/actions';
import CartsOrNewsletters from 'generic/components/pages/CartsOrNewsletters';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import CenteredMessage from 'generic/components/ui/CenteredMessage';

const NewslettersContainer = () => {
  const loading = useSelector((state) => state.newsletters.loading);
  const newsletters = useSelector((state) => state.newsletters.newsletters);
  const themes = useSelector((state) => state.config.themesPanier);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleOpenDeleteNewslettersConfirmDialog = (rowsDeleted, data) => {
    const ids = [];
    for (let i = 0; i < rowsDeleted.data.length; i++) {
      if (data[rowsDeleted.data[i].dataIndex]) {
        ids.push(data[rowsDeleted.data[i].dataIndex][0]);
      }
    }
    dispatch(setConfirmDialogData({
      title: t('newsletters.delete'),
      message: t('newsletters.delete_newsletters', { count: ids.length }),
      submitColor: 'danger',
      waitForActions: [types.DELETE_NEWSLETTERS_SUCCESS, types.DELETE_NEWSLETTERS_ERROR],
      action: {
        type: types.DELETE_NEWSLETTERS,
        ids,
      },
    }));
    return false;
  };

  useEffect(() => {
    dispatch(fetchNewsletters());

    // Cleanup des newsletters lorsque le composant est "unmount" (donc quand on
    // change de page)
    return () => dispatch(cleanupNewsletters());
  }, [dispatch]);

  if (_.isEmpty(newsletters)) {
    if (loading) {
      return <GlobalLoading />;
    }
    return (
      <Box width="100%">
        <CenteredMessage>
          {t('newsletters.no_newsletters')}
        </CenteredMessage>
      </Box>
    );
  }

  return (
    <CartsOrNewsletters
      handleDelete={handleOpenDeleteNewslettersConfirmDialog}
      items={newsletters}
      itemsType="newsletters"
      loading={loading}
      themes={themes}
    />
  );
};

export default NewslettersContainer;
