import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  addDays,
  addWeeks,
  addMonths,
  addYears,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { fetchResults } from 'generic/core/search/actions';
import { cleanupDashboard } from 'generic/core/dashboard/actions';
import CriteriaButtons from 'generic/components/ui/CriteriaButtons';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { format } from 'generic/utils/dateUtils';
import { openDialogCreateAlert } from 'generic/core/actions/actions';
import { fetchAlerts } from 'generic/core/alerts/actions';

const CriteriaButtonsContainer = () => {
  const bases = useSelector((state) => state.config.bases);
  const alerts = useSelector((state) => state.alerts.alerts);
  const {
    base: activeBaseId,
    tranche: activeBaseDefaultTranche,
    veille: canCreateAlerts,
  } = useSelector((state) => state.config.activeBase);
  const dispatch = useDispatch();
  const {
    DATE_INTERVAL_COMPARATOR,
    DEFAULT_SEARCH_FULL_BASE,
    ALERT,
  } = QES_CONSTANTS;

  const currentBase = _.find(bases, { base: activeBaseId });
  const { idChampDate } = currentBase;
  const filteredRequests = _.filter(alerts, { type_veille: ALERT.TYPES.REQUEST });

  const handleReset = () => {
    dispatch(cleanupDashboard());
    dispatch(fetchResults({
      bodyItems: {
        premier: 1,
        dernier: activeBaseDefaultTranche,
        tranche: activeBaseDefaultTranche,
        base: activeBaseId,
        champs: DEFAULT_SEARCH_FULL_BASE,
      },
      refreshForm: true,
      clearResults: true,
      clearSelection: true,
    }));
  };

  const handleSearchAlert = (alertId) => {
    dispatch(cleanupDashboard());
    dispatch(fetchResults({
      uriParams: {
        veille: alertId,
        premier: 1,
        dernier: 20,
      },
      refreshForm: true,
      clearResults: true,
      clearSelection: true,
    }));
  };

  const handleSearchPeriod = (period) => {
    let dateFrom = new Date();
    let dateTo = new Date();
    let comparator = DATE_INTERVAL_COMPARATOR.between;
    switch (period) {
      case 'today': {
        comparator = DATE_INTERVAL_COMPARATOR.equal;
        dateTo = null;
        break;
      }
      case 'yesterday': {
        comparator = DATE_INTERVAL_COMPARATOR.equal;
        dateFrom.setDate(dateFrom.getDate() - 1);
        dateTo = null;
        break;
      }
      case 'this_week': {
        dateFrom = startOfISOWeek(dateFrom);
        dateTo = addWeeks(startOfISOWeek(dateFrom), 1);
        break;
      }
      case 'last_week': {
        dateTo = startOfISOWeek(dateFrom);
        dateFrom = addWeeks(startOfISOWeek(dateFrom), -1);
        break;
      }
      case 'last_7_days': {
        dateTo = dateFrom;
        dateFrom = addDays(dateFrom, -7);
        break;
      }
      case 'this_month': {
        dateFrom = startOfMonth(dateFrom);
        dateTo = addMonths(startOfMonth(dateFrom), 1);
        break;
      }
      case 'last_month': {
        dateTo = startOfMonth(dateFrom);
        dateFrom = addMonths(startOfMonth(dateFrom), -1);
        break;
      }
      case 'last_30_days': {
        dateTo = dateFrom;
        dateFrom = addDays(dateFrom, -30);
        break;
      }
      case 'this_year': {
        dateFrom = startOfYear(dateFrom);
        dateTo = addYears(startOfYear(dateFrom), 1);
        break;
      }
      case 'last_year': {
        dateTo = startOfYear(dateFrom);
        dateFrom = addYears(startOfYear(dateFrom), -1);
        break;
      }
      case 'last_12_months': {
        dateTo = dateFrom;
        dateFrom = addMonths(dateFrom, -12);
        break;
      }
      default: {
        break;
      }
    }
    const champs = {
      [`DATE_${idChampDate}_comp`]: comparator,
      [`DATE_${idChampDate}_from`]: format(dateFrom, 'dd/MM/yyyy'),
      [`DATE_${idChampDate}_to`]: dateTo != null ? format(dateTo, 'dd/MM/yyyy') : null,
    };
    dispatch(cleanupDashboard());
    dispatch(fetchResults({
      bodyItems: {
        premier: 1,
        dernier: activeBaseDefaultTranche,
        tranche: activeBaseDefaultTranche,
        base: activeBaseId,
        champs,
      },
      refreshForm: true,
      clearResults: true,
      clearSelection: true,
    }));
  };

  useEffect(() => {
    dispatch(fetchAlerts(ALERT.STATUSES.ACTIVE.join(',')));
  }, [ALERT, dispatch]);

  return (
    <CriteriaButtons
      handleReset={handleReset}
      handleSearchPeriod={handleSearchPeriod}
      handleSearchAlert={handleSearchAlert}
      showSearchPeriodButton={!!idChampDate}
      canCreateAlerts={canCreateAlerts}
      requests={filteredRequests}
      handleOpenCreateAlert={() => dispatch(openDialogCreateAlert())}
    />
  );
};

export default CriteriaButtonsContainer;
