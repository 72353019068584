import _ from 'lodash';
import { types } from 'generic/core/search/actions';

const searchReducer = (state = {
  results: {},
  loading: false,
  loadingBlank: false,
  resultsComplete: {},
  loadingComplete: false,
}, action) => {
  switch (action.type) {
    case types.CLEANUP_RESULTS: {
      return {
        ...state,
        results: {},
      };
    }
    case types.FETCH_RESULTS: {
      return {
        ...state,
        loading: true,
        loadingBlank: action.values.clearResults,
        results: action.values.clearResults ? {} : { ...state.results },
      };
    }
    case types.FETCH_RESULTS_SUCCESS: {
      const { facets: resultsFacets } = action.results;
      const facets = _.map(
        resultsFacets,
        (facet) => ({
          ...facet,
          mouvement: null,
          mouvement_maitre: null,
          facetValues: _.map(
            facet.facetValues,
            ({
              champ,
              formatFacet,
              frequence,
              id,
              libelle,
              strategie,
            }) => ({
              champ,
              formatFacet,
              frequence,
              id,
              libelle,
              strategie,
            }),
          ),
        }),
      );
      return {
        ...state,
        results: {
          ...action.results,
          facets,
        },
        loading: false,
        loadingBlank: false,
      };
    }
    case types.FETCH_RESULTS_ERROR: {
      return {
        ...state,
        results: {},
        loading: false,
        loadingBlank: false,
      };
    }
    case types.CLEANUP_RESULTS_COMPLETE: {
      return {
        ...state,
        loadingComplete: false,
        resultsComplete: {},
      };
    }
    case types.FETCH_RESULTS_COMPLETE: {
      return {
        ...state,
        loadingComplete: true,
        resultsComplete: {},
      };
    }
    case types.FETCH_RESULTS_COMPLETE_SUCCESS: {
      return {
        ...state,
        resultsComplete: {
          ...action.results,
        },
        loadingComplete: false,
      };
    }
    case types.FETCH_RESULTS_COMPLETE_ERROR: {
      return {
        ...state,
        resultsComplete: {},
        loadingComplete: false,
      };
    }
    case types.UPDATE_DOCUMENT_COMMENTS: {
      const resultsCloned = _.cloneDeep(state.results);
      const docToUpdate = _.find(resultsCloned.documents, { idext: action.idext });
      if (!_.isEmpty(docToUpdate)) {
        docToUpdate.commentaires = action.comments;
        docToUpdate.hasComment = _.some(action.comments, { user_id: action.userId });
        docToUpdate.commentAlert = _.some(action.comments, { user_id: action.userId, alert: true });
      }
      const resultsCompleteCloned = !_.isEmpty(state.resultsComplete) ? _.cloneDeep(state.resultsComplete) : {};
      const docCompleteToUpdate = _.find(resultsCompleteCloned.documents, { idext: action.idext });
      if (!_.isEmpty(docCompleteToUpdate)) {
        docCompleteToUpdate.commentaires = action.comments;
        docCompleteToUpdate.hasComment = _.some(action.comments, { user_id: action.userId });
        docCompleteToUpdate.commentAlert = _.some(action.comments, { user_id: action.userId, alert: true });
      }
      return {
        ...state,
        results: resultsCloned,
        resultsComplete: resultsCompleteCloned,
        loading: false,
      };
    }
    case types.UPDATE_DOCUMENT_ALERT: {
      const resultsCloned = _.cloneDeep(state.results);
      const docToUpdate = _.find(resultsCloned.documents, { idext: action.idext });
      if (!_.isEmpty(docToUpdate)) {
        docToUpdate.commentAlert = action.alert;
      }
      const resultsCompleteCloned = !_.isEmpty(state.resultsComplete) ? _.cloneDeep(state.resultsComplete) : {};
      const docCompleteToUpdate = _.find(resultsCompleteCloned.documents, { idext: action.idext });
      if (!_.isEmpty(docCompleteToUpdate)) {
        docCompleteToUpdate.commentAlert = action.alert;
      }
      return {
        ...state,
        results: resultsCloned,
        resultsComplete: resultsCompleteCloned,
      };
    }
    case types.SET_DOCUMENT_MORE_LIKE_THIS_LOADING: {
      const key = action.formatComplete ? 'resultsComplete' : 'results';
      const resultsCloned = _.cloneDeep(state[key]);
      const docToUpdate = _.find(resultsCloned.documents, { idext: action.idext });
      if (!_.isEmpty(docToUpdate)) {
        docToUpdate.loadingMoreLikeThis = action.loadingMoreLikeThis;
      }
      return {
        ...state,
        [key]: resultsCloned,
      };
    }
    case types.UPDATE_DOCUMENT_MORE_LIKE_THIS: {
      const key = action.formatComplete ? 'resultsComplete' : 'results';
      const resultsCloned = _.cloneDeep(state[key]);
      const docToUpdate = _.find(resultsCloned.documents, { idext: action.idext });
      if (!_.isEmpty(docToUpdate)) {
        docToUpdate.morelikethis = action.morelikethis;
      }
      return {
        ...state,
        [key]: resultsCloned,
      };
    }
    default:
      return state;
  }
};

export default searchReducer;
