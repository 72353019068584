export const types = {
  CLEANUP_ANR_USER: '@ANR/CLEANUP_ANR_USER',
  CLEANUP_ANR_FOLDERS: '@ANR/CLEANUP_ANR_FOLDERS',
  CLEANUP_ANR_SOURCES: '@ANR/CLEANUP_ANR_SOURCES',
  CLEANUP_ANR_BANNED_SITES: '@ANR/CLEANUP_ANR_BANNED_SITES',
  BAN_SITE_ERROR: '@ANR/BAN_SITE_ERROR',
  BAN_SITE_SUCCESS: '@ANR/BAN_SITE_SUCCESS',
  BAN_SITE: '@ANR/BAN_SITE',
  CHANGE_ACTIVE_ITEM: '@ANR/CHANGE_ACTIVE_ITEM',
  CLOSE_ARTICLE: '@ANR/CLOSE_ARTICLE',
  CLOSE_BAN_SITE_DIALOG: '@ANR/CLOSE_BAN_SITE_DIALOG',
  CLOSE_DEMAND_NEW_SOURCE_DIALOG: '@ANR/CLOSE_DEMAND_NEW_SOURCE_DIALOG',
  CLOSE_SHARE_DIALOG: '@ANR/CLOSE_SHARE_DIALOG',
  COLLECT_ERROR: '@ANR/COLLECT_ERROR',
  COLLECT_SUCCESS: '@ANR/COLLECT_SUCCESS',
  COLLECT: '@ANR/COLLECT',
  DELETE_ARTICLES_ERROR: '@ANR/DELETE_ARTICLES_ERROR',
  DELETE_ARTICLES_SUCCESS: '@ANR/DELETE_ARTICLES_SUCCESS',
  DELETE_ARTICLES: '@ANR/DELETE_ARTICLES',
  DELETE_FOLDER_ERROR: '@ANR/DELETE_FOLDER_ERROR',
  DELETE_FOLDER_SUCCESS: '@ANR/DELETE_FOLDER_SUCCESS',
  DELETE_FOLDER: '@ANR/DELETE_FOLDER',
  DEMAND_NEW_SOURCE_ERROR: '@ANR/DEMAND_NEW_SOURCE_ERROR',
  DEMAND_NEW_SOURCE_SUCCESS: '@ANR/DEMAND_NEW_SOURCE_SUCCESS',
  DEMAND_NEW_SOURCE: '@ANR/DEMAND_NEW_SOURCE',
  FETCH_ACTIVE_ITEM_RESULTS: '@ANR/FETCH_ACTIVE_ITEM_RESULTS',
  FETCH_ANR_BANNED_SITES_ERROR: '@ANR/FETCH_ANR_BANNED_SITES_ERROR',
  FETCH_ANR_BANNED_SITES_SUCCESS: '@ANR/FETCH_ANR_BANNED_SITES_SUCCESS',
  FETCH_ANR_BANNED_SITES: '@ANR/FETCH_ANR_BANNED_SITES',
  FETCH_ANR_DUPLICATES: '@ANR/FETCH_ANR_DUPLICATES',
  FETCH_ANR_DUPLICATES_SUCCESS: '@ANR/FETCH_ANR_DUPLICATES_SUCCESS',
  FETCH_ANR_DUPLICATES_ERROR: '@ANR/FETCH_ANR_DUPLICATES_ERROR',
  FETCH_ANR_FOLDER_ERROR: '@ANR/FETCH_ANR_FOLDER_ERROR',
  FETCH_ANR_FOLDER_NEWS_ERROR: '@ANR/FETCH_ANR_FOLDER_NEWS_ERROR',
  FETCH_ANR_FOLDER_NEWS_SUCCESS: '@ANR/FETCH_ANR_FOLDER_NEWS_SUCCESS',
  FETCH_ANR_FOLDER_NEWS: '@ANR/FETCH_ANR_FOLDER_NEWS',
  FETCH_ANR_FOLDER_QUOTES_ERROR: '@ANR/FETCH_ANR_FOLDER_QUOTES_ERROR',
  FETCH_ANR_FOLDER_QUOTES_SUCCESS: '@ANR/FETCH_ANR_FOLDER_QUOTES_SUCCESS',
  FETCH_ANR_FOLDER_QUOTES: '@ANR/FETCH_ANR_FOLDER_QUOTES',
  FETCH_ANR_FOLDER_SUCCESS: '@ANR/FETCH_ANR_FOLDER_SUCCESS',
  FETCH_ANR_FOLDER: '@ANR/FETCH_ANR_FOLDER',
  FETCH_ANR_FOLDERS_ERROR: '@ANR/FETCH_ANR_FOLDERS_ERROR',
  FETCH_ANR_FOLDERS_SUCCESS: '@ANR/FETCH_ANR_FOLDERS_SUCCESS',
  FETCH_ANR_FOLDERS: '@ANR/FETCH_ANR_FOLDERS',
  FETCH_ANR_GLIMPSE_ERROR: '@ANR/FETCH_ANR_GLIMPSE_ERROR',
  FETCH_ANR_GLIMPSE_SUCCESS: '@ANR/FETCH_ANR_GLIMPSE_SUCCESS',
  FETCH_ANR_GLIMPSE: '@ANR/FETCH_ANR_GLIMPSE',
  FETCH_ANR_SOURCES_ERROR: '@ANR/FETCH_ANR_SOURCES_ERROR',
  FETCH_ANR_SOURCES_SUCCESS: '@ANR/FETCH_ANR_SOURCES_SUCCESS',
  FETCH_ANR_SOURCES: '@ANR/FETCH_ANR_SOURCES',
  FETCH_ANR_USER_ERROR: '@ANR/FETCH_ANR_USER_ERROR',
  FETCH_ANR_USER_SUCCESS: '@ANR/FETCH_ANR_USER_SUCCESS',
  FETCH_ANR_USER: '@ANR/FETCH_ANR_USER',
  INCREMENT_OR_DECREMENT_NB_FOLDERS: '@ANR/INCREMENT_OR_DECREMENT_NB_FOLDERS',
  OPEN_ARTICLE: '@ANR/OPEN_ARTICLE',
  OPEN_BAN_SITE_DIALOG: '@ANR/OPEN_BAN_SITE_DIALOG',
  OPEN_DEMAND_NEW_SOURCE_DIALOG: '@ANR/OPEN_DEMAND_NEW_SOURCE_DIALOG',
  OPEN_NEXT_ARTICLE: '@ANR/OPEN_NEXT_ARTICLE',
  OPEN_PREVIOUS_ARTICLE: '@ANR/OPEN_PREVIOUS_ARTICLE',
  OPEN_SHARE_DIALOG: '@ANR/OPEN_SHARE_DIALOG',
  SAVE_ANR_FAVORITE_STATUS_ERROR: '@ANR/SAVE_ANR_FAVORITE_STATUS_ERROR',
  SAVE_ANR_FAVORITE_STATUS_SUCCESS: '@ANR/SAVE_ANR_FAVORITE_STATUS_SUCCESS',
  SAVE_ANR_FAVORITE_STATUS: '@ANR/SAVE_ANR_FAVORITE_STATUS',
  SAVE_ANR_FOLDER_ERROR: '@ANR/SAVE_ANR_FOLDER_ERROR',
  SAVE_ANR_FOLDER_SUCCESS: '@ANR/SAVE_ANR_FOLDER_SUCCESS',
  SAVE_ANR_FOLDER: '@ANR/SAVE_ANR_FOLDER',
  SEND_SUCCESS: '@ANR/SEND_SUCCESS',
  SEND: '@ANR/SEND',
  SET_LOADING_DUPLICATES_ARTICLE: '@ANR/SET_LOADING_DUPLICATES_ARTICLE',
  SET_EDIT_FOLDER: '@ANR/SET_EDIT_FOLDER',
  SET_EDIT_NEW_FOLDER: '@ANR/SET_EDIT_NEW_FOLDER',
  SET_FILTERS_PARAM: '@ANR/SET_FILTERS_PARAM',
  SET_FILTERS_PARAMS: '@ANR/SET_FILTERS_PARAMS',
  SET_OPEN_FILTER_NAV: '@ANR/SET_OPEN_FILTER_NAV',
  UNBAN_SITE_ERROR: '@ANR/UNBAN_SITE_ERROR',
  UNBAN_SITE_SUCCESS: '@ANR/UNBAN_SITE_SUCCESS',
  UNBAN_SITE: '@ANR/UNBAN_SITE',
  UNSET_FILTERS_PARAMS: '@ANR/UNSET_FILTERS_PARAM',
  REPORT_EXTRACTION: '@ANR/REPORT_EXTRACTION',
  REPORT_EXTRACTION_SUCCESS: '@ANR/REPORT_EXTRACTION_SUCCESS',
  REPORT_EXTRACTION_ERROR: '@ANR/REPORT_EXTRACTION_ERROR',
  VALIDATE_SUCCESS: '@ANR/VALIDATE_SUCCESS',
  VALIDATE: '@ANR/VALIDATE',
};

export const cleanupAnRUser = () => ({
  type: types.CLEANUP_ANR_USER,
});

export const cleanupAnRFolders = () => ({
  type: types.CLEANUP_ANR_FOLDERS,
});

export const cleanupAnRSources = () => ({
  type: types.CLEANUP_ANR_SOURCES,
});

export const cleanupAnRBannedSites = () => ({
  type: types.CLEANUP_ANR_BANNED_SITES,
});

export const fetchAnRUser = () => ({
  type: types.FETCH_ANR_USER,
});

export const fetchAnRUserSuccess = (results) => ({
  type: types.FETCH_ANR_USER_SUCCESS,
  user: results.user,
});

export const fetchAnRUserError = (response) => ({
  type: types.FETCH_ANR_USER_ERROR,
  response,
});

export const fetchAnRFolders = () => ({
  type: types.FETCH_ANR_FOLDERS,
});

export const fetchAnRFoldersSuccess = (results) => ({
  type: types.FETCH_ANR_FOLDERS_SUCCESS,
  folders: results.folders,
});

export const fetchAnRFoldersError = (response) => ({
  type: types.FETCH_ANR_FOLDERS_ERROR,
  response,
});

export const changeActiveItem = (routeParams) => ({
  type: types.CHANGE_ACTIVE_ITEM,
  routeParams,
});

export const fetchActiveItemResults = (fetchParams, options) => ({
  type: types.FETCH_ACTIVE_ITEM_RESULTS,
  fetchParams,
  options,
});

export const fetchAnRFolderNews = (params, openArticleOption) => ({
  type: types.FETCH_ANR_FOLDER_NEWS,
  params,
  openArticleOption,
});

export const fetchAnRFolderNewsSuccess = (results) => ({
  type: types.FETCH_ANR_FOLDER_NEWS_SUCCESS,
  results,
});

export const fetchAnRFolderNewsError = (response) => ({
  type: types.FETCH_ANR_FOLDER_NEWS_ERROR,
  response,
});

export const fetchAnRGlimpse = (params, openArticleOption) => ({
  type: types.FETCH_ANR_GLIMPSE,
  params,
  openArticleOption,
});

export const fetchAnRGlimpseSuccess = (results) => ({
  type: types.FETCH_ANR_GLIMPSE_SUCCESS,
  results,
});

export const fetchAnRGlimpseError = (response) => ({
  type: types.FETCH_ANR_GLIMPSE_ERROR,
  response,
});

export const fetchAnRFolderQuotes = (params, openArticleOption) => ({
  type: types.FETCH_ANR_FOLDER_QUOTES,
  params,
  openArticleOption,
});

export const fetchAnRFolderQuotesSuccess = (results) => ({
  type: types.FETCH_ANR_FOLDER_QUOTES_SUCCESS,
  results,
});

export const fetchAnRFolderQuotesError = (response) => ({
  type: types.FETCH_ANR_FOLDER_QUOTES_ERROR,
  response,
});

export const unsetFiltersParams = () => ({
  type: types.UNSET_FILTERS_PARAMS,
});

export const setFiltersParam = (param) => ({
  type: types.SET_FILTERS_PARAM,
  param,
});

export const setFiltersParams = (params) => ({
  type: types.SET_FILTERS_PARAMS,
  params,
});

export const setOpenFilterNav = (open) => ({
  type: types.SET_OPEN_FILTER_NAV,
  open,
});

export const setEditFolder = (editFolderId) => ({
  type: types.SET_EDIT_FOLDER,
  editFolderId,
});

export const setEditNewFolder = (folder) => ({
  type: types.SET_EDIT_NEW_FOLDER,
  folder,
});

export const incrementOrDecrementNbFolders = (nb) => ({
  type: types.INCREMENT_OR_DECREMENT_NB_FOLDERS,
  nb,
});

export const fetchAnRFolder = (params) => ({
  type: types.FETCH_ANR_FOLDER,
  params,
});

export const fetchAnRFolderSuccess = (results) => ({
  type: types.FETCH_ANR_FOLDER_SUCCESS,
  results,
});

export const fetchAnRFolderError = (response) => ({
  type: types.FETCH_ANR_FOLDER_ERROR,
  response,
});

export const saveAnRFolder = (params) => ({
  type: types.SAVE_ANR_FOLDER,
  params,
});

export const saveAnRFolderSuccess = (results) => ({
  type: types.SAVE_ANR_FOLDER_SUCCESS,
  results,
});

export const saveAnRFolderError = (response) => ({
  type: types.SAVE_ANR_FOLDER_ERROR,
  response,
});

export const deleteAnRFolderSuccess = () => ({
  type: types.DELETE_FOLDER_SUCCESS,
});

export const deleteAnRFolderError = (response) => ({
  type: types.DELETE_FOLDER_ERROR,
  response,
});

export const saveAnRFavoriteStatus = (params) => ({
  type: types.SAVE_ANR_FAVORITE_STATUS,
  params,
});

export const saveAnRFavoriteStatusSuccess = (results) => ({
  type: types.SAVE_ANR_FAVORITE_STATUS_SUCCESS,
  results,
});

export const saveAnRFavoriteStatusError = (response) => ({
  type: types.SAVE_ANR_FAVORITE_STATUS_ERROR,
  response,
});

export const openArticle = (item) => ({
  type: types.OPEN_ARTICLE,
  item,
});

export const closeArticle = () => ({
  type: types.CLOSE_ARTICLE,
});

export const openNextArticle = () => ({
  type: types.OPEN_NEXT_ARTICLE,
});

export const openPreviousArticle = () => ({
  type: types.OPEN_PREVIOUS_ARTICLE,
});

export const fetchAnRSources = (params) => ({
  type: types.FETCH_ANR_SOURCES,
  params,
});

export const fetchAnRSourcesSuccess = (results) => ({
  type: types.FETCH_ANR_SOURCES_SUCCESS,
  results,
});

export const fetchAnRSourcesError = (response) => ({
  type: types.FETCH_ANR_SOURCES_ERROR,
  response,
});

export const openDemandSourceDialog = () => ({
  type: types.OPEN_DEMAND_NEW_SOURCE_DIALOG,
});

export const closeDemandSourceDialog = () => ({
  type: types.CLOSE_DEMAND_NEW_SOURCE_DIALOG,
});

export const demandNewSource = (params) => ({
  type: types.DEMAND_NEW_SOURCE,
  params,
});

export const demandNewSourceSuccess = (results) => ({
  type: types.DEMAND_NEW_SOURCE_SUCCESS,
  results,
});

export const demandNewSourceError = (response) => ({
  type: types.DEMAND_NEW_SOURCE_ERROR,
  response,
});

export const fetchAnRBannedSites = (params) => ({
  type: types.FETCH_ANR_BANNED_SITES,
  params,
});

export const fetchAnRBannedSitesSuccess = (results) => ({
  type: types.FETCH_ANR_BANNED_SITES_SUCCESS,
  results,
});

export const fetchAnRBannedSitesError = (response) => ({
  type: types.FETCH_ANR_BANNED_SITES_ERROR,
  response,
});

export const unbanSiteSuccess = (results) => ({
  type: types.UNBAN_SITE_SUCCESS,
  results,
});

export const unbanSiteError = (response) => ({
  type: types.UNBAN_SITE_ERROR,
  response,
});

export const collect = (item) => ({
  type: types.COLLECT,
  item,
});

export const collectSuccess = () => ({
  type: types.COLLECT_SUCCESS,
});

export const collectError = (response) => ({
  type: types.COLLECT_ERROR,
  response,
});

export const validateAction = (articles, qesCollectResultList) => ({
  type: types.VALIDATE,
  articles,
  qesCollectResultList,
});

export const validateActionSuccess = (results) => ({
  type: types.VALIDATE_SUCCESS,
  results,
});

export const sendAction = (articlesIds, emails) => ({
  type: types.SEND,
  articlesIds,
  emails,
});

export const sendActionSuccess = (articlesIds, emails) => ({
  type: types.SEND_SUCCESS,
  articlesIds,
  emails,
});

export const openBanSiteDialog = (article) => ({
  type: types.OPEN_BAN_SITE_DIALOG,
  article,
});

export const closeBanSiteDialog = () => ({
  type: types.CLOSE_BAN_SITE_DIALOG,
});

export const banSite = (params) => ({
  type: types.BAN_SITE,
  params,
});

export const banSiteSuccess = (results) => ({
  type: types.BAN_SITE_SUCCESS,
  results,
});

export const banSiteError = (response) => ({
  type: types.BAN_SITE_ERROR,
  response,
});

export const reportExtraction = () => ({
  type: types.REPORT_EXTRACTION,
});

export const reportExtractionSuccess = () => ({
  type: types.REPORT_EXTRACTION_SUCCESS,
});

export const reportExtractionError = (response) => ({
  type: types.REPORT_EXTRACTION_ERROR,
  response,
});

export const openShareDialog = (article) => ({
  type: types.OPEN_SHARE_DIALOG,
  article,
});

export const closeShareDialog = () => ({
  type: types.CLOSE_SHARE_DIALOG,
});

export const deleteArticles = (articles) => ({
  type: types.DELETE_ARTICLES,
  articles,
});

export const deleteArticlesSuccess = (results) => ({
  type: types.DELETE_ARTICLES_SUCCESS,
  results,
});

export const deleteArticlesError = (response) => ({
  type: types.DELETE_ARTICLES_ERROR,
  response,
});

export const fetchAnrDuplicates = (article) => ({
  type: types.FETCH_ANR_DUPLICATES,
  article,
});

export const setLoadingDuplicatesArticle = (idArticle, loadindDuplicates) => ({
  type: types.SET_LOADING_DUPLICATES_ARTICLE,
  idArticle,
  loadindDuplicates,
});

export const fetchAnrDuplicatesSuccess = (article, resultsDuplicates) => ({
  type: types.FETCH_ANR_DUPLICATES_SUCCESS,
  article,
  resultsDuplicates,
});

export const fetchAnrDuplicatesError = (response) => ({
  type: types.FETCH_ANR_DUPLICATES_ERROR,
  response,
});

export const fetchResultsMethodsMap = {
  glimpse: fetchAnRGlimpse,
  news: fetchAnRFolderNews,
  quotes: fetchAnRFolderQuotes,
};
