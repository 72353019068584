import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import { Clear } from '@mui/icons-material';
import {
  cleanupAnRBannedSites,
  fetchAnRBannedSites, types,
} from 'generic/core/anr/actions';
import ResultsBannedSites from 'generic/components/pages/anr/ResultsBannedSites';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import TooltipButton from 'generic/components/ui/TooltipButton';

const BannedSitesContainer = () => {
  const loading = useSelector((state) => state.anr.bannedSitesLoading);
  const responseData = useSelector((state) => state.anr.responseData);
  const bannedSites = useSelector((state) => state.anr.bannedSites);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    // A la création du reducer, sources vaut null,
    // on tente de les charger une première fois ici
    if (bannedSites === null) {
      dispatch(fetchAnRBannedSites());
    }
  }, [bannedSites, dispatch]);

  useEffect(() => (
    // Cleanup d'anr (sites bannis) lorsque le composant est "unmount"
    // (donc quand on change de page)
    () => dispatch(cleanupAnRBannedSites())
  ), [dispatch]);

  const handleOpenConfirmUnbanSite = (host) => {
    dispatch(setConfirmDialogData({
      title: t('anr.unban_site'),
      message: (
        <Fragment>
          {t('anr.unban_site_sure')}
          &nbsp;
          <b>{host}</b>
          &nbsp;?
        </Fragment>
      ),
      submitColor: 'danger',
      waitForActions: [types.UNBAN_SITE_SUCCESS, types.UNBAN_SITE_ERROR],
      action: {
        type: types.UNBAN_SITE,
        params: {
          level: 'account',
          host,
        },
      },
    }));
  };

  const columns = [
    {
      name: 'host',
      label: t('anr.site'),
      options: {
        filter: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRender: (host) => (
          <Link
            underline="none"
            href={`http://${host}`}
            target="_blank"
            rel="noopener"
            sx={{ wordBreak: 'break-word' }}
          >
            {host}
          </Link>
        ),
      },
    },
    {
      name: 'name',
      label: t('anr.name'),
      options: {
        filter: false,
      },
    },
    {
      name: 'edit',
      label: t('anr.action'),
      options: {
        filter: true,
        sort: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRender: (value, tableMeta) => (
          <TooltipButton
            title={t('anr.unban')}
            tag="fab"
            color="primary"
            size="extraSmall"
            onClick={() => handleOpenConfirmUnbanSite(tableMeta.rowData[0])}
          >
            <Clear />
          </TooltipButton>
        ),
      },
    },
  ];

  const computeParams = (tableState) => {
    const {
      filterList,
    } = tableState;
    let params = {
      search: tableState.searchText,
    };
    const filters = {};
    for (let i = 0; i < filterList.length; i++) {
      if (!_.isEmpty(filterList[i])) {
        const { name } = columns[i];
        const [value] = filterList[i];
        filters[name] = value;
      }
    }
    if (!_.isEmpty(filters)) {
      params = {
        ...params,
        ...filters,
      };
    }
    return params;
  };

  const handlePageChangePagination = (tableState) => {
    const params = computeParams(tableState);
    const finalParams = {
      offset: tableState.page * 20,
      ...params,
    };
    dispatch(fetchAnRBannedSites(finalParams));
  };

  const handleSearch = (tableState) => {
    const params = computeParams(tableState);
    dispatch(fetchAnRBannedSites(params));
  };

  if (bannedSites === null) {
    return null;
  }
  return (
    <ResultsBannedSites
      columns={columns}
      count={responseData.filtered_count}
      handlePageChangePagination={handlePageChangePagination}
      handleSearch={handleSearch}
      loading={loading}
      page={(Math.ceil(responseData.offset / responseData.limit))}
      bannedSites={bannedSites}
    />
  );
};

export default BannedSitesContainer;
