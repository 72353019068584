import Highcharts from 'highcharts';
import { getContrastRatio } from '@mui/material/styles';

/**
 * retourne une couleur, plus la valeur sera proche de min, et plus elle sera transparente,
 * plus elle sera proche de max et plus elle sera opaque
 * @param {int} minOpacity le seuil d'opacitée minimum
 * @param {int} minFrequence la valeur minimum
 * @param {int} maxFrequence la valeur maximum
 * @param {int} currentFrequence la valeur actuel
 * @param {int} indexColor l'index de la couleur à prendre dans le thème Highcharts
 * @returns retourne une couleur rgba
 */
export const getColorForFrequency = (minOpacity, minFrequence, maxFrequence, currentFrequence, indexColor) => {
  const base = Highcharts.getOptions().colors[indexColor];
  const ratio = minOpacity + ((currentFrequence - minFrequence) * (1 - minOpacity)) / (maxFrequence - minFrequence);
  return Highcharts.color(base).setOpacity(ratio).get();
};

/**
 * Permet de calculer un tableau de couleurs, dégradées, la couleur de référence se base sur le thème
 * La fonction s'assure de ne pas générer de couleurs au dessous d'un seil afin d'éviter des couleurs illisibles
 * @param {int} minOpacity le seuil d'opacitée minimum
 * @param {int} maxColors le nombre de couleurs à calculer
 * @param {int} indexColor l'index de la couleur à prendre dans le thème Highcharts
 * @returns un tableau de dégradé de couleur couleurs en dégradé
 */
export const monoColors = (minOpacity, maxColors, indexColor) => {
  const base = Highcharts.getOptions().colors[indexColor];
  const colors = Array(maxColors)
    .fill()
    .map((__, i) => {
      const ratio = ((i + 1) * 100) / maxColors;
      const opacity = ratio + (minOpacity * (100 - ratio));
      return Highcharts.color(base).setOpacity(opacity / 100).get();
    })
    .reverse();

  return colors;
};

/**
 * Permet de générer une couleur lisible et non "fluo dégueu" pour du css ou des graphes
 * @param {int} number un nombre (par exemple un index dans un map) pour "randomiser" la couleur
 * @returns une couleur au format "hsl"
 */
export const getRandomColor = (number) => {
  const pivot = number || Math.random();
  const hue = pivot * 137.508;
  return `hsl(${hue}, 50%, 60%)`;
};

/**
 * Permet de générer une couleur pour la valeur de tonalité demandée
 * @param {int} toneValue la valeur de la tonalité
 * @returns une couleur au format hexa sur 8 (avec opacité à la fin)
 */
export const getToneColor = (toneValue) => {
  if (toneValue === 0) {
    return '#b5b5b5';
  }
  let baseColor = '#148f14';
  if (toneValue < 0) {
    baseColor = '#c91d1d';
  }
  const scaledHex = Math.floor((255 * Math.abs(toneValue)) / 4).toString(16);
  return `${baseColor}${scaledHex}`;
};

/**
 * Permet de savoir si le background du bandeau de l'application est clair ou sombre
 * @param {Object} theme
 * @returns {boolean} true si le background est sombre, false sinon
 */
export const isHeaderBackgroundColorDark = (theme) => {
  const headerBackgroundColor = theme.palette?.header?.main || theme.palette.primary.main;
  return getContrastRatio(headerBackgroundColor, '#fff') > 3;
};
