import { types } from 'generic/core/config/actions';
import { overrideRessource } from 'generic/utils/utils';

const { computeUserAdditionnalAttrs } = overrideRessource('utils/customUtils', true);

const initialState = {
  activeBase: {},
  bases: [],
  form: {},
  loading: false,
  settings: {},
  themesPanier: [],
  user: null,
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CONFIG: {
      return {
        ...state,
        bases: action.config.bases,
        settings: action.config.parametres,
        themesPanier: action.config.themesPanier,
        user: {
          ...action.user,
          ...computeUserAdditionnalAttrs(action.user),
        },
      };
    }
    case types.CLEANUP_CONFIG: {
      return { ...initialState };
    }
    case types.SET_ACTIVE_BASE: {
      return {
        ...state,
        activeBase: action.base,
      };
    }
    case types.SET_FORM_FIELDS: {
      return {
        ...state,
        form: {
          champs: action.fields,
        },
      };
    }
    case types.SAVE_USER_IMAGE:
    case types.SAVE_PROFILE: {
      return {
        ...state,
        user: {
          ...state.user,
          saveUserLoading: true,
        },
      };
    }
    case types.SAVE_PROFILE_SUCCESS: {
      return {
        ...state,
        user: {
          ...action.user,
          saveUserLoading: false,
        },
      };
    }
    case types.SAVE_USER_IMAGE_ERROR:
    case types.SAVE_PROFILE_ERROR: {
      return {
        ...state,
        user: {
          ...state.user,
          saveUserLoading: false,
        },
      };
    }
    case types.UPLOAD_USER_IMAGE: {
      return {
        ...state,
        user: {
          ...state.user,
          imageUploading: true,
        },
      };
    }
    case types.UPLOAD_USER_IMAGE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          imageUploading: false,
          avatarName: action.imageName,
        },
      };
    }
    case types.UPLOAD_USER_IMAGE_ERROR: {
      return {
        ...state,
        user: {
          ...state.user,
          imageUploading: false,
        },
      };
    }
    case types.UPLOAD_USER_IMAGE_CLOSE_DIALOG: {
      return {
        ...state,
        user: {
          ...state.user,
          avatarName: null,
        },
      };
    }
    case types.SAVE_USER_IMAGE_SUCCESS: {
      return {
        ...state,
        user: {
          ...action.user,
          avatarName: null,
        },
      };
    }
    default:
      return state;
  }
};

export default configReducer;
