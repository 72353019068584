import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialogCreateAlert } from 'generic/core/actions/actions';
import { createAlert } from 'generic/core/alerts/actions';
import CreateAlertDialog from 'generic/components/pages/dialog/CreateAlertDialog';

const CreateAlertDialogContainer = () => {
  const open = useSelector((state) => state.actions.dialogCreateAlertOpened);
  const idMovement = useSelector((state) => state.search.results.mouvement);
  const loading = useSelector((state) => state.alerts.createAlertLoading);
  const dispatch = useDispatch();

  const handleCreateAlert = (nameNewAlert, typeAlert) => {
    dispatch(createAlert({ mouvement: idMovement, libelle: nameNewAlert, type_veille: typeAlert }));
  };

  const handleClose = () => {
    dispatch(closeDialogCreateAlert());
  };

  if (!open) {
    return null;
  }
  return (
    <CreateAlertDialog
      handleCreateAlert={handleCreateAlert}
      handleClose={handleClose}
      loading={loading}
    />
  );
};

export default CreateAlertDialogContainer;
