import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import MUIDataTable from 'mui-datatables';
import i18next from 'i18next';

const textLabels = i18next.t('datatable.textLabels', { returnObjects: true });
const defaultOptions = {
  textLabels: {
    ...textLabels,
    body: {
      ...textLabels.body,
    },
  },
  elevation: 0,
  fixedHeader: true,
  pagination: false,
  download: false,
  filter: false,
  print: false,
  search: false,
  searchAlwaysOpen: false,
  viewColumns: false,
  selectableRows: 'none',
  responsive: 'simple',
  setTableProps: () => ({ size: 'small' }),
  sortOrder: {
    name: 'date_of_creation',
    direction: 'desc',
  },
};

const Datatable = ({ data, columns, options }) => {
  const finalConfig = merge({}, defaultOptions, options);

  return (
    <MUIDataTable
      options={finalConfig}
      data={data}
      columns={columns}
      dense
    />
  );
};

Datatable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.shape(),
  })).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
  ),
  options: PropTypes.shape(),
};

Datatable.defaultProps = {
  options: {},
  data: [],
};

export default Datatable;
