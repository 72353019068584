import React from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);
HighchartsNoData(Highcharts);

const defaultOptions = {
  highcharts: Highcharts,
  options: {
    plotOptions: {
      solidgauge: {
        colorByPoint: false,
        dataLabels: {
          enabled: false,
        },
        linecap: 'round',
        stickyTracking: false,
        rounded: true,
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
    },
    chart: { type: 'solidgauge' },
  },
};

const SolidGauge = ({ highchartsOptions }) => {
  const finalConfig = merge({}, defaultOptions, highchartsOptions);

  return (
    <HighchartsReact {...finalConfig} />
  );
};

SolidGauge.propTypes = {
  highchartsOptions: PropTypes.shape().isRequired,
};

SolidGauge.defaultProps = {
};

export default SolidGauge;
