import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { format, isDate, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { splitAndCleanEmptyValues } from 'generic/utils/utils';

import {
  clearUploadGEDFile,
  fetchDocument,
  types,
  toggleNewMultipleField,
  saveDocument,
  unsetDocument,
  cleanupDocument,
  unlockDocument,
} from 'generic/core/ged/actions';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import FormGED from 'generic/components/forms/FormGED';

const GEDContainer = ({
  id, base, variant, multiple, backPageLocation, afterSave, onCloseDialog, ...props
}) => {
  const activeBaseStatusField = useSelector((state) => state.config.activeBase.champEtat);
  const loading = useSelector((state) => state.ged.loading);
  const document = useSelector((state) => state.ged.document);
  const files = useSelector((state) => state.ged.files);
  const checkedItems = useSelector((state) => state.selection.checkedItems);
  const activeBaseId = useSelector((state) => state.config.activeBase.base);
  const userId = useSelector((state) => state.config.user.utilisateur);
  const history = useHistory();
  const [formikInitialValues, setFormikInitialValues] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const unlock = () => { dispatch(unlockDocument(id)); };

    dispatch(fetchDocument({ id, base, variant }));
    window.addEventListener('beforeunload', unlock); // libère le document courant si on ferme l'onglet

    return () => {
      unlock(); // libère le document courant si on passe au doc suivant.
      window.removeEventListener('beforeunload', unlock);
    };
  }, [id, base, dispatch, variant]);

  useEffect(() => (
    // lorsque le composant est "unmount"; on nettoie le store redux
    () => {
      dispatch(cleanupDocument());
    }
  ), [dispatch]);

  const handleClearUploadGEDFile = (fieldName) => {
    dispatch(clearUploadGEDFile(fieldName));
  };

  const handleToggleNewMultipleField = (fieldName) => {
    dispatch(toggleNewMultipleField(fieldName));
  };

  const handleGoBack = () => {
    history.push({ ...backPageLocation, state: { ...backPageLocation.state, referrer: '/ged' } });
  };

  const populateInitialValues = () => {
    const initialValues = {};
    _.map(document.champs, (field) => {
      if (field.name) {
        if (field.nature === 'date' || field.nature === 'datetime') {
          let dateValue = null;
          // On parse la valeur en date JS, si elle existe
          if (field.value) {
            dateValue = parse(field.value, 'dd/MM/yyyy', new Date());
          }
          if (field.nature === 'date') {
            initialValues[field.name] = dateValue;
          } else {
            // Si le champ est de nature 'datetime', on crée deux
            // valeurs dans les initialValues pour le datepicker
            // et le timepicker
            initialValues[`DATE_${field.name}`] = dateValue;
            initialValues[`TIME_${field.name}`] = dateValue;
          }
        } else if ([
          'autocomplete_text',
          'chips',
          'chips-non-modifiable',
        ].includes(field.nature)) {
          // La valeur de ces types de champs est "découpée" via des points virgules,
          // on la split
          initialValues[field.name] = field.value ? splitAndCleanEmptyValues(field.value) : [];
        } else if (field.nature === 'liste') {
          const selectedValue = _.find(field.values, (value) => value.selected);
          const validValue = selectedValue || field.values[0];
          initialValues[field.name] = _.get(validValue, 'code', '');
        } else if (field.nature === 'listeChoix') {
          // Champ de type Autocomplete multiple qui prend une liste d'objets
          // en tant que valeurs possibles
          // La valeur de ces types de champs est "découpée" via des points virgules,
          // on la split
          const selected = field.value ? splitAndCleanEmptyValues(field.value) : [];
          // La liste de valeurs sélectionnées à passer au champ MUI doit être
          // constituée des objets complets, et pas juste leurs ID, on va donc
          // récupérer chaque objet dans les valeurs possibles pour préparer
          // l'initialValue concernée
          const values = [];
          _.each(field.values, (value) => {
            if (selected.includes(value.code)) {
              values.push(value);
            }
          });
          initialValues[field.name] = values;
        } else if ([
          'liste_checkbox_ou',
          'liste_checkbox_et_ou',
        ].includes(field.nature)) {
          const values = [];
          _.each(field.values, (value) => {
            if (!_.isEmpty(value.selected)) {
              values.push(value);
            }
          });
          initialValues[field.name] = values;
          if (field.nature === 'liste_checkbox_et_ou') {
            initialValues[`${field.name}_comp`] = field.comparateur || '';
          }
        } else {
          // Pour tous les autres champs, si la valeur existe, on l'unescape
          initialValues[field.name] = field.value ? _.unescape(field.value) : '';
        }
      }
    });
    return initialValues;
  };

  useEffect(() => {
    setFormikInitialValues(populateInitialValues());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const handleSubmit = (values, nextStatus) => {
    const params = {
      utilisateur: userId,
      base: activeBaseId,
    };

    // const readOnlyFields = [];
    // const readOnlyNatures = ['print', 'chips-non-modifiable'];
    // for (let i = 0; i < document.champs.length; i++) {
    //   if (readOnlyNatures.includes(document.champs[i].nature)) {
    //     readOnlyFields.push(document.champs[i].name);
    //   }
    // }
    const readOnlyNatures = ['print', 'chips-non-modifiable'];
    const readOnlyFields = document.champs.reduce((acc, champ) => {
      if (readOnlyNatures.includes(champ.nature)) {
        acc.push(champ.name);
      }
      return acc;
    }, []);

    const alteredValues = {};
    _.forOwn(values, (value, key) => {
      if (!readOnlyFields.includes(key)) {
        let finalValue = value;
        if (_.isArray(value)) {
          if (_.every(value, _.isObject)) {
            finalValue = _.map(value, (item) => item.code);
          }
          finalValue = finalValue.join(';');
        } else if (isDate(value)) {
          if (key.indexOf('TIME_') === 0) {
            finalValue = format(value, 'HH:mm');
          } else {
            finalValue = format(value, 'dd/MM/yyyy');
          }
        }
        alteredValues[key] = finalValue;
        if (key === activeBaseStatusField && nextStatus) {
          alteredValues[activeBaseStatusField] = nextStatus;
        }
      }
    });
    params.champs = alteredValues;
    if (id) { params.article = id; }

    dispatch(saveDocument(params, afterSave));
  };

  // Cleanup du document quand on unmount le composant
  useEffect(() => (() => {
    dispatch(unsetDocument());
  }), [dispatch]);

  if (_.isEmpty(document)) {
    if (loading) {
      return <GlobalLoading />;
    }
  }

  const currentIndex = Object.keys(checkedItems).indexOf(id);
  const previousIdQes = Object.keys(checkedItems)[currentIndex - 1];
  const nextIdQes = Object.keys(checkedItems)[currentIndex + 1];

  return (
    <FormGED
      {...props}
      activeBaseId={activeBaseId}
      actionTypeUpload={types.UPLOAD_GED_FILE}
      actionTypeUploadError={types.UPLOAD_GED_FILE_ERROR}
      actionTypeUploadSuccess={types.UPLOAD_GED_FILE_SUCCESS}
      onCloseDialog={onCloseDialog}
      currentTitle={document.titre}
      fields={document.champs}
      tabs={document.onglets}
      workflowActions={document.actions}
      isLocked={document.userAccesConcurrent !== ''}
      files={files}
      fileUploading={files.uploading}
      handleClear={() => setFormikInitialValues(populateInitialValues())}
      handleClearUploadGEDFile={handleClearUploadGEDFile}
      handleGoBack={handleGoBack}
      handleToggleNewMultipleField={handleToggleNewMultipleField}
      hasBackPage={!_.isEmpty(backPageLocation)}
      initialValues={formikInitialValues}
      loading={loading}
      multiple={multiple}
      nextDoc={nextIdQes ? checkedItems[nextIdQes] : null}
      onSubmit={handleSubmit}
      previousDoc={previousIdQes ? checkedItems[previousIdQes] : null}
      ssPathForUpload={document.ssPathForUpload}
    />
  );
};

GEDContainer.propTypes = {
  afterSave: PropTypes.func,
  onCloseDialog: PropTypes.func,
  id: PropTypes.string.isRequired,
  base: PropTypes.number.isRequired,
  variant: PropTypes.oneOf([null, 'light']),
  multiple: PropTypes.bool,
  backPageLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    key: PropTypes.string,
    state: PropTypes.shape(),
  }),
};

GEDContainer.defaultProps = {
  afterSave: _.noop,
  backPageLocation: {},
  onCloseDialog: null,
  multiple: false,
  variant: null,
};

export default GEDContainer;
